import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { ServicePointIdReference } from "src/app/shared/models/referenceData";
import { SelectMenuSearchComponent } from "../table/filters/select-menu-search/select-menu-search.component";
import {
  FilterSearchMeta,
  FilterSearchState,
} from "src/app/shared/utilities/filter-search-state";
import { Subscription } from "rxjs";
import { ReferenceDataService } from "../reference-data/reference-data.service";
import { SelectMenuListComponent } from "../table/filters/select-menu-list/select-menu-list.component";
import { SelectMenuListItemComponent } from "../table/filters/select-menu-list-item/select-menu-list-item.component";
import { SelectMenuListloadingComponent } from "../table/filters/select-menu-list-loading-item/select-menu-list-loading-item.component";
import { SelectMenuListErrorComponent } from "../table/filters/select-menu-list-error-item/select-menu-list-error-item.component";
import { SelectMenuListNoItemsComponent } from "../table/filters/select-menu-list-no-items-item/select-menu-list-no-items-item.component";
import { SelectMenuListPagingComponent } from "../table/filters/select-menu-list-paging-item/select-menu-list-paging-item.component";

@Component({
  standalone: true,
  imports: [
    MatIcon,
    SelectMenuSearchComponent,
    SelectMenuListComponent,
    SelectMenuListItemComponent,
    SelectMenuListloadingComponent,
    SelectMenuListNoItemsComponent,
    SelectMenuListPagingComponent,
    SelectMenuListErrorComponent,
  ],
  selector: "div[app-service-point-selector]",
  template: `
    <div class="wrapper" [class.slim]="slim">
      <mat-icon class="search-icon" svgIcon="search"></mat-icon>
      <input
        type="button"
        [placeholder]="'none'"
        [value]="value?.service_point_id ?? null"
        (click)="toggle()"
      />
      <div class="popover" [class.open]="open">
        <div
          app-select-menu-search
          name="service_point_id"
          label="Service Point"
          [value]="searchMeta?.searchTerm ?? ''"
          (search)="this.searchState.search($event)"
        ></div>
        <ul app-select-menu-list (scroll)="onScroll($event)">
          @if(allowPartial && !!selectedIds && searchMeta?.searchTerm) {
          <li
            app-select-menu-list-item
            [value]="searchMeta?.searchTerm!"
            [display]="searchMeta?.searchTerm!"
            [selected]="
              selectedIds ? selectedIds.has(searchMeta?.searchTerm!) : false
            "
            [multi]="!!selectedIds"
            (select)="setPartialValue($event)"
            label="Current Search"
          ></li>
          }
          <!-- SHOW LOADING INDICATOR -->
          @if(searchMeta?.searching) {
          <li app-select-menu-list-loading-item></li>
          }

          <!-- SHOW ERROR -->
          @else if(searchMeta?.error ) {
          <li app-select-menu-list-error-item></li>
          }

          <!-- SHOW ENTITY REFS -->
          @else {
          <!-- SHOW SEARCH RESULTS -->
          @for (result of searchResults; track result.service_point_id;) {
          <li
            app-select-menu-list-item
            [value]="result"
            [display]="result.service_point_id"
            [selected]="
              selectedIds
                ? selectedIds.has(result.service_point_uid)
                : result.service_point_uid === value?.service_point_uid
            "
            [multi]="!!selectedIds"
            (select)="setValue($event)"
          ></li>
          }

          <!-- SHOW NO RESULTS -->
          @if(!searchResults.length) {
          <li app-select-menu-list-no-items-item></li>
          }

          <!-- SHOW PAGING -->
          @if(searchMeta?.paging ) {
          <li app-select-menu-list-paging-item></li>
          } }
        </ul>
      </div>
    </div>
  `,
  styles: `
    .wrapper {
      position: relative;
      width: 100%;
      padding: 0.5rem 0.75rem;
      border: 1px solid black;

      &.slim {
        padding: 0.5rem 0rem 0rem;
        border: none; 
      }
    }

    input {
      width: 100%;
      padding: 0.5rem 1.75rem 0.25rem 0.25rem;
      border: none;
      background: white;
      text-align: left;
      border-bottom: 1px solid black;
      font-size: 0.9rem;
      line-height: 1.25rem;

      &:focus {
        outline: none;
      }
    }

    .search-icon {
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;
    }
  
    .popover {
      display: none;
      position: absolute;
    }

    .popover.open {
      z-index: 3;
      display: flex;
      flex-direction: column;
      min-height: 7rem;
      width: 300px;
      right: 0;
      top: calc(100% + 0.5rem);
      border: 1px solid black;
      border-radius: 0;
      padding: 0.5rem 1rem;
      background: white;


      &::before {
        content: ' ';
        border: none;
        border-top: 1px solid black;
        border-right: 1px solid black;
        width: 0.5rem;
        height: 0.5rem;
        transform: rotate(-45deg);
        position: absolute;
        top: calc(-0.25rem - 1px);
        background: linear-gradient(
          45deg, 
          rgba(255,255,255,0) 0%, 
          rgba(255,255,255,0) calc(50% - 1px), 
          rgba(255,255,255,1) calc(50% - 1px), 
          rgba(255,255,255,1) 100%
          );
        right: 1rem;
      }
    }
  `,
})
export class ServicePointSelectorComponent implements OnInit, OnDestroy {
  elementRef = inject(ElementRef);
  referenceDataService = inject(ReferenceDataService);

  @Input() value: ServicePointIdReference | null = null;
  @Input() slim = false;
  @Input() allowPartial = false;
  @Input() selectedIds: Set<string> | undefined;

  @Output() valueChange = new EventEmitter<ServicePointIdReference>();
  @Output() partialValueChange = new EventEmitter<string>();

  open = false;
  subscription = new Subscription();
  searchResults: ServicePointIdReference[] = [];
  searchMeta?: FilterSearchMeta;
  searchState: FilterSearchState<ServicePointIdReference>;
  searchStateMeta: FilterSearchState<ServicePointIdReference>;

  @HostListener("document:click", ["$event"])
  handleOutsideClick(event: Event) {
    if (
      this.open &&
      !this.elementRef.nativeElement.contains(event.target as Node)
    ) {
      this.toggle();
    }
  }

  ngOnInit(): void {
    this.searchState = new FilterSearchState<ServicePointIdReference>(
      ((searchTerm: string, pageNumber: number, pageSize: number) =>
        this.referenceDataService.servicePoints(
          { pageNumber, pageSize },
          [{ name: "service_point_id", values: [{ value: searchTerm }] }],
          [{ name: "service_point_id" }]
        )).bind(this)
    );
    this.subscription.add(
      this.searchState.results$.subscribe((r) => {
        this.searchResults = r;
      })
    );
    this.subscription.add(
      this.searchState.meta$.subscribe((m) => {
        this.searchMeta = m;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggle(): void {
    this.open = !this.open;
    this.searchState.search("");
  }

  onScroll(evt: Event) {
    const { scrollHeight, scrollTop, clientHeight } = evt.target as any;
    const threshold = 15;
    const reachedThreshold =
      Math.abs(scrollHeight - clientHeight - scrollTop) < threshold;
    if (
      this.searchMeta?.hasMore &&
      reachedThreshold &&
      !this.searchMeta.paging &&
      !this.searchMeta.searching
    ) {
      this.searchState.page();
    }
  }

  setValue(event: ServicePointIdReference) {
    this.value = event;
    this.valueChange.emit(event);
    this.open = false;
  }

  setPartialValue(event: string) {
    this.value = null;
    this.partialValueChange.emit(event);
    this.open = false;
  }
}
