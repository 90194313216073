import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";

@Component({
    standalone: true,
    imports: [FormsModule],
    selector: 'div[app-file-input]',
    styles: ``,
    template: `
        <input #f type="file" [accept]="accept" (change)="handleFileSelect($event)" />
    `
})
export class FileInputComponent {
    @Input({required: true}) accept: string
    @Input() skipParse: boolean = false;
    @Output() changeValue = new EventEmitter<string>
    @Output() changeFile = new EventEmitter<File | undefined>

    @ViewChild('f')
    private fileEl: ElementRef<HTMLInputElement>;

    async handleFileSelect(evt: Event ) {
        evt.stopImmediatePropagation();
        evt.preventDefault();
        const file = (evt.target as HTMLInputElement)?.files?.[0];
        this.changeValue.emit(await this.fileAsBase64(file));
    }

    private async fileAsBase64(file: File | undefined) {
        this.changeFile.emit(file);
        if(!this.skipParse && file) {
            const dataUrl = await new Promise<string | ArrayBuffer | null>((resolve, reject) => {
                const reader = Object.assign(new FileReader(), {
                    onload: () => resolve(reader.result),
                    onerror: () => reject(reader.error),
                });
                return reader.readAsDataURL(file)
            });
            return (dataUrl as string).split('base64,')[1];
        }
    }

    clear() {
        this.fileEl.nativeElement.value = ''
        this.fileEl.nativeElement.files = null
    }
}