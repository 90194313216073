import { Component, inject, OnDestroy } from "@angular/core";
import { AbstractFilterModal } from "./abstract-filter-modal";
import { ModalHeaderComponent } from "../modal-header/modal-header.component";
import { ModalBodyComponent } from "../modal-body/modal-body.component";
import { ModalFooterComponent } from "../modal-footer/modal-footer.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { FilterService } from "src/app/shared/services/filter.service";
import { ActiveFilterListComponent } from "../../table/filters/active-filter-list/active-filter-list.component";
import { SelectMenuSearchComponent } from "../../table/filters/select-menu-search/select-menu-search.component";
import { LabeledPropertyComponent } from "../../labeled-property/labeled-property.component";

type StringFilterModalInit = {};

@Component({
  standalone: true,
  imports: [
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    LabeledPropertyComponent,
    ActiveFilterListComponent,
    SelectMenuSearchComponent,
  ],
  selector: "app-string-filter-modal",
  styles: `
  `,
  template: `
    <div app-modal-header>{{ fieldNameDisplay }}</div>
    <div app-modal-body>
      <p>Select a filter value for the column {{ fieldNameDisplay }} below</p>
      <div app-labeled-property [label]="fieldNameDisplay">
        <div
        app-select-menu-search
        [name]="field"
        [label]="fieldNameDisplay"
        [value]="searchValue"
        [showAdd]="true"
        (search)="onSearch($event)"
        (add)="setValue($event)"
        ></div>
      </div>
      <app-active-filter-list
        [filterGroup]="filterGroup"
        [filterName]="field"
      ></app-active-filter-list>
    </div>
    <div app-modal-footer>
      <button class="action-btn" (click)="onClose()">Close</button>
    </div>
  `,
})
export class StringFilterModalComponent
  extends AbstractFilterModal<StringFilterModalInit>
  implements OnDestroy
{
  private modal = inject(NgbActiveModal);
  private filterService = inject(FilterService);

  private subscription = new Subscription();
  public filterGroup: string;
  public field: string;
  public modalInit: StringFilterModalInit;
  public fieldNameDisplay: string = "";
  public searchValue = "";

  public override init(
    modalInit: StringFilterModalInit,
    filterGroup: string,
    field: string,
    fieldNameDisplay: string
  ): void {
    this.modalInit = modalInit;
    this.filterGroup = filterGroup;
    this.field = field;
    this.fieldNameDisplay = fieldNameDisplay;

    this.subscription.add(
      this.filterService.state(this.filterGroup).subscribe((fp) => {})
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onSearch(event: string) {
    const terms = event
      .split(",")
      .map((sp) => sp.trim())
      .filter(Boolean);

    if (terms.length > 1) {
      this.setValue(terms);
    }

    this.searchValue = terms.slice(-1)[0] ?? "";
  }

  setValue(val: string | string[]) {
    const terms = [val].flat();
    this.filterService.dispatch({
      type: "ADD_STRING_FILTER",
      value: terms,
      filterGroup: this.filterGroup,
      filterName: this.field,
      display: this.fieldNameDisplay,
    });
  }

  onClose() {
    this.modal.close();
  }
}
