<mat-toolbar color="primary" class="mat-elevation-z5">
    <span class="fill-remaining-space">
        <button class="nav-btn" mat-icon-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" (mouseover)="menuTrigger.openMenu()">
            <img class="ameren-logo" src="../../../assets/images/ameren-logo-dark.svg" />
            <mat-icon svgIcon="expand-more"></mat-icon>
        </button>
        <mat-menu #menu="matMenu" [overlapTrigger]="false">
            <button mat-menu-item routerLink='/spah-overrides'>
                <!-- <mat-icon svgIcon="search"></mat-icon> -->
                <span>Service Point Overrides</span>
            </button>
            <button mat-menu-item routerLink='/service-points'>
                <!-- <mat-icon svgIcon="bolt"></mat-icon> -->
                <span>Service Points</span>
            </button>
            <button mat-menu-item routerLink='/interval-usage'>
                <!-- <mat-icon svgIcon="monitoring"></mat-icon> -->
                <span>Interval Usage</span>
            </button>
            <button mat-menu-item routerLink='/mdma-settlement-runs'>
                <!-- <mat-icon svgIcon="summarize"></mat-icon> -->
                <span>MDMA Settlement Runs</span>
            </button>
            <button mat-menu-item routerLink='/digital-certs'>
                <!-- <mat-icon svgIcon="summarize"></mat-icon> -->
                <span>Digital Certificates</span>
            </button>
            <button mat-menu-item routerLink='/hds-input-validation'>
                <!-- <mat-icon svgIcon="summarize"></mat-icon> -->
                <span>HDS Input Validation</span>
            </button>
            <!-- <button mat-menu-item routerLink='/entity-managment'>
                <mat-icon svgIcon="database"></mat-icon>
                <span>Entity Managment</span>
            </button>
            <button mat-menu-item routerLink='/scalar-profiling'>
                <mat-icon svgIcon="monitoring"></mat-icon>
                <span>Scalar Profiling</span>
            </button>
            <button mat-menu-item routerLink='/planning-year'>
                <mat-icon svgIcon="monitoring"></mat-icon>
                <span>Planning Data</span>
            </button>
            <button mat-menu-item>
                <mat-icon svgIcon="settings"></mat-icon>
                <span>Settings</span>
            </button> -->
            <button mat-menu-item>
                <mat-icon svgIcon="logout"></mat-icon>
                <span>Logout</span>
            </button>
        </mat-menu>
    </span>
    <span class="fill-remaining-space">Electric Settlement System</span>
</mat-toolbar>