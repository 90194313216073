import { DestroyRef, inject, Injectable } from "@angular/core";
import { CachedResponse } from "../utilities/cached-response";
import { HttpClient } from "@angular/common/http";
import { ServicePointGroup } from "../models/servicePointGroup";
import { map } from "rxjs";

@Injectable({ providedIn: "root" })
export class ServicePointGroupService {
  private httpClient = inject(HttpClient);
  private destroyRef = inject(DestroyRef);

  private servicePointGroups = new CachedResponse<ServicePointGroup>(
    "service_point_group/",
    sessionStorage,
    this.httpClient
  );

  constructor() {
    this.destroyRef.onDestroy(() => {
      this.servicePointGroups.destroy();
    });
  }

  get(searchTerm?: string) {
    return this.servicePointGroups
      .get()
      .pipe(
        map((res) =>
          res.filter((data) =>
            searchTerm
              ? data.service_point_group_name
                  .toLowerCase()
                  .startsWith(searchTerm.toLowerCase())
              : true
          )
        )
      );
  }
}
