import { HttpParams } from "@angular/common/http";

export const httpParams = (...fns: (() => HttpParams)[]): HttpParams => {
  return fns
    .map((fn) => fn())
    .reduce(
      (acc1, params) =>
        params
          .keys()
          .reduce(
            (acc2, key) =>
              (params.getAll(key) ?? []).reduce(
                (acc3, value) => acc3.append(key, value),
                acc2
              ),
            acc1
          ),
      new HttpParams()
    );
};

export const withHttpParams =
  <T extends (...args: any[]) => HttpParams>(fn: T, ...params: Parameters<T>) =>
  (): HttpParams =>
    fn(...params);

// Pagniation
export type PaginationParams = {
  pageSize: number;
  pageNumber: number;
};

export const DEFAULT_PAGE_SIZE = 1000;
export const DEFAULT_PAGE_NUMBER = 0;

export const pagination = (
  params: PaginationParams = {
    pageNumber: DEFAULT_PAGE_NUMBER,
    pageSize: DEFAULT_PAGE_SIZE,
  }
): HttpParams =>
  httpParams(
    () => new HttpParams().append("limit", params.pageSize),
    () => new HttpParams().append("start", params.pageSize * params.pageNumber)
  );

export const paginationBody = (
  params: PaginationParams = {
    pageNumber: DEFAULT_PAGE_NUMBER,
    pageSize: DEFAULT_PAGE_SIZE,
  }
) => ({
  limit: params.pageSize,
  start: params.pageSize * params.pageNumber,
});

// Filters
export type FilterOperation =
  | "=="
  | ">"
  | ">="
  | "<"
  | "<="
  | "!="
  | "between="
  | "in=";
export type FilterParams = {
  name: string;
  values: {
    value: string;
    operation?: FilterOperation;
  }[];
};

export const DEFAULT_FILTER_OPERATION: FilterOperation = "==";

export const filter = (params: FilterParams[] = []): HttpParams =>
  httpParams(
    ...params.map(
      (p) => () =>
        httpParams(
          ...p.values.map(
            (v) => () =>
              v.value
                ? new HttpParams().append(
                    p.name,
                    v.operation && v.operation !== "=="
                      ? `${v.operation}${v.value}`
                      : `${v.value}`
                  )
                : new HttpParams()
          )
        )
    )
  );

// Sorting
export type SortDirection = "asc" | "desc";
export type SortParams = {
  name: string;
  direction?: SortDirection;
};

export const DEFAULT_SORT_DIRECTION: SortDirection = "asc";

export const sort = (params: SortParams[] = []): HttpParams =>
  params.length
    ? httpParams(() =>
        new HttpParams().append(
          "order_by",
          params
            .map(
              (p) =>
                `${
                  (p.direction ?? DEFAULT_SORT_DIRECTION) === "asc" ? "" : "-"
                }${p.name}`
            )
            .join(",")
        )
      )
    : httpParams();

export const sortBody = (params: SortParams[] = []) =>
  params.length
    ? {
        order_by: params
          .map(
            (p) =>
              `${(p.direction ?? DEFAULT_SORT_DIRECTION) === "asc" ? "" : "-"}${
                p.name
              }`
          )
          .join(","),
      }
    : {};
