import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatCheckbox } from "@angular/material/checkbox";
import { BoolDisplayPipe } from "src/app/shared/pipes/boolDisplay.pipe";

@Component({
    standalone: true,
    imports: [MatCheckbox, BoolDisplayPipe],
    selector: 'div[app-bool-picker]',
    styles: ``,
    template: `
    <mat-checkbox [checked]="value" (change)="valueChange.emit($event.checked)"></mat-checkbox>
    <span>{{value | boolDisplay }}</span>
    `
})
export class BoolPickerComponent {
    @Input()
    value: boolean;

    @Output()
    valueChange = new EventEmitter<boolean>()
    
}