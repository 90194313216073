import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { NamedDate } from '../models/namedDate';

@Pipe({
    name: 'maskedDate',
    pure: true,
    standalone: true
})

export class MaskedDatePipe implements PipeTransform {
    constructor(private datePipe: DatePipe) {}

    transform(value: Date | string | undefined | null, masks?:NamedDate[], format?: string): string | null {
        const foundMask = masks?.find(m => moment(value).isSame(m.value, 'D'));
        return foundMask?.name ?? value ? moment(value).format(format ?? 'YYYY/MM/DD'): null;
    }
} 