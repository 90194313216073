import { Component, inject } from "@angular/core";
import { PageHeaderComponent } from "../page-header/page-header.component";
import { PageContentComponent } from "../page-content/page-content.component";
import { InputValidationFileUploader } from "../input-validation-file-uploader/input-validation-file-uploader.component";
import { ExpectedResultsService } from "src/app/shared/services/expected-results.service";
import { firstValueFrom } from "rxjs";

@Component({
    standalone: true,
    selector: 'app-input-validation',
    imports: [
        PageHeaderComponent, 
        PageContentComponent, 
        InputValidationFileUploader
    ],
    styles: `
    app-page-content {
        padding-top: 2rem;
    }
    `,
    template: `
    <app-page-header title="HDS Input Validation"></app-page-header>
    <app-page-content>
        <app-input-validation-file-uploader 
            name="Generation Load" 
            [validation]="generationLoadValidation" 
            [submit]="generationLoadSubmit">
        </app-input-validation-file-uploader>
        <app-input-validation-file-uploader 
            name="RPGI" 
            [validation]="rpgiValidation" 
            [submit]="rpgiSubmit">
        </app-input-validation-file-uploader>
        <app-input-validation-file-uploader 
            name="System Load" 
            [validation]="systemLoadValidation" 
            [submit]="systemLoadSubmit">
        </app-input-validation-file-uploader>
    <app-page-content/>`,
})
export class InputValidationComponent {
    expectedResultsService = inject(ExpectedResultsService);

    generationLoadValidation = (async (file: File) => {
        const data = await file.text();
        const exp = new RegExp([
            /^Report Dates:,\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}-\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2},{25}[\s\S]+/,
            /,{26}[\s\S]+/,
            /,{26}[\s\S]+/,
            /,{26}[\s\S]+/,
            /,{2}Hour,{24}[\s\S]+/,
            /Date,MapMDMAGen,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,Total[\s\S]+/
        ].map(r => r.source).join(''),)
        if(!exp.test(data)) {
            return 'Selected data file does not match expected format'
        }
    }).bind(this);
    generationLoadSubmit = (async (file: File) => {
        try{
            await firstValueFrom(this.expectedResultsService.generationLoad(file));
        } catch {
            return 'An unexpected error occurred while uploading your file. Please try again.'
        }
    }).bind(this);

    rpgiValidation = (async (file: File) => {
        const data = await file.text();
        const exp = new RegExp([
            /^Report Dates:,\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}-\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2},{25}[\s\S]+/,
            /,{26}[\s\S]+/,
            /,{26}[\s\S]+/,
            /,{26}[\s\S]+/,
            /,{3}Hour,{23}[\s\S]+/,
            /Description,Date,Total,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24[\s\S]+/
        ].map(r => r.source).join(''),)
        if(!exp.test(data)) {
            return 'Selected data file does not match expected format'
        }
    }).bind(this);
    rpgiSubmit = (async (file: File) => {
        try{
            await firstValueFrom(this.expectedResultsService.rpgiLoad(file));
        } catch {
            return 'An unexpected error occurred while uploading your file. Please try again.'
        }
    }).bind(this);
    
    systemLoadValidation = (async (file: File) => {
        const data = await file.text();
        const exp = new RegExp([
            /^Report Dates:,\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}-\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2},{25}[\s\S]+/,
            /,{26}[\s\S]+/,
            /,{26}[\s\S]+/,
            /,{26}[\s\S]+/,
            /,{2}Hour,{24}[\s\S]+/,
            /Date,Description,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,Total[\s\S]+/
        ].map(r => r.source).join(''),)
        if(!exp.test(data)) {
            return 'Selected data file does not match expected format'
        }
    }).bind(this);
    systemLoadSubmit = (async (file: File) => {
        try{
            await firstValueFrom(this.expectedResultsService.systemLoad(file));
        } catch {
            return 'An unexpected error occurred while uploading your file. Please try again.'
        }
    }).bind(this);
}