import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";

@Component({
    standalone: true,
    imports: [FormsModule],
    selector: 'div[app-text-input]',
    styles: ``,
    template: `
        <input type="text" [ngModel]="value" [placeholder]="placeholder" [disabled]="disabled" (keyup)="handleKeyup($event)"/>
    `
})
export class TextInputComponent {
    @Input() value: string = ""
    @Input() placeholder: string = ""
    @Input() disabled: boolean = false
    @Output() changeValue = new EventEmitter<string>

    handleKeyup(evt: KeyboardEvent) {
        evt.stopImmediatePropagation();
        evt.preventDefault();
        const value = (evt.target as HTMLInputElement)?.value as string ?? "";
        this.changeValue.emit(value);
    }

}