import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(req.reportProgress !== true) {
            return next.handle( 
                req.clone({ 
                    headers : req.headers
                    .set('Content-Type', req.headers.get('Content-Type') ?? 'application/json')
                })
            );
        } else {
            return next.handle(req)
        }
    }
}
