import { ChangeDetectorRef, Component, inject, OnDestroy } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalHeaderComponent } from "../modal-header/modal-header.component";
import { ModalBodyComponent } from "../modal-body/modal-body.component";
import { ModalFooterComponent } from "../modal-footer/modal-footer.component";
import {
  catchError,
  firstValueFrom,
  of,
  Subscription,
  switchMap,
  tap,
  throwError,
} from "rxjs";
import { SpaOverrideService } from "../../spah-overrides/spah-override.service";
import { ServicePointResponse } from "src/app/shared/models/servicePointResponse";
import { ServicePointAttributesConfirmationTableComponent } from "../../table/service-point-attributes-confirmation-table.component";
import { SpaOverride } from "src/app/shared/models/spaOverride";

@Component({
  standalone: true,
  imports: [
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    ServicePointAttributesConfirmationTableComponent,
  ],
  selector: "app-service-point-override-delete-modal",
  styles: `
  .spinner-anchor {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
  }
  `,
  template: `
    <div app-modal-header>Confirm Deletion</div>

    <div app-modal-body>
      <app-service-point-attributes-confirmation-table
        [loading]="loading"
        [data]="confirmationData.results"
      >
      </app-service-point-attributes-confirmation-table>
    </div>

    <div app-modal-footer>
      <button
        class="action-btn primary"
        [disabled]="!canConfirmDeletion()"
        (click)="confirmDeletion()"
      >
        Confirm
      </button>
      <button class="action-btn" (click)="onCancel()">Cancel</button>
    </div>
  `,
})
export class ServicePointOverrideDeleteModal implements OnDestroy {
  activeModal = inject(NgbActiveModal);
  servicePointOverrideService = inject(SpaOverrideService);
  changeDetectorRef = inject(ChangeDetectorRef);

  subscription = new Subscription();
  loading: boolean = true;
  confirmationData: ServicePointResponse = { results: [], total: 0 };
  item: SpaOverride;

  canConfirmDeletion(): boolean {
    return !this.loading;
  }

  close(changed = false) {
    if (changed) {
      this.activeModal.close();
    } else {
      this.activeModal.dismiss();
    }
  }

  async confirmDeletion(): Promise<void> {
    if (this.canConfirmDeletion()) {
      const workflow$ = of(this.item).pipe(
        tap(() => {
          this.loading = true;
        }),
        switchMap((req) =>
          this.servicePointOverrideService.deleteOverride(
            req.service_point_id,
            req.effective_date,
            false
          )
        ),
        tap(() => {
          this.loading = false;
          this.close(true);
        }),
        catchError((e) => {
          this.loading = false;
          return throwError(() => e);
        })
      );
      await firstValueFrom(workflow$);
    }
  }

  async init(req: SpaOverride) {
    const workflow$ = of(req).pipe(
      tap(() => {
        this.item = req;
        this.loading = true;
      }),
      switchMap((req) =>
        this.servicePointOverrideService.deleteOverride(
          req.service_point_id,
          req.effective_date,
          true
        )
      ),
      tap(() => {
        this.loading = false;
      }),
      catchError((e) => {
        this.loading = false;
        return throwError(() => e);
      })
    );
    this.confirmationData = await firstValueFrom(workflow$);
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onCancel() {
    this.close();
  }
}
