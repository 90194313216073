import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatIcon } from "@angular/material/icon";

@Component({
  standalone: true,
  imports: [MatIcon, FormsModule],
  selector: "[app-select-menu-search]",
  styles: `
        :host {
            position: relative;
            width: calc(100% - 2rem);
            margin: 1rem;
            margin-top: 1.5rem;
        }

        label {
            position: absolute;
            font-size: 0.7rem;
            line-height: 0.8rem;
            font-weight: 400;
            opacity: 0.8;
            top: -1rem;
            left: 0;
        }

        .search-icon, .add-icon-btn {
            overflow: visible;
            position: absolute;
            right: 0;
        }

        .add-icon-btn {
            padding: 0;
            height: 24px;
            border: 0;
            border-radius: 4px;

            &:disabled {
                opacity: 50%;
            }

            &:hover:not(:disabled) {
                background: gainsboro;
            }

            mat-icon {
                display:flex;
            }
        }

        input {
            width: 100%;
            padding-bottom: 0.25rem;
            padding-right: 1.75rem;
            border: none;
            border-bottom: 1px solid black;
            border-radius: 0;

            &:focus-visible {
                outline: none;
            }
        }
    `,
  template: `
    <input
      type="text"
      [id]="'{{name}}' + '-edit-search-id'"
      [ngModel]="value"
      (keyup)="handleKeyup($event)"
    />
    <label [for]="'{{name}}' + '-edit-search-id'"> Search {{ label }} </label>
    @if(showAdd) {
    <button class="add-icon-btn" [disabled]="!(value??'').length" (click)="add.emit(value ?? '')">
      <mat-icon class="add-icon" svgIcon="add_circle"></mat-icon>
    </button>
    } @else {
    <mat-icon class="search-icon" svgIcon="search"></mat-icon>
    }
  `,
})
export class SelectMenuSearchComponent {
  @Input({ required: true })
  public label: string;

  @Input({ required: true })
  public name: string;

  @Input({ required: true })
  public value: string | null;

  @Input()
  public showAdd: boolean | null;

  @Output()
  public search = new EventEmitter<string>();

  @Output()
  public add = new EventEmitter<string>();

  handleKeyup(evt: KeyboardEvent) {
      if (this.showAdd && evt.key === 'Enter') {
          evt.stopImmediatePropagation();
          evt.preventDefault();
          if(this.value) {
              this.add.emit(this.value);
            }
    }
    this.search.emit(((evt.target as HTMLInputElement)?.value as string) ?? "");
  }
}
