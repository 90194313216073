import { Component, inject, OnDestroy } from "@angular/core";
import {
  catchError,
  combineLatest,
  NEVER,
  Observable,
  shareReplay,
  Subscription,
  switchMap,
  tap,
} from "rxjs";
import {
  ColDef,
  GetRowIdFunc,
  GridApi,
  GridReadyEvent,
  IRowNode,
} from "ag-grid-community";
import { AgGridAngular } from "ag-grid-angular";
import { TableLoadingOverlayComponent } from "./overlays/table-loading-overlay.component";
import { TableNoDataOverlayComponent } from "./overlays/table-no-data-overlay.component";
import { TableSortService } from "./services/table-sort.service";
import { ActiveFilterListComponent } from "./filters/active-filter-list/active-filter-list.component";
import { RLSCertificatesService } from "../rls-certificates/rls-certificates.service";
import { RLSCertMetadata } from "src/app/shared/models/rls-cert-metadata";
import {
  filterGroup,
  RLSCertsColumnDefinition,
} from "./definitions/rls-certs-column-definition";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RLSCertConfirmDeleteModal } from "../modals/rls-cert-confirm-delete-modal/rls-cert-confirm-delete-modal.component";
import { FilterService } from "src/app/shared/services/filter.service";

@Component({
  standalone: true,
  selector: "app-rls-certs-table",
  imports: [AgGridAngular, ActiveFilterListComponent],
  providers: [TableSortService],
  styles: `
      :host {
          display: flex;
          flex-direction: column;
          justify-content: start;
      }
      app-table-pagination {
          margin-top: 1rem;
      }
    `,
  template: `
    <app-active-filter-list
      [filterGroup]="filterGroup"
    ></app-active-filter-list>
    <ag-grid-angular
      class="ag-theme-quartz app-table"
      style="height: calc(100vh - 250px)"
      [getRowId]="getRowId"
      [columnDefs]="columnDefs"
      [rowData]="[]"
      [suppressPaginationPanel]="true"
      [suppressDragLeaveHidesColumns]="true"
      [loadingOverlayComponent]="loadingOverlayComponent"
      [noRowsOverlayComponent]="noRowsOverlayComponent"
      [noRowsOverlayComponentParams]="noRowsOverlayParams"
      [pagination]="false"
      [enableCellTextSelection]="true"
      (gridReady)="onGridReady($event)"
    />
  `,
})
export class RLSCertsTableComponent implements OnDestroy {
  modalService = inject(NgbModal);
  rlsCertificatesService = inject(RLSCertificatesService);

  private subscription = new Subscription();
  public gridApi: GridApi;

  sortService = inject(TableSortService);
  filterService = inject(FilterService);

  // DATA
  public data$: Observable<RLSCertMetadata[]> = combineLatest([
    this.filterService.asParams(filterGroup),
    this.sortService.getOrder(),
  ]).pipe(
    tap(() => {
      this.gridApi.showLoadingOverlay();
    }),
    switchMap(([filters, sortOrder]) => {
      return this.rlsCertificatesService.getCertMetadata(filters, sortOrder);
    }),
    catchError(() => {
      this.noRowsOverlayParams = { error: true };
      const nodeData: IRowNode[] = [];
      this.gridApi?.forEachNode((node) => nodeData.push(node.data));
      this.gridApi?.applyTransaction({
        remove: nodeData,
      });
      this.gridApi.showNoRowsOverlay();
      return NEVER;
    }),
    shareReplay(1)
  );

  getRowId: GetRowIdFunc<RLSCertMetadata> = (data) =>
    data.data.participant + data.data.expiration_date;

  init() {
    this.subscription.add(
      this.data$.subscribe((res) => {
        const nodeData: unknown[] = [];
        this.gridApi?.forEachNode((node) => nodeData.push(node.data));
        this.gridApi?.applyTransaction({
          remove: nodeData,
          add: res,
        });
        this.gridApi.hideOverlay();
        if (!res.length) {
          this.gridApi?.forEachNode((node) => nodeData.push(node.data));
          this.gridApi?.applyTransaction({
            remove: nodeData,
          });
          this.noRowsOverlayParams = { error: false };
          this.gridApi.showNoRowsOverlay();
        }
      })
    );
  }

  destroy() {
    if (!this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.init();
  }

  ngOnDestroy() {
    this.destroy();
  }

  // columns
  public loadingOverlayComponent = TableLoadingOverlayComponent;
  public noRowsOverlayComponent = TableNoDataOverlayComponent;
  public noRowsOverlayParams = { error: false };

  public rlsCertsColumnDefinition = new RLSCertsColumnDefinition([
    {
      name: "Download",
      onAction: ((d: RLSCertMetadata) => {
        this.rlsCertificatesService.downloadCert(d.participant).subscribe();
      }).bind(this),
    },
    {
      name: "Delete",
      onAction: ((d: RLSCertMetadata) => {
        this.modalService
          .open(RLSCertConfirmDeleteModal, { centered: true })
          .componentInstance.init(d);
      }).bind(this),
    },
  ]);
  public columnDefs: ColDef[] = this.rlsCertsColumnDefinition.getColDefs();
  public filterGroup = filterGroup;
}
