import { Component, inject, Input, OnInit } from "@angular/core";
import { TableHeaderSortIndicatorComponent } from "../table-header-sort-indicator/table-header-sort-indicator.component";
import { CurrentSort } from "src/app/shared/utilities/table-sort-observable";
import { TableSortService } from "../../services/table-sort.service";
import { AsyncPipe } from "@angular/common";

@Component({
  standalone: true,
  imports: [TableHeaderSortIndicatorComponent, AsyncPipe],
  selector: "button[app-table-header-sort-button]",
  host: {
    "(click)": "sort($event)"
  },
  template: `
    <span class="header-name">{{ label }}</span>

    <!-- Show SORT INDICATOR -->
    <span
      app-table-header-sort-indicator
      [currentSort]="sortService.getCurrentSort(field) | async"
    >
    </span>
  `,
  styles: `
        :host {            
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 1.5rem);
            align-items: center;
            background-color: transparent;
            border: none;
            padding: 0;
            gap: 0.25rem;
            white-space: nowrap;
        }

        .header-name {
            white-space: break-spaces;
            text-align: start;
            text-overflow: ellipsis;
            overflow: hidden;
            max-height: 2.5rem;
            line-height: 1.2rem;
        }
    `,
})
export class TableHeaderSortButtonComponent {
  sortService = inject(TableSortService);

  @Input() public label: string;
  @Input() public field: string;
  @Input() public sortable: boolean;

  currentSort: CurrentSort;

  sort(evt: MouseEvent) {
    if (this.sortable) {
      this.sortService.next({
        name: this.field,
        multi: evt.ctrlKey,
      });
    }
  }
}
