import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (!this.isAssetRequest(request.url)) {

      const hostname = window.location.hostname;
      let baseUrl: string;

      switch(hostname) {
          case "localhost": {
              baseUrl = "http://localhost:8000"; // for local API use
              // baseUrl = "https://settlement-dev-api.np.ce.ameren.com";  // for dev API use
              break;
          }
          case "settlement-dev.np.ce.ameren.com": {
              baseUrl = "https://settlement-dev-api.np.ce.ameren.com";
              break;
          }
          case "settlement-uat.np.ce.ameren.com": {
              baseUrl = "https://settlement-uat-api.np.ce.ameren.com";
              break;
          }
          case "settlement-prod.np.ce.ameren.com": {
              baseUrl = "https://settlement-prod-api.np.ce.ameren.com";
              break;
          }
          default: {
              baseUrl = "https://settlement-dev-api.np.ce.ameren.com";
          }
      }

      let req = request.clone({ url: `${baseUrl}/${request.url}` });

      return next.handle(req);
    }
    return next.handle(request);
  }

  // loading the .svg icons for the nav hits the interceptor as well, we don't want to do that
  isAssetRequest(url: string): boolean {
    return url.startsWith("assets");
  }
}
