import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import * as moment from "moment";
import { Observable, catchError, map, of, tap } from "rxjs";
import { IntervalUsage } from "src/app/shared/models/intervalUsage";
import { IntervalUsageHistory } from "src/app/shared/models/intervalUsageHistory";
import { IntervalUsageHistoryResponse } from "src/app/shared/models/intervalUsageHistoryResponse";
import { IntervalUsageResponse } from "src/app/shared/models/intervalUsageResponse";
import { ServicePointIdReference } from "src/app/shared/models/referenceData";
import {
  PaginationParams,
  FilterParams,
  SortParams,
  httpParams,
  pagination,
  sort,
  withHttpParams,
  filter,
} from "src/app/shared/utilities/http-params";
import { ToastService } from "../toasts/toasts.service";

@Injectable({
  providedIn: "root",
})
export class IntervalUsageService {
  http = inject(HttpClient);
  toast = inject(ToastService);

  getIntervalUsage(
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ) {
    const params = httpParams(
      withHttpParams(pagination, page),
      withHttpParams(filter, filters),
      withHttpParams(sort, order)
    );
    return this.http
      .get<IntervalUsageResponse>("service_point_measurement/", {
        params,
        headers: {
          NOLOADING: "true",
        },
      }).pipe(catchError(this.handleError.bind(this)));
  }

  getIntervalUsageHistory(servicePointRef: ServicePointIdReference, marketInterval: Date): Observable<IntervalUsageHistory[]> {
    const params = httpParams(
      withHttpParams(pagination, {pageNumber: 0, pageSize: 1000}),
      withHttpParams(filter, [
        {name: 'service_point_id', values: [{value: servicePointRef.service_point_id}]},
        {name: 'service_point_uid', values: [{value: servicePointRef.service_point_uid}]},
        {name: 'market_interval', values: [{value: moment(marketInterval).utc().toISOString(true)}]},
      ]));
    return this.http.get<IntervalUsageHistoryResponse>("service_point_measurement/history", {
      params,
      headers: {
        NOLOADING: "true",
      },
    }).pipe(
      catchError(this.handleError.bind(this)),
      map(res => res.results)
    )
  }

  createIntervalUsage(partials: Partial<IntervalUsage>[]){
    const reqBody = partials.map(p => ({
      service_point_uid: p.service_point_uid,
      market_interval: p.market_interval,
      measurement_value: p.measurement_value,
      "measurement_value_type_code": "DSP",
      "channel_measurement_channel_code": "-1",
      "interval_type_code": "HR"
    }));
    return this.http.post('service_point_measurement/import', reqBody ).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  downloadImportTemplate() {
    return of('data:text/csv;base64,' + btoa(`service_point_id,market_interval,measurement_value
    00000110173450847,${moment().startOf('hour').toISOString(true)},1000.0000`
      )).pipe(
      tap((url) => {
        var link = document.createElement("a");
        link.download="interval-usage-import-template.csv"
        link.href = url;
        link.click();
      })
    )
  }

  downloadExports(service_point_uid: string[], market_interval_start: Date, market_interval_end: Date) {
    const params = httpParams(
      withHttpParams(filter, [
        { name: "service_point_uid", values: service_point_uid.map(value => ({value}))},
        { name: "market_interval_start", values: [{value: moment(market_interval_start).toISOString(true)}]},
        { name: "market_interval_end", values: [{value: moment(market_interval_end).toISOString(true)}]}
      ]),
    );
    return this.http.get<string[]>('service_point_measurement/export', {
      params,
      headers: {
        NOLOADING: "true",
      },
    }).pipe(
      tap(urls => {
        const timestamp = moment().unix();
        urls.forEach((url, i) => {
          var link = document.createElement("a");
          link.download=`interval-usage-export-${i}-${timestamp}.csv`
          link.href = url;
          link.click();
        })
      }),
      catchError(this.handleError.bind(this)),
    )
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error(
        "There is an issue with the client or network:",
        error.error
      );
    } else {
      console.error("Server-side error: ", error.error);
    }
    this.toast.show({type: 'error', message: 'Something went wrong, please try again!'})
    return of({total:0, results: [] as IntervalUsage[], error: true});
  }
}
