import { Component, inject } from "@angular/core";
import { PageHeaderComponent } from "../page-header/page-header.component";
import { PageContentComponent } from "../page-content/page-content.component";
import { RLSCertsTableComponent } from "../table/rls-certs-table.component";
import { MatIcon } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { RLSCertUploadModal } from "../modals/rls-cert-upload-modal/rls-cert-upload-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    standalone: true,
    selector: 'app-rls-certificates',
    imports: [
        MatMenuModule, 
        MatIcon, 
        PageHeaderComponent, 
        PageContentComponent, 
        RLSCertsTableComponent
    ],
    styles: ``,
    template: `
    <app-page-header title="Digital Certificates">
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onUploadAction()">Upload</button>
      </mat-menu>
      <button
        class="action-btn icon"
        mat-icon-button
        [matMenuTriggerFor]="menu"
      >
        <mat-icon svgIcon="setting-list"></mat-icon>
      </button>
    </app-page-header>
    <app-page-content>
        <app-rls-certs-table></app-rls-certs-table>
    </app-page-content>`,
})
export class RLSCertificatesComponent {
    private modalService = inject(NgbModal);
    onUploadAction() {
      this.modalService.open(RLSCertUploadModal, {centered: true})
    }
}