import { CommonModule } from "@angular/common";
import { Component, TemplateRef } from "@angular/core";
import type { INoRowsOverlayAngularComp } from "ag-grid-angular";
import type { INoRowsOverlayParams } from "ag-grid-community";

type Params =  INoRowsOverlayParams<any, any> & {
    error: boolean,
    message: string
}

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: "app-no-data-overlay-component",
  styles: `
    p {
        margin: 2rem;
        font-size: 0.9rem;
        font-weight: normal;

        &.error {
            font-weight: bold;
            font-size: 1rem;
            color: red;
        }
    }
  `,
  template: `
  @if(params?.error) {
    <p class="error">
      {{params?.message ?? 'An error occurred fetching data! Please try again.'}}
    </p>
  } 
  @else {
    <p>
        {{params?.message ?? 'No data to display! If you expected to see data check that your filters are correct.'}}
    </p>
  }
  `,
})
export class TableNoDataOverlayComponent implements INoRowsOverlayAngularComp {
  params?: Params;

  agInit(params: Params): void {
    this.refresh(params);
  }

  refresh(params: Params): void {
    this.params = params;
  }
}
