import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from "@angular/common/http";
import { Observable, catchError, throwError } from "rxjs";
import { AuthLoginService } from "../services/auth-login.service";

@Injectable()
export class AuthCheckInterceptor implements HttpInterceptor {
  constructor(private service: AuthLoginService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let req = request.clone({
      withCredentials: true
    });

    return next.handle(req).pipe(catchError(err => {
      if ([401].includes(err.status) && req.headers.get('EXPECT_FAILURE') !== 'true') {
        this.service.login();
      }
      const error = err.error.message || err.statusText || 'An unknown error occurred!';
      return throwError(() => error);
    }));  
  }
}
