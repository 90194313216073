import { Component, Input } from "@angular/core";

@Component({
  standalone: true,
  selector: "[app-labeled-property]",
  styles: `
    :host {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        align-items: baseline;

        & > span {
            font-weight: 500;
        }
    }
  `,
  template: `<span>{{ label }}</span>
    <div><ng-content></ng-content></div>`,
})
export class LabeledPropertyComponent {
  @Input({ required: true })
  label: string;
}
