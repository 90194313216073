import { Component, OnDestroy, OnInit, inject } from "@angular/core";
import { ModalHeaderComponent } from "../modal-header/modal-header.component";
import { ModalFooterComponent } from "../modal-footer/modal-footer.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalBodyComponent } from "../modal-body/modal-body.component";
import { RightAlignComponent } from "../../right-align/right-align.component";
import { ImportIntervalUsageTableComponent } from "../../table/import-interval-usage-table.component";
import {
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  catchError,
  combineLatest,
  concatMap,
  firstValueFrom,
  from,
  map,
  mergeMap,
  of,
  shareReplay,
  switchMap,
  tap,
} from "rxjs";
import { IntervalUsage } from "src/app/shared/models/intervalUsage";
import { FileInputComponent } from "../../file-input/file-input.component";
import { ReferenceDataService } from "../../reference-data/reference-data.service";
import * as Papa from "papaparse";
import { chunk } from "lodash";
import * as moment from "moment";
import { IntervalUsageService } from "../../interval-usage/interval-usage.service";
import { ToastService } from "../../toasts/toasts.service";

@Component({
  standalone: true,
  imports: [
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    RightAlignComponent,
    ImportIntervalUsageTableComponent,
    FileInputComponent,
  ],
  selector: "app-interval-usage-import-modal",
  styles: `
    .error {
      color: red;
      font-size: 0.65rem;
    }
  `,
  template: `
    <div app-modal-header>Import</div>
    <div app-modal-body>
      <div app-right-align>
        <button class="action-btn" (click)="onDownloadTemplate()">
          Download Template
        </button>
        <div
          app-file-input
          [accept]="'text/csv'"
          [skipParse]="true"
          (changeFile)="onUpload($event)"
        >
          Upload File
        </div>
      </div>
      <app-import-interval-usage-table
        [data]="data"
        [loading]="loading"
        [error]="error"
      ></app-import-interval-usage-table>
    </div>
    <div app-modal-footer>
      <button class="action-btn" (click)="onCancel()">Cancel</button>
      <button
        class="action-btn primary"
        (click)="onImport()"
        [disabled]="loading || error || !data.length"
      >
        Import
      </button>
    </div>
  `,
})
export class IntervalUsageImportModalComponent implements OnInit, OnDestroy {
  activeModal = inject(NgbActiveModal);
  toastService = inject(ToastService);
  referenceDataService = inject(ReferenceDataService);
  intervalUsageService = inject(IntervalUsageService);

  subscription = new Subscription();
  loading = false;
  error = "";
  fileData$ = new Subject<File | undefined>();
  data: Partial<IntervalUsage>[] = [];
  data$ = this.fileData$.pipe(
    tap(this.startLoadingSequence.bind(this)),
    switchMap(this.loadCSV.bind(this)),
    switchMap(this.withServicePointUids.bind(this)),
    tap((partials) => {
      const error: string | undefined = partials
        .map((p) => {
          if (!p.service_point_uid) {
            return `Service point ${p.service_point_id} was not found. Please ensure it's existence and try again`;
          }
          if (!p.market_interval) {
            return `Market Interval must be provided. Please correct this and try again and try again`;
          }
          if (!p.measurement_value) {
            return `Measurement Value must be provided. Please correct this and try again and try again`;
          }
          if (!moment(p.market_interval).isValid()) {
            return `Market Interval ${p.market_interval} is not a valid date. Please correct this and try again and try again`;
          }
          if (isNaN(parseFloat(p.measurement_value))) {
            return `Measurement Value ${p.measurement_value} is not a number. Please correct this and try again and try again`;
          }
        })
        .find(Boolean);
      if (error) {
        throw error;
      }
    })
  );

  startLoadingSequence() {
    this.loading = true;
    this.error = "";
  }

  loadCSV(file: File | undefined): Observable<Partial<IntervalUsage>[]> {
    return new Observable((observer) => {
      const rows: Partial<IntervalUsage>[] = [];
      if (file) {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          step: (results: any) => {
            if (results.errors && results.errors.length) {
              results.errors.forEach(console.error);
              observer.error(
                "The file selected is invalid, please correct the file and retry"
              );
            } else {
              rows.push(results.data);
            }
          },
          complete: () => observer.next(rows),
        });
      }
    });
  }

  withServicePointUids(
    partials: Partial<IntervalUsage>[]
  ): Observable<Partial<IntervalUsage>[]> {
    const presentServicePointIds: string[] = [];
    partials.forEach((p) => {
      if (
        p.service_point_id &&
        !presentServicePointIds.includes(p.service_point_id ?? "")
      ) {
        presentServicePointIds.push(p.service_point_id);
      }
    });
    return combineLatest([
      of(partials),
      this.servicePointIdsInList(partials).pipe(
        switchMap((ids) =>
          this.referenceDataService.servicePoints(
            { pageNumber: 0, pageSize: 1000 },
            [
              {
                name: "service_point_id",
                values: ids.map((value) => ({
                  value: value.replace(/^0+/, ""),
                })),
              },
            ],
            [{ name: "service_point_id" }]
          )
        )
      ),
    ]).pipe(
      map(([partials, referenceData]) => {
        const idMap: Record<string, string> = referenceData.reduce(
          (acc, ref) => ({
            ...acc,
            [ref.service_point_id]: ref.service_point_uid,
          }),
          {}
        );
        return partials.map((p) => ({
          ...p,
          service_point_uid: idMap[p.service_point_id ?? ""],
        }));
      })
    );
  }

  servicePointIdsInList(
    partials: Partial<IntervalUsage>[]
  ): Observable<string[]> {
    const presentServicePointIds: string[] = [];
    partials.forEach((p) => {
      if (
        p.service_point_id &&
        !presentServicePointIds.includes(p.service_point_id ?? "")
      ) {
        presentServicePointIds.push(p.service_point_id);
      }
    });
    return of(presentServicePointIds);
  }

  endLoadingSequence(data?: Partial<IntervalUsage>[], err?: string) {
    this.data = data ?? [];
    this.loading = false;
    this.error = err ?? "";
  }

  getSubscription() {
    return this.data$.subscribe({
      next: (data) => this.endLoadingSequence(data),
      error: (err) => this.endLoadingSequence(undefined, err),
      complete: () => {
        console.log("completed");
      },
    });
  }

  ngOnInit(): void {
    this.subscription.add(this.getSubscription());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onCancel() {
    this.activeModal.dismiss();
  }

  async onImport() {
    this.loading = true;
    try {
      await firstValueFrom(
        this.intervalUsageService.createIntervalUsage(this.data)
      );
      this.activeModal.dismiss();
    } catch (e) {
      this.toastService.show({
        type: "error",
        message: "Something went wrong! Please review your data and try again",
      });
    }
    this.loading = false;
  }

  onUpload(file: File | undefined) {
    this.fileData$.next(file);
  }

  async onDownloadTemplate() {
    await firstValueFrom(this.intervalUsageService.downloadImportTemplate());
  }
}
