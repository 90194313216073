import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ServicePointResponse } from "src/app/shared/models/servicePointResponse";
import {
  PaginationParams,
  FilterParams,
  SortParams,
  httpParams,
  withHttpParams,
  pagination,
  sort,
  filter,
} from "src/app/shared/utilities/http-params";
import { BehaviorSubject, Observable, switchMap } from "rxjs";
import { SpaOverrideResponse } from "src/app/shared/models/spaOverrideResponse";
import { SpaOverride } from "src/app/shared/models/spaOverride";

export interface Filter {
  field: string;
  value: string;
}

@Injectable({
  providedIn: "root",
})
export class SpaOverrideService {
  constructor(private http: HttpClient) {}

  _createOverride(
    service_point_id: string,
    data: Partial<SpaOverride>,
    dryRun: boolean
  ) {
    return this.http.post<ServicePointResponse>(
      `service_points/${service_point_id}/override`,
      { ...data, dry_run: dryRun },
      {
        headers: {
          NOLOADING: "true",
        },
      }
    );
  }

  createOverride(
    data: Partial<SpaOverride>,
    dryRun: boolean
  ): Observable<ServicePointResponse> {
    return this._createOverride(data.service_point_id!, data, dryRun);
  }

  updateOverride(
    data: Partial<SpaOverride>,
    dryRun: boolean
  ): Observable<ServicePointResponse> {
    return this._createOverride(data.service_point_id!, data, dryRun);
  }

  deleteOverride(
    id: string,
    effectiveDate: string,
    dryRun: boolean
  ): Observable<ServicePointResponse> {
    return this.http.delete<ServicePointResponse>(
      `service_points/${id}/override/${effectiveDate}`,
      {
        headers: {
          NOLOADING: "true",
        },
        params: {
          dry_run: dryRun,
        },
      }
    );
  }

  getOverrides(
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ) {
    const params = httpParams(
      withHttpParams(pagination, page),
      withHttpParams(filter, filters),
      withHttpParams(sort, order)
    );
    return this.http.get<SpaOverrideResponse>("service_points/overrides", {
      params,
      headers: {
        NOLOADING: "true",
      },
    });
  }
}
