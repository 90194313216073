import { Component } from "@angular/core";
import { BaseCellComponent } from "./base-cell.component";
import { NamedDate } from "src/app/shared/models/namedDate";
import { MaskedDatePipe } from "src/app/shared/pipes/maskedDate.pipe";

type DateCellComponentProps = {
  namedDates?: NamedDate[];
  format?: string;
};

@Component({
  standalone: true,
  imports: [MaskedDatePipe],
  styles: `
        :host {
            position: relative;
        }
    `,
  template: `
    {{ params.value | maskedDate : params.namedDates : params.format }}
  `,
})
export class DateCellComponent<
  DATA extends Record<string, unknown>
> extends BaseCellComponent<
  DATA,
  DateCellComponentProps,
  keyof DATA,
  DATA[keyof DATA] & string
> {}
