import { ColDef } from "ag-grid-community";
import { DateCellComponent } from "../cells/date-cell.component";
import { ReferenceDataService } from "../../reference-data/reference-data.service";
import {
  ActionCellComponent,
  ActionCellProps,
} from "../cells/action-cell.component";
import { MDMAJobStatus } from "../../settlements/service/settlements.types";
import { TableHeaderComponent } from "../headers/table-header.component";
import { EntityFilterModalComponent } from "../../modals/filters/entity-filter-modal.component";
import { DateFilterModalComponent } from "../../modals/filters/date-filter-modal.component";

export const filterGroup = "mdma_aggregation";

export class MDMAAggregationsColumnDefinition {
  constructor(
    private refDataService: ReferenceDataService,
    private actions: ActionCellProps<MDMAJobStatus>["actions"]
  ) {}

  public sharedDef: ColDef = {
    filter: true,
    sortable: true,
    editable: false,
    minWidth: 150,
    width: 250,
  };

  getColDefs = (): ColDef[] => [
    {
      ...this.sharedDef,
      flex: 1,
      headerName: "Operating Day",
      field: "operating_day",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        format: "YYYY/MM/DD",
      },
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: DateFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {},
      },
    },
    {
      ...this.sharedDef,
      headerName: "Settlement Type",
      field: "settlement_type",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: EntityFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {
          resource: this.refDataService.settlementTypes.name,
        },
      },
    },
    {
      ...this.sharedDef,
      headerName: "Status",
      field: "run_status",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: EntityFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {
          resource: this.refDataService.runStatuses.name,
        },
      },
    },
    {
      ...this.sharedDef,
      headerName: "Created On",
      field: "start_time",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        format: "YYYY/MM/DD",
      },
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: DateFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {},
      },
    },
    {
      ...this.sharedDef,
      width: 75,
      pinned: "right",
      resizable: false,
      suppressMovable: true,
      filter: false,
      sortable: false,
      cellRenderer: ActionCellComponent,
      cellRendererParams: {
        actions: this.actions,
      },
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup
      }
    },
  ];
}
