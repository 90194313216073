import { AsyncPipe } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { FilterService } from "src/app/shared/services/filter.service";

@Component({
  standalone: true,
  imports: [AsyncPipe, MatIcon],
  selector: "app-active-filter-list",
  styles: `
    ul {
      min-height: 3rem;
      margin-bottom: 0.5rem;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      align-items: end;
      gap: 0.5rem;
      padding: 0;
    }
  
    li {
      display: flex;
      gap: 0.25rem;
      min-height: 1.25rem;
      line-height: 1.25rem;
      border-radius: 0.5rem;
      border: none;
      background: gainsboro;
      padding: 0 0.5rem;
    }

    button {
      margin: 0;
      padding: 0;
      border: 0;
      background: none;
    }

    .icon {
      max-height: 1.25rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0;
      margin: 0;
    }
  `,
  template: `<ul>
    @for(i of active() | async; track $index){
    <li>
      <span>{{ i.headerName }}&nbsp;{{ i.operation }}&nbsp;{{ i.value }}</span
      ><button (click)="i.remove()">
        <mat-icon class="icon" svgIcon="close" />
      </button>
    </li>
    }
  </ul>`,
})
export class ActiveFilterListComponent {
  private filterService = inject(FilterService);

  @Input()
  filterGroup: string;

  @Input()
  filterName?: string;

  public active() {
    return this.filterService.asActiveDisplay(
      this.filterGroup,
      this.filterName
    );
  }
}
