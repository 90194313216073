import { ChangeDetectorRef, Component, inject, OnInit } from "@angular/core";
import { LoadingSpinnerService } from "./service/loading-spinner.service";

@Component({
  selector: "loading-spinner",
  templateUrl: "./loading-spinner.component.html",
  styleUrls: ["./loading-spinner.component.scss"],
})
export class LoadingSpinnerComponent implements OnInit {
  changeDetectorRef = inject(ChangeDetectorRef);
  spinnerService = inject(LoadingSpinnerService);
  spinnerActive: boolean = true;

  ngOnInit(): void {
    this.spinnerService.showSpinner.subscribe(this.showSpinner.bind(this));
  }

  showSpinner = (state: boolean): void => {
    if (state !== this.spinnerActive) {
      this.spinnerActive = state;
      this.changeDetectorRef.detectChanges();
    }
  };
}
