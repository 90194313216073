import { ColDef } from "ag-grid-community";
import { AlwaysEditableTextCellComponent } from "../cells/always-editable-text-cell.component";
import { TableHeaderComponent } from "../headers/table-header.component";
import { DateCellComponent } from "../cells/date-cell.component";

const filterGroup = "update_interval_usage";

export class UpdateIntervalUsageColumnDefinition {
  constructor() {}

  sharedDef: ColDef = {
    filter: false,
    sortable: false,
    editable: false,
    minWidth: 100,
    width: 150,
    flex: 1,
  };

  getColDefs = (): ColDef[] => [
    {
      ...this.sharedDef,
      headerName: "Time",
      field: "market_interval",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        format: "YYYY/MM/DD HH:00",
      },
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
      },
    },
    {
      ...this.sharedDef,
      headerName: "Last Value (kW h)",
      field: "measurement_value",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
      },
    },
    {
      ...this.sharedDef,
      width: 175,
      field: "override",
      headerName: "Override (kW h)",
      cellRenderer: AlwaysEditableTextCellComponent,
      cellRendererParams: {
        inputFilter: (value: string) => /^-?\d*[.,]?\d*$/.test(value),
      },
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
      },
    },
    {
      ...this.sharedDef,
      width: 175,
      headerName: "Last Updated At",
      field: "last_update_time",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        format: "YYYY/MM/DD HH:mm:ss",
      },
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
      },
    },
    {
      ...this.sharedDef,
      width: 175,
      headerName: "Last Updated By",
      field: "last_updated_by",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
      },
    },
  ];
}
