import {
  Component,
  inject,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { combineLatest, filter, Subscription, switchMap, tap } from "rxjs";
import {
  ColDef,
  GetRowIdFunc,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { AgGridAngular } from "ag-grid-angular";
import { TablePaginationComponent } from "./table-pagination/table-pagination.component";
import { TableLoadingOverlayComponent } from "./overlays/table-loading-overlay.component";
import { TableNoDataOverlayComponent } from "./overlays/table-no-data-overlay.component";
import { TableSortService } from "./services/table-sort.service";
import { IntervalUsage } from "src/app/shared/models/intervalUsage";
import {
  filterGroup,
  IntervalUsageColumnDefinition,
} from "./definitions/interval-usage-column-definition";
import { ReferenceDataService } from "../reference-data/reference-data.service";
import { IntervalUsageService } from "../interval-usage/interval-usage.service";
import {
  TablePageService,
  TablePageState,
} from "./services/table-page.service";
import { IntervalUsageResponse } from "src/app/shared/models/intervalUsageResponse";
import { ActiveFilterListComponent } from "./filters/active-filter-list/active-filter-list.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IntervalUsageHistoryModalComponent } from "../modals/interval-usage-history-modal/interval-usage-history-modal.component";
import { UpdateIntervalUsageModalComponent } from "../modals/update-interval-usage-modal/update-interval-usage-modal.component";
import { FilterParams } from "src/app/shared/utilities/http-params";
import { FilterService } from "src/app/shared/services/filter.service";
import { ServicePointFilterModalComponent } from "../modals/filters/service-point-filter-modal.component";
import { DateRangeFilterModalComponent } from "../modals/filters/date-range-filter-modal.component";

@Component({
  standalone: true,
  selector: "app-interval-usage-table",
  imports: [AgGridAngular, TablePaginationComponent, ActiveFilterListComponent],
  styles: `
      :host {
        display: flex;
        flex-direction: column;
        justify-content: start;
      }

      .more-info-needed {
        position: absolute;
        top: calc(50% - 6rem);
        height: 6rem;
        font-weight: bold;
        font-size: 1rem;
        color: green;
        left: calc(50% - 15rem);
        max-width: 30rem;
        display: ruby;

        button:last-of-type {
          margin: 0 0rem;
        }
      }
        
      app-table-pagination {
        margin-top: 1rem;
      }
    `,
  template: `
    <app-active-filter-list
      [filterGroup]="filterGroup"
    ></app-active-filter-list>
    <ag-grid-angular
      class="ag-theme-quartz app-table"
      style="height: 70vh"
      [getRowId]="getRowId"
      [columnDefs]="columnDefs"
      [rowData]="[]"
      [suppressPaginationPanel]="true"
      [suppressDragLeaveHidesColumns]="true"
      [loadingOverlayComponent]="loadingOverlayComponent"
      [noRowsOverlayComponent]="noRowsOverlayComponent"
      [noRowsOverlayComponentParams]="noRowsOverlayParams"
      [pagination]="false"
      [enableCellTextSelection]="true"
      (gridReady)="onGridReady($event)"
    />
    <app-table-pagination
      [pageNumber]="pageState?.pageNumber ?? 0"
      [pageSize]="pageState?.pageSize ?? 0"
      [totalResults]="pageState?.total ?? 0"
      (pageNumberChange)="pageService.pageNumber$.next($event)"
      (pageSizeChange)="pageService.pageSize$.next($event)"
    ></app-table-pagination>

    @if(showMoreInfoNeeded) {
    <span class="more-info-needed">
      <span
        >Interval usage contains an enormous amount of data! In order to query
        this data you must provide a</span
      >
      <button
        class="link-btn primary"
        (click)="chooseServicePoints()"
      >
        Service Point
      </button>
      <span>as well as a</span>
      <button class="link-btn primary" (click)="chooseMarketInterval()">
        Market Interval
      </button>
    </span>
    }
  `,
})
export class IntervalUsageTableComponent implements OnDestroy {
  modalService = inject(NgbModal);
  pageService = inject(TablePageService);
  sortService = inject(TableSortService);
  filterService = inject(FilterService);
  intervalUsageService = inject(IntervalUsageService);
  referenceDataService = inject(ReferenceDataService);

  showMoreInfoNeeded = true;

  private subscription = new Subscription();
  pageState: TablePageState | undefined;

  public gridApi: GridApi;

  getRowId: GetRowIdFunc<IntervalUsage> = (data) =>
    `${data.data.service_point_id}-${data.data.operating_date}-${data.data.market_interval}`;

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.init();
  }

  private init() {
    this.pageService.pageSize$.next(50);
    this.subscription.add(
      this.pageService.state$.subscribe((pageState) => {
        this.pageState = pageState;
      })
    );

    this.subscription.add(
      combineLatest([
        this.pageService.params$,
        this.filterService.asParams(filterGroup),
        this.sortService.getOrder(),
      ])
        .pipe(
          filter(([, filters]) => {
            const canRequest = this.minimumFiltersArePresent(filters);
            if (!canRequest) {
              this.showSelectionNeeded();
            }
            return canRequest;
          }),
          tap(() => {
            this.showLoading();
          }),
          switchMap(([page, filters, order]) =>
            this.intervalUsageService.getIntervalUsage(page, filters, order)
          )
        )
        .subscribe((res) => {
          this.updateData(res);
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  minimumFiltersArePresent(filterParams: FilterParams[]): boolean {
    const hasServicePoints = !!filterParams.find(
      (fp) => fp.name === "service_point_uid"
    )?.values.length;
    const hasMarketInterval = !!filterParams.find(
      (fp) => fp.name === "market_interval"
    )?.values.length;
    return hasServicePoints && hasMarketInterval;
  }

  clearTable() {
    const nodeData: unknown[] = [];
    this.gridApi?.forEachNode((node) => nodeData.push(node.data));
    this.gridApi?.applyTransaction({
      remove: nodeData,
    });
  }

  showLoading() {
    this.hideOverlays();
    this.gridApi.showLoadingOverlay();
  }

  updateData(response: IntervalUsageResponse & { error?: boolean }) {
    if (response.error) {
      return this.showError();
    }
    if (response.total <= 0) {
      return this.showNoData();
    }

    this.pageService.total$.next(response.total);
    const nodeData: unknown[] = [];
    this.gridApi?.forEachNode((node) => nodeData.push(node.data));
    this.gridApi?.applyTransaction({
      remove: nodeData,
      add: response.results,
    });
    this.gridApi.hideOverlay();
  }

  showSelectionNeeded() {
    this.noRowsOverlayParams = { error: false };
    this.hideOverlays();
    this.showMoreInfoNeeded = true;
  }

  showNoData() {
    this.clearTable();
    this.noRowsOverlayParams = { error: false };
    this.hideOverlays();
    this.gridApi.showNoRowsOverlay();
  }

  showError() {
    this.clearTable();
    this.noRowsOverlayParams = { error: true };
    this.hideOverlays();
    this.gridApi.showNoRowsOverlay();
  }

  hideOverlays() {
    this.showMoreInfoNeeded = false;
    this.gridApi.hideOverlay();
  }

  onViewHistoryAction(data: IntervalUsage) {
    this.modalService
      .open(IntervalUsageHistoryModalComponent, {
        centered: true,
        windowClass: "confirmation-modal",
      })
      .componentInstance.init(data);
  }

  onEditAction(data: IntervalUsage) {
    this.modalService
      .open(UpdateIntervalUsageModalComponent, {
        centered: true,
        windowClass: "confirmation-modal",
      })
      .componentInstance.init(data);
  }

  chooseServicePoints() {
    this.modalService
      .open(ServicePointFilterModalComponent, {
        centered: true,
      })
      .componentInstance.init(
        { allowPartial: false, useUids: true },
        filterGroup,
        "service_point_uid",
        "Service Point"
      );
  }

  chooseMarketInterval() {
    this.modalService
      .open(DateRangeFilterModalComponent, {
        centered: true,
      })
      .componentInstance.init(
        { enableHours: true },
        filterGroup,
        "market_interval",
        "Market Interval"
      );
  }

  // columns
  public loadingOverlayComponent = TableLoadingOverlayComponent;
  public noRowsOverlayComponent = TableNoDataOverlayComponent;
  public noRowsOverlayParams = { error: false };

  tableColumnDef = new IntervalUsageColumnDefinition(
    this.onViewHistoryAction.bind(this),
    [
      {
        name: "Edit",
        onAction: this.onEditAction.bind(this),
      },
    ]
  );
  public columnDefs: ColDef[] = this.tableColumnDef.getColDefs();
  public filterGroup = filterGroup;
}
