import { ColDef, IRowNode } from "ag-grid-community";
import { DateCellComponent } from "../cells/date-cell.component";
import { IntervalUsage } from "src/app/shared/models/intervalUsage";
import { TableHeaderComponent } from "../headers/table-header.component";

const filterGroup = "import_inteval_usage";

export class ImportIntervalUsageColumnDefinition {
  constructor() {}

  sharedDef: ColDef = {
    filter: false,
    sortable: false,
    editable: false,
    minWidth: 100,
    width: 150,
  };

  getColDefs = (): ColDef[] => [
    {
      ...this.sharedDef,
      width: 165,
      headerName: "Service Point",
      field: "service_point_id",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
      },
    },
    {
      ...this.sharedDef,
      filter: true,
      sortable: true,
      headerName: "Market Interval",
      field: "market_interval",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        format: "YYYY/MM/DD HH:00",
      },
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
      },
    },
    {
      ...this.sharedDef,
      headerName: "Usage (kW h)",
      field: "usage",
      cellRenderer: (d: IRowNode<Partial<IntervalUsage>>) =>
        d.data?.measurement_value,
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
      },
    },
  ];
}
