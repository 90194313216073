import { ColDef, IRowNode } from "ag-grid-community";
import { DateCellComponent } from "../cells/date-cell.component";
import {
  ActionCellComponent,
  ActionCellProps,
} from "../cells/action-cell.component";
import { IntervalUsage } from "src/app/shared/models/intervalUsage";
import { TableHeaderComponent } from "../headers/table-header.component";
import { DateRangeFilterModalComponent } from "../../modals/filters/date-range-filter-modal.component";
import { ServicePointFilterModalComponent } from "../../modals/filters/service-point-filter-modal.component";
import { StringFilterModalComponent } from "../../modals/filters/string-filter-modal.component";

export const filterGroup = "interval_usage";

export class IntervalUsageColumnDefinition {
  constructor(
    private onViewHistoryAction: (data: IntervalUsage) => void,
    private actions: ActionCellProps<IntervalUsage>["actions"]
  ) {}

  public sharedDef: ColDef = {
    filter: false,
    sortable: false,
    editable: false,
    minWidth: 100,
    width: 150,
  };

  getColDefs = (): ColDef[] => [
    {
      ...this.sharedDef,
      filter: true,
      sortable: true,
      minWidth: 165,
      flex: 1,
      headerName: "Service Point",
      field: "service_point_id",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: ServicePointFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {
          allowPartial: false,
          useUids: true,
          filterField: 'service_point_uid'
        },
      },
    },
    {
      ...this.sharedDef,
      filter: true,
      sortable: true,
      headerName: "Market Interval",
      field: "market_interval",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        format: "YYYY/MM/DD HH:00",
      },
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: DateRangeFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {
          enableHours: true
        },
      },
    },
    {
      ...this.sharedDef,
      headerName: "Usage (kW h)",
      field: "usage",
      cellRenderer: (d: IRowNode<IntervalUsage>) => d.data?.measurement_value,
      onCellClicked: (evt) => this.onViewHistoryAction(evt.data),
      cellStyle: { cursor: "pointer" },
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
      },
    },
    {
      ...this.sharedDef,
      width: 175,
      headerName: "Last Updated At",
      field: "last_update_time",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        format: "YYYY/MM/DD HH:mm:ss",
      },
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
      },
    },
    {
      ...this.sharedDef,
      filter: true,
      sortable: true,
      width: 175,
      headerName: "Last Updated By",
      field: "last_updated_by",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: StringFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {},
      },
    },
    {
      ...this.sharedDef,
      width: 75,
      pinned: "right",
      resizable: false,
      suppressMovable: true,
      cellRenderer: ActionCellComponent<IntervalUsage>,
      cellRendererParams: {
        actions: this.actions,
      },
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
      },
    },
  ];
}
