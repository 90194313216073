import { Component, inject, OnDestroy } from "@angular/core";
import { AbstractFilterModal } from "./abstract-filter-modal";
import { ModalHeaderComponent } from "../modal-header/modal-header.component";
import { ModalBodyComponent } from "../modal-body/modal-body.component";
import { ModalFooterComponent } from "../modal-footer/modal-footer.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import {
  EntityFilter,
  FilterService,
} from "src/app/shared/services/filter.service";
import { ActiveFilterListComponent } from "../../table/filters/active-filter-list/active-filter-list.component";
import { ReferenceDataService } from "../../reference-data/reference-data.service";
import { EntitySelectorComponent } from "../../entity-selector/entity-selector.component";
import { EntityRef } from "src/app/shared/models/referenceData";
import { LabeledPropertyComponent } from "../../labeled-property/labeled-property.component";

type EntityFilterModalInit = {
  resource: Omit<keyof ReferenceDataService, "servicePoints">;
};

@Component({
  standalone: true,
  imports: [
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    LabeledPropertyComponent,
    EntitySelectorComponent,
    ActiveFilterListComponent,
  ],
  selector: "app-entity-filter-modal",
  styles: `
  `,
  template: `
    <div app-modal-header>{{ fieldNameDisplay }}</div>
    <div app-modal-body>
      <p>Select a filter values for the column {{ fieldNameDisplay }} below</p>
      <div app-labeled-property [label]="fieldNameDisplay">
        <div
          app-entity-selector
          (valueChange)="select($event)"
          [selectedCodes]="selected"
          [resource]="modalInit.resource"
          [slim]="true"
        ></div>
      </div>
      <app-active-filter-list
        [filterGroup]="filterGroup"
        [filterName]="field"
      ></app-active-filter-list>
    </div>
    <div app-modal-footer>
      <button class="action-btn" (click)="onClose()">Close</button>
    </div>
  `,
})
export class EntityFilterModalComponent
  extends AbstractFilterModal<EntityFilterModalInit>
  implements OnDestroy
{
  private modal = inject(NgbActiveModal);
  private filterService = inject(FilterService);

  private subscription = new Subscription();
  public filterGroup: string;
  public field: string;
  public modalInit: EntityFilterModalInit;
  public fieldNameDisplay: string = "";
  public selected: Set<string> = new Set();

  public override init(
    modalInit: EntityFilterModalInit,
    filterGroup: string,
    field: string,
    fieldNameDisplay: string
  ): void {
    this.modalInit = modalInit;
    this.filterGroup = filterGroup;
    this.field = field;
    this.fieldNameDisplay = fieldNameDisplay;

    this.subscription.add(
      this.filterService.state(this.filterGroup).subscribe((fp) => {
        this.selected = new Set(
          ((fp[this.field] as EntityFilter)?.value ?? []).map(
            (e) => e.entity_code
          )
        );
      })
    );
  }

  select(entity: EntityRef) {
    if (this.selected.has(entity.entity_code)) {
      this.filterService.dispatch({
        type: "REMOVE_ENTITY_FILTER",
        code: entity.entity_code,
        filterGroup: this.filterGroup,
        filterName: this.field,
      });
    } else {
      this.filterService.dispatch({
        type: "ADD_ENTITY_FILTER",
        value: [entity],
        display: this.fieldNameDisplay,
        filterGroup: this.filterGroup,
        filterName: this.field,
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onClose() {
    this.modal.close();
  }
}
