import { Component, inject, OnDestroy } from "@angular/core";
import { AbstractFilterModal } from "./abstract-filter-modal";
import { ModalHeaderComponent } from "../modal-header/modal-header.component";
import { ModalBodyComponent } from "../modal-body/modal-body.component";
import { ModalFooterComponent } from "../modal-footer/modal-footer.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import {
  FilterService,
  FlagFilter,
} from "src/app/shared/services/filter.service";
import { ActiveFilterListComponent } from "../../table/filters/active-filter-list/active-filter-list.component";
import { SelectorComponent } from "../../selector/selector.component";
import { BoolDisplayPipe } from "src/app/shared/pipes/boolDisplay.pipe";
import { LabeledPropertyComponent } from "../../labeled-property/labeled-property.component";

type FlagFilterModalInit = {
};

@Component({
  standalone: true,
  imports: [
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    LabeledPropertyComponent,
    ActiveFilterListComponent,
    SelectorComponent,
  ],
  selector: "app-flag-filter-modal",
  styles: `
  `,
  template: `
    <div app-modal-header>{{ fieldNameDisplay }}</div>
    <div app-modal-body>
      <p>Select a filter value for the column {{ fieldNameDisplay }} below</p>
      <div app-labeled-property [label]="fieldNameDisplay">
        <div
          app-selector
          [selected]="selected"
          [options]="options"
          [slim]="true"
          (selectedChange)="onSelect($event)"
        ></div>
      </div>
      <app-active-filter-list
        [filterGroup]="filterGroup"
        [filterName]="field"
      ></app-active-filter-list>
    </div>
    <div app-modal-footer>
      <button class="action-btn" (click)="onClose()">Close</button>
    </div>
  `,
})
export class FlagFilterModalComponent
  extends AbstractFilterModal<FlagFilterModalInit>
  implements OnDestroy
{
  private modal = inject(NgbActiveModal);
  private filterService = inject(FilterService);

  private subscription = new Subscription();
  public options = [
    { value: undefined, display: "Either" },
    { value: true, display: new BoolDisplayPipe().transform(true) },
    { value: false, display: new BoolDisplayPipe().transform(false) },
  ];
  public selected = this.options[0];
  public filterGroup: string;
  public field: string;
  public modalInit: FlagFilterModalInit;
  public fieldNameDisplay: string = "";

  public override init(
    modalInit: FlagFilterModalInit,
    filterGroup: string,
    field: string,
    fieldNameDisplay: string,
  ): void {
    this.modalInit = modalInit;
    this.filterGroup = filterGroup;
    this.field = field;
    this.fieldNameDisplay = fieldNameDisplay;

    this.subscription.add(
      this.filterService.state(this.filterGroup).subscribe((s) => {
        const filter = s[this.field] as FlagFilter | undefined;
        this.selected = this.options.find(
          (o) =>
            `${o.value}` === `${filter?.value}` || o.value === filter?.value
        ) as any;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onSelect(event: { value: boolean | undefined; display: string }) {
    this.selected = event;
    this.filterService.dispatch({
      type: "SET_FLAG_FILTER",
      value: event.value,
      filterGroup: this.filterGroup,
      filterName: this.field,
      display: this.fieldNameDisplay,
    });
  }

  onClose() {
    this.modal.close();
  }
}
