import { ChangeDetectorRef, Component, OnDestroy, inject } from "@angular/core";
import { ModalHeaderComponent } from "../modal-header/modal-header.component";
import { ModalFooterComponent } from "../modal-footer/modal-footer.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalBodyComponent } from "../modal-body/modal-body.component";
import { LabeledPropertyComponent } from "../../labeled-property/labeled-property.component";
import { IntervalUsageService } from "../../interval-usage/interval-usage.service";
import { Subscription, firstValueFrom, map } from "rxjs";
import { DatePipe } from "@angular/common";
import * as moment from "moment";
import { ReferenceDataService } from "../../reference-data/reference-data.service";
import { SpinnerComponent } from "../../spinner/spinner.component";
import { FilterService } from "src/app/shared/services/filter.service";


@Component({
  standalone: true,
  imports: [
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    LabeledPropertyComponent,
    DatePipe,
    SpinnerComponent
],
  selector: "app-interval-usage-export-modal",
  styles: `
    div.loading {
      margin-top: 3rem;
    }

    .spinner-anchor {
      position: absolute;
      left: 50%;
      top: -3rem;
    }

    .warn {
      color: goldenrod;
    }

    .error {
      color: red
    }
  `,
  template: `
    <div app-modal-header>Export</div>
    <div app-modal-body [class.loading]=loading>
      @if(loading) {
        <span class="spinner-anchor">
          &nbsp;
          <app-spinner></app-spinner>
        </span>
      } @else {
        <div app-labeled-property label="Start Interval">
          @if(market_interval_start) {
          {{market_interval_start | date:"YYYY-MM-dd HH:00"}}
          } @else {
            <span class="error">NOT PROVIDED!</span>
          } 
        </div>
        <div app-labeled-property label="End Interval">
          @if(market_interval_end) {
            {{market_interval_end | date:"YYYY-MM-dd HH:00"}}
          } @else {
            <span class="error">NOT PROVIDED!</span>
          } 
        </div>      
        <div app-labeled-property label="Service Points">
          @if(service_point_id.length) {
            {{service_point_id.join(', ')}}
          } @else {
            <span class="error">NOT PROVIDED!</span>
          }
        </div>
      }
      <span class="warn">If you are exporting a large data set, it could take some time. Please be patient and do not refresh the page until the data has been downloaded</span>
    </div>
    <div app-modal-footer>
      <button class="action-btn" (click)="onCancel()">Cancel</button>
      <button class="action-btn primary" (click)="onDownload()" [disabled]="!canExport || loading">Download</button>
    </div>
  `,
})
export class IntervalUsageExportModalComponent implements OnDestroy {
  activeModal = inject(NgbActiveModal);
  referenceDataService = inject(ReferenceDataService);
  intervalUsageService = inject(IntervalUsageService);
  filterService = inject(FilterService);
  changeDetectorRef = inject(ChangeDetectorRef);

  service_point_id: string[] = [];
  market_interval_start: Date;
  market_interval_end: Date;
  canExport: boolean = false;
  loading: boolean = false;

  subscription = new Subscription();

  async init(filterGroup: string) {
    this.subscription.add(this.filterService.asActiveDisplay(filterGroup).subscribe(activeFilters => {
      this.service_point_id = activeFilters.filter(af => af.headerName === 'Service Point').map(af => af.value);
      const [mis, mie] = activeFilters.filter(af => af.headerName === 'Market Interval')[0]?.value.split(' & ');
      this.market_interval_start = moment(mis).toDate();
      this.market_interval_end = moment(mie).toDate();
      this.canExport = !!this.service_point_id.length && !!mis && !!mie
      this.changeDetectorRef.detectChanges();
    }))
  }

  async onDownload() {
    try {
      this.loading = true
      const service_point_refs = await firstValueFrom(this.referenceDataService.servicePoints(
        undefined,
        [{name: 'service_point_id', values: this.service_point_id.map(value => ({value: value.slice(0,1)}))}]
      ))
      await firstValueFrom(this.intervalUsageService.downloadExports(
        service_point_refs
        .filter(ref => this.service_point_id.some(id => id === ref.service_point_id))
        .map(ref => ref.service_point_uid), this.market_interval_start, this.market_interval_end
      ));
    } catch(e) {}
    this.loading = false;
  }

  onCancel() {
    this.activeModal.dismiss();
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }
}
