import { ColDef } from "ag-grid-community";
import { DateCellComponent } from "../cells/date-cell.component";
import { ReferenceDataService } from "../../reference-data/reference-data.service";
import { NON_TERMINATING_NAMED_DATE } from "src/app/shared/models/namedDate";
import { TableHeaderComponent } from "../headers/table-header.component";
import { FlagFilterModalComponent } from "../../modals/filters/flag-filter-modal.component";
import { EntityFilterModalComponent } from "../../modals/filters/entity-filter-modal.component";
import { DateFilterModalComponent } from "../../modals/filters/date-filter-modal.component";
import { ServicePointFilterModalComponent } from "../../modals/filters/service-point-filter-modal.component";
import { FlagCellComponent } from "../cells/flag-cell.component";

export const filterGroup = "service_point_attributes";

export class ServicePointAttributeColumnDefinition {
  constructor(private refDataService: ReferenceDataService) {}

  public sharedDef: ColDef = {
    filter: true,
    sortable: true,
    editable: false,
    minWidth: 100,
    width: 150,
  };

  getColDefs = (): ColDef[] => [
    {
      ...this.sharedDef,
      width: 165,
      headerName: "Service Point",
      field: "service_point_id",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: ServicePointFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {
          allowPartial: true,
        },
      },
    },
    {
      ...this.sharedDef,
      headerName: "Effective Date",
      field: "effective_date",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        format: "YYYY/MM/DD",
      },
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: DateFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {},
      },
    },
    {
      ...this.sharedDef,
      headerName: "Termination Date",
      field: "termination_date",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        format: "YYYY/MM/DD",
      },
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: DateFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {
          namedDates: [NON_TERMINATING_NAMED_DATE],
        },
      },
    },
    {
      ...this.sharedDef,
      headerName: "LDC",
      field: "ldc_code",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: EntityFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {
          resource: this.refDataService.lossDistributionCompanies.name,
        },
      },
    },
    {
      ...this.sharedDef,
      headerName: "ELDC",
      field: "eldc_code",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: EntityFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {
          resource: this.refDataService.lossDistributionCompanies.name,
        },
      },
    },
    {
      ...this.sharedDef,
      headerName: "Profile Class",
      field: "profile_class_code",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: EntityFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {
          resource: this.refDataService.profileClasses.name,
        },
      },
    },
    {
      ...this.sharedDef,
      headerName: "Retailer",
      field: "retailer_code",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: EntityFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {
          resource: this.refDataService.retailers.name,
        },
      },
    },
    {
      ...this.sharedDef,
      headerName: "Supply Class",
      field: "supply_class_code",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: EntityFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {
          resource: this.refDataService.supplyClasses.name,
        },
      },
    },
    {
      ...this.sharedDef,
      headerName: "Meter Type",
      field: "meter_type_code",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: EntityFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {
          resource: this.refDataService.meterTypes.name,
        },
      },
    },
    {
      ...this.sharedDef,
      headerName: "Loss Class",
      field: "loss_class_code",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: EntityFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {
          resource: this.refDataService.lossClasses.name,
        },
      },
    },
    {
      ...this.sharedDef,
      headerName: "Location",
      field: "location_code",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: EntityFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {
          resource: this.refDataService.locations.name,
        },
      },
    },
    {
      ...this.sharedDef,
      headerName: "CP Node",
      field: "cpnode_code",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: EntityFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {
          resource: this.refDataService.cpNodes.name,
        },
      },
    },
    {
      ...this.sharedDef,
      headerName: "Weather Sensitive",
      field: "weather_sensitive_flag",
      cellRenderer: FlagCellComponent,
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: FlagFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {},
      },
    },
    {
      ...this.sharedDef,
      headerName: "BMG",
      field: "bmg_flag",
      cellRenderer: FlagCellComponent,
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: FlagFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {},
      },
    },
    {
      ...this.sharedDef,
      headerName: "Tie",
      field: "tie_flag",
      cellRenderer: FlagCellComponent,
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: FlagFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {},
      },
    },
    {
      ...this.sharedDef,
      headerName: "Net Meter Rider",
      field: "net_meter_rider_flag",
      cellRenderer: FlagCellComponent,
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: FlagFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {},
      },
    },
    {
      ...this.sharedDef,
      headerName: "Usage Ingestion Block",
      field: "usage_ingestion_block_flag",
      cellRenderer: FlagCellComponent,
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: FlagFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {},
      },
    },
    {
      ...this.sharedDef,
      headerName: "SPA Override",
      field: "spa_override_flag",
      cellRenderer: FlagCellComponent,
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: FlagFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {},
      },
    },
  ];
}
