import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable, catchError, from, map, switchMap, tap } from "rxjs";
import { ToastService } from "src/app/public/toasts/toasts.service";

@Injectable({ providedIn: 'root'})
export class ExpectedResultsService {
   http = inject(HttpClient);
   toast = inject(ToastService);

    upload(file_type: string, file: File): Observable<any> {
        const formData = new FormData();
        formData.append('newfile', file)
        return this.http.post<string>(`expected_results/${file_type}`, formData, {
                responseType: 'json',
                reportProgress: true,
            }).pipe(
                catchError((err) => {
                    this.toast.show({
                        type: 'error',
                        message: 'Something went wrong! Please try again.'
                    });
                    throw err;
                })
            )
        
    }

    generationLoad(file: File): Observable<string> {
        return this.upload('generation_load', file);
    }

    rpgiLoad(file: File): Observable<string> {
        return this.upload('rpgi', file);
    }

    systemLoad(file: File): Observable<string> {
        return this.upload('system_load', file);
    }
}