import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  inject,
} from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { SelectMenuListComponent } from "../table/filters/select-menu-list/select-menu-list.component";
import { SelectMenuListItemComponent } from "../table/filters/select-menu-list-item/select-menu-list-item.component";

@Component({
  standalone: true,
  imports: [MatIcon, SelectMenuListComponent, SelectMenuListItemComponent],
  selector: "div[app-selector]",
  template: `
    <div class="wrapper" [class.slim]="slim">
      <mat-icon class="select-icon" svgIcon="expand-more"></mat-icon>
      <input
        type="button"
        [placeholder]="'none'"
        [value]="selected?.display"
        (click)="toggle()"
      />
      <div class="popover" [class.open]="open">
        <ul app-select-menu-list>
          <!-- SHOW SEARCH RESULTS -->
          @for (o of options; track o.value;) {
          <li
            app-select-menu-list-item
            [value]="o"
            [display]="o.display"
            [selected]="selected === o"
            [multi]="false"
            (select)="setValue($event)"
          ></li>
          }
        </ul>
      </div>
    </div>
  `,
  styles: `
    .wrapper {
      position: relative;
      width: 100%;
      padding: 0.5rem 0.75rem;
      border: 1px solid black;

      &.slim {
        padding: 0.5rem 0rem 0rem;
        border: none; 
      }
    }

    input {
      width: 100%;
      padding: 0.5rem 1.75rem 0.25rem 0.25rem;
      border: none;
      background: white;
      text-align: left;
      border-bottom: 1px solid black;
      font-size: 0.9rem;
      line-height: 1.25rem;

      &:focus {
        outline: none;
      }
    }
  
    .popover {
      display: none;
      position: absolute;
    }

    .popover.open {
      z-index: 3;
      display: flex;
      flex-direction: column;
      min-height: 7rem;
      width: 300px;
      right: 0;
      top: calc(100% + 0.5rem);
      border: 1px solid black;
      border-radius: 0;
      padding: 0.5rem 1rem;
      background: white;


      &::before {
        content: ' ';
        border: none;
        border-top: 1px solid black;
        border-right: 1px solid black;
        width: 0.5rem;
        height: 0.5rem;
        transform: rotate(-45deg);
        position: absolute;
        top: calc(-0.25rem - 1px);
        background: linear-gradient(
          45deg, 
          rgba(255,255,255,0) 0%, 
          rgba(255,255,255,0) calc(50% - 1px), 
          rgba(255,255,255,1) calc(50% - 1px), 
          rgba(255,255,255,1) 100%
          );
        right: 1rem;
      }
    }

    .select-icon {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
  `,
})
export class SelectorComponent<T> {
  elementRef = inject(ElementRef);

  @Input() options: { value: T; display: string }[];
  @Input() selected?: (typeof this.options)[number];
  @Input() label: string = "";
  @Input() slim = false;

  @Output() selectedChange = new EventEmitter<
    Exclude<typeof this.selected, undefined>
  >();

  open = false;

  constructor() {
    console.log(this)
  }

  @HostListener("document:click", ["$event"])
  handleOutsideClick(event: Event) {
    if (
      this.open &&
      !this.elementRef.nativeElement.contains(event.target as Node)
    ) {
      this.toggle();
    }
  }

  toggle(): void {
    this.open = !this.open;
  }

  setValue(event: Exclude<typeof this.selected, undefined>) {
    this.selected = event;
    this.selectedChange.emit(event);
    this.open = false;
  }
}
