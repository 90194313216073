import { Component, inject } from "@angular/core";
import { map, Observable } from "rxjs";
import { PageHeaderComponent } from "../page-header/page-header.component";
import { QuickDatePickerComponent } from "../quick-date-picker/quick-date-picker.component";
import { PageContentComponent } from "../page-content/page-content.component";
import { ServicePointAttributesTableComponent } from "../table/service-point-attributes-table.component";
import { AsyncPipe } from "@angular/common";
import * as moment from "moment";
import { DateFilter, FilterService } from "src/app/shared/services/filter.service";
import { filterGroup } from "../table/definitions/service-point-attribute-column-definition";

@Component({
  standalone: true,
  selector: "app-service-points",
  imports: [
    PageHeaderComponent,
    QuickDatePickerComponent,
    PageContentComponent,
    ServicePointAttributesTableComponent,
    AsyncPipe,
  ],
  template: `
    <app-page-header title="Service Point Attributes">
      <app-quick-datepicker
        label="Operating Date"
        [value]="operatingDate$ | async"
        (valueChange)="onQuickDateSelect($event)"
      />
    </app-page-header>
    <app-page-content>
      <app-service-point-attributes-table />
    </app-page-content>
  `,
  styles: ``,
})
export class ServicePointsComponent {
  filterService = inject(FilterService);
  operatingDate$: Observable<string | Date | null | undefined> = this.filterService.state(filterGroup)
    .pipe(
      map(state => {
        const effectiveDate = state['effective_date'] as DateFilter | undefined;
        const terminationDate = state['termination_date'] as DateFilter | undefined;
        if(
          effectiveDate && effectiveDate.value && effectiveDate.operation === '<=' &&
          terminationDate && terminationDate.value && terminationDate.operation === '>' &&
          moment(effectiveDate.value).isSame(terminationDate.value, 'day')
        ) {
          return effectiveDate.value
        }
        else {
          return undefined
        }
      })
    );


  onQuickDateSelect(val: Date) {
    this.filterService.dispatch({
      'type': 'SET_DATE_FILTER',
      filterName: 'effective_date',
      display: 'Effective Date',
      operation: '<=',
      value: val,
      filterGroup,
    });
    this.filterService.dispatch({
      'type': 'SET_DATE_FILTER',
      filterName: 'termination_date',
      display: 'Termination Date',
      operation: '>',
      value: val,
      filterGroup,
    });
  }
}
