import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { orderBy } from "lodash";
import {
  map,
  merge,
  Observable,
  shareReplay,
  Subscription,
} from "rxjs";
import { APIEnvelope } from "src/app/shared/models/api";
import {
  EntityRef,
  ServicePointIdReference,
} from "src/app/shared/models/referenceData";
import { fetchUntilExhausted } from "src/app/shared/utilities/fetch-until-exhausted";
import {
  FilterParams,
  httpParams,
  pagination,
  filter,
  PaginationParams,
  withHttpParams,
  sort,
  SortParams,
} from "src/app/shared/utilities/http-params";

class CachedEntityRef {
  private _subscription = new Subscription();
  private _data$: Observable<EntityRef[]>;

  constructor(
    url: string,
    storage: Storage,
    http: HttpClient,
    pageSize = 1000
  ) {
    const fromStorage$ = new Observable<EntityRef[]>((subscriber) => {
      const storageValue = storage.getItem(url);
      if (storageValue) {
        subscriber.next(<EntityRef[]>JSON.parse(storageValue));
      }
    });
    const fromServer$ = new Observable<EntityRef[]>((subscriber) => {
      fetchUntilExhausted<EntityRef>(http, `reference/${url}`, pageSize, 0).then((res) => {
        storage.setItem(url, JSON.stringify(res.results));
        subscriber.next(res.results);
      });
    });
    this._data$ = merge(fromStorage$, fromServer$).pipe(shareReplay(1));
    this._subscription.add(this._data$.subscribe());
  }

  get(filters: FilterParams[], order: SortParams[]) {
    return this._data$.pipe(
      map((data) =>
        data.filter((d) =>
          filters
            .map((fp) => fp.values)
            .flat()
            .some(
              (fpv) =>
                d.entity_code.toLowerCase().includes(fpv.value.toLowerCase()) ||
                d.entity_name.toLowerCase().includes(fpv.value.toLowerCase())
            )
        )
      ),
      map((data) =>
        orderBy(
          data,
          order.map((o) => o.name),
          order.map((o) => o.direction ?? "asc")
        )
      )
    );
  }

  destroy() {
    this._subscription.unsubscribe();
  }
}

@Injectable({
  providedIn: "root",
})
export class ReferenceDataService {
  constructor(private http: HttpClient) {}

  private get<T>(
    path: string,
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ) {
    const params = httpParams(
      withHttpParams(pagination, page),
      withHttpParams(filter, filters),
      withHttpParams(sort, order)
    );
    return this.http
      .get<APIEnvelope<T>>(`reference/${path}`, {
        headers: {
          NOLOADING: "true",
        },
        params,
      })
      .pipe(map((res) => res.results));
  }

  private static readonly defaultPage: PaginationParams = {
    pageNumber: 0,
    pageSize: 1000,
  };

  private static readonly defaultEntitySort: SortParams[] = [
    { name: "entity_name", direction: "asc" },
  ];

  private static readonly defaultServiceIdSort: SortParams[] = [
    { name: "service_point_id", direction: "asc" },
  ];

  private _settlmentTypes = new CachedEntityRef(
    "settlement_type",
    sessionStorage,
    this.http
  );
  settlementTypes(filters?: FilterParams[], order?: SortParams[]) {
    return this._settlmentTypes.get(
      filters ?? [{ name: "entity_code", values: [{ value: "" }] }],
      order ?? ReferenceDataService.defaultEntitySort
    );
  }

  private _locations = new CachedEntityRef(
    "location",
    sessionStorage,
    this.http
  );
  locations(filters?: FilterParams[], order?: SortParams[]) {
    return this._locations.get(
      filters ?? [{ name: "entity_code", values: [{ value: "" }] }],
      order ?? ReferenceDataService.defaultEntitySort
    );
  }

  private _profileClasses = new CachedEntityRef(
    "profile_class",
    sessionStorage,
    this.http
  );
  profileClasses(filters?: FilterParams[], order?: SortParams[]) {
    return this._profileClasses.get(
      filters ?? [{ name: "entity_code", values: [{ value: "" }] }],
      order ?? ReferenceDataService.defaultEntitySort
    );
  }

  private _cpNodes = new CachedEntityRef("cp_node", sessionStorage, this.http);
  cpNodes(filters?: FilterParams[], order?: SortParams[]) {
    return this._cpNodes.get(
      filters ?? [{ name: "entity_code", values: [{ value: "" }] }],
      order ?? ReferenceDataService.defaultEntitySort
    );
  }

  private _servicePointSources = new CachedEntityRef(
    "service_point_source",
    sessionStorage,
    this.http
  );
  servicePointSources(filters?: FilterParams[], order?: SortParams[]) {
    return this._servicePointSources.get(
      filters ?? [{ name: "entity_code", values: [{ value: "" }] }],
      order ?? ReferenceDataService.defaultEntitySort
    );
  }

  private _runStatuses = new CachedEntityRef(
    "run_status",
    sessionStorage,
    this.http
  );
  runStatuses(filters?: FilterParams[], order?: SortParams[]) {
    return this._runStatuses.get(
      filters ?? [{ name: "entity_code", values: [{ value: "" }] }],
      order ?? ReferenceDataService.defaultEntitySort
    );
  }

  private _deliveryClasses = new CachedEntityRef(
    "delivery_class",
    sessionStorage,
    this.http
  );
  deliveryClasses(filters?: FilterParams[], order?: SortParams[]) {
    return this._deliveryClasses.get(
      filters ?? [{ name: "entity_code", values: [{ value: "" }] }],
      order ?? ReferenceDataService.defaultEntitySort
    );
  }

  private _lossClasses = new CachedEntityRef(
    "loss_class",
    sessionStorage,
    this.http
  );
  lossClasses(filters?: FilterParams[], order?: SortParams[]) {
    return this._lossClasses.get(
      filters ?? [{ name: "entity_code", values: [{ value: "" }] }],
      order ?? ReferenceDataService.defaultEntitySort
    );
  }

  private _meterTypes = new CachedEntityRef(
    "meter_type",
    sessionStorage,
    this.http
  );
  meterTypes(filters?: FilterParams[], order?: SortParams[]) {
    return this._meterTypes.get(
      filters ?? [{ name: "entity_code", values: [{ value: "" }] }],
      order ?? ReferenceDataService.defaultEntitySort
    );
  }

  private _retailers = new CachedEntityRef(
    "retailer",
    sessionStorage,
    this.http
  );
  retailers(filters?: FilterParams[], order?: SortParams[]) {
    return this._retailers.get(
      filters ?? [{ name: "entity_code", values: [{ value: "" }] }],
      order ?? ReferenceDataService.defaultEntitySort
    );
  }

  private _supplyClasses = new CachedEntityRef(
    "supply_class",
    sessionStorage,
    this.http
  );
  supplyClasses(filters?: FilterParams[], order?: SortParams[]) {
    return this._supplyClasses.get(
      filters ?? [{ name: "entity_code", values: [{ value: "" }] }],
      order ?? ReferenceDataService.defaultEntitySort
    );
  }

  private _lossDistributionCompanies = new CachedEntityRef(
    "local_distribution_company",
    sessionStorage,
    this.http
  );
  lossDistributionCompanies(filters?: FilterParams[], order?: SortParams[]) {
    return this._lossDistributionCompanies.get(
      filters ?? [{ name: "entity_code", values: [{ value: "" }] }],
      order ?? ReferenceDataService.defaultEntitySort
    );
  }

  servicePoints(
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ) {
    const params = httpParams(
      withHttpParams(pagination, page ?? ReferenceDataService.defaultPage),
      withHttpParams(filter, filters),
      withHttpParams(sort, order ?? ReferenceDataService.defaultServiceIdSort)
    );
    return this.http
      .get<APIEnvelope<ServicePointIdReference>>("service_points/", {
        params,
        headers: { NOLOADING: "true" },
      })
      .pipe(map((res) => res.results));
  }
}
