import { DestroyRef, Injectable, inject } from "@angular/core";
import { ReplaySubject, Subscription, scan } from "rxjs";


/** This is not used by most cell components */
@Injectable({
    providedIn: 'any'
})
export class TableEditableDataService {
    private destroyRef = inject(DestroyRef);

    private subscription = new Subscription();
    private event$ = new ReplaySubject<{key: string, value: any}>(1);
    private data$ = this.event$.asObservable().pipe(
        scan((acc, evt) => ({ ...acc, [evt.key]: evt.value }), {} as Record<string, any>),
    );
    private data: Record<string, any>;

    constructor() {
        this.subscription.add(this.data$.subscribe((data) => {
            console.log(data);
            this.data = data
        }));

        this.destroyRef.onDestroy(() => {
            this.subscription.unsubscribe();
        })
    }

    update(key: string, value: any) {
        this.event$.next({key, value});
    }

    getData() {
        return this.data;
    }
}