import { Component, inject, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SpaOverrideService } from "./spah-override.service";
import { ToastService } from "../toasts/toasts.service";
import { ServicePointOverrideCreateModal } from "../modals/service point-override-create-modal/service-point-override-create-modal.component";
import { SpahOverridesTableComponent } from "../table/spah-overrides-table.component";

@Component({
  selector: "app-spah-overrides",
  template: `
    <app-page-header title="Service Point Overrides">
      <button class="action-btn" (click)="createNew()" [disabled]="loading">
        Create <mat-icon svgIcon="add_circle" />
      </button>
    </app-page-header>
    <app-page-content>
      <app-spah-overrides-table
        (loading)="onDataLoading($event)"
      />
    </app-page-content>
  `,
  styles: `
  .confirm-btn{
    padding: 0.5rem 1rem;
    border: none;
    background: white;
    text-align: center;
    border: 1px solid black;
    border-radius: 0.25rem;
    font-size: 0.9rem;
    line-height: 1.25rem;

    &:focus {
      outline: none;
    }

    &.error {
      border-color: var(--error-red);
      color: var(--error-red)
    }
  }
  `,
})
export class SpahOverridesComponent {
  modalService = inject(NgbModal);
  spaOverrideService = inject(SpaOverrideService);
  toastService = inject(ToastService);

  @ViewChild(SpahOverridesTableComponent)
  private table: SpahOverridesTableComponent;

  public loading = false;

  createNew() {
    const ref = this.modalService.open(ServicePointOverrideCreateModal, {
      windowClass: "confirmation-modal",
      centered: true,
    })
    ref.componentInstance.init("create", {});

    ref.result
    .then(() => {
      console.debug("Modal closed");
      this.table.refreshTrigger$.next(void 0);
    })
    .catch(() => {
      console.debug("Modal dismissed");
    });
  }

  onDataLoading(isLoading: boolean) {
    this.loading = isLoading;
  }
}
