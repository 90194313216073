import { Component, Input, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import {
  ColDef,
  GetRowIdFunc,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { AgGridAngular } from "ag-grid-angular";
import { TableLoadingOverlayComponent } from "./overlays/table-loading-overlay.component";
import { ServicePoint } from "src/app/shared/models/servicePoint";
import { TableNoDataOverlayComponent } from "./overlays/table-no-data-overlay.component";
import { TableSortService } from "./services/table-sort.service";
import { ServicePointAttributeConfirmationColumnDefinition } from "./definitions/service-point-attribute-confirmation-column-definition";

@Component({
  standalone: true,
  selector: "app-service-point-attributes-confirmation-table",
  imports: [AgGridAngular],
  providers: [TableSortService],
  styles: `
      :host {
          display: flex;
          flex-direction: column;
          justify-content: start;
      }
    `,
  template: `
    <ag-grid-angular
      class="ag-theme-quartz app-table"
      style="height: 70vh"
      [getRowId]="getRowId"
      [columnDefs]="columnDefs"
      [rowData]="[]"
      [suppressPaginationPanel]="true"
      [suppressDragLeaveHidesColumns]="true"
      [loadingOverlayComponent]="loadingOverlayComponent"
      [noRowsOverlayComponent]="noRowsOverlayComponent"
      [noRowsOverlayComponentParams]="noRowsOverlayParams"
      [pagination]="false"
      [enableCellTextSelection]="true"
      (gridReady)="onGridReady($event)"
    />
  `,
})
export class ServicePointAttributesConfirmationTableComponent
  implements OnDestroy
{
  private subscription = new Subscription();
  private loading$ = new BehaviorSubject(false);
  private _data: ServicePoint[] = [];
  private data$ = new BehaviorSubject<ServicePoint[]>([]);

  @Input() set loading(val: boolean) {
    this.loading$.next(val);
  }

  @Input() set data(val: ServicePoint[]) {
    this.data$.next(val);
  }

  public gridApi: GridApi;

  getRowId: GetRowIdFunc<ServicePoint> = (data) =>
    data.data.service_point_uid + data.data.effective_date;

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.init();
  }

  private init() {
    this.subscription.add(
      this.data$.subscribe((data) => {
        const nodeData: unknown[] = [];
        this._data = data;
        this.gridApi?.forEachNode((node) => nodeData.push(node.data));
        this.gridApi?.applyTransaction({
          remove: nodeData,
          add: data,
        });
        this.gridApi.hideOverlay();
        if (!data.length) {
          this.gridApi?.forEachNode((node) => nodeData.push(node.data));
          this.gridApi?.applyTransaction({
            remove: nodeData,
          });
          this.noRowsOverlayParams = { error: false };
          this.gridApi.showNoRowsOverlay();
        }
      })
    );
    this.subscription.add(
      this.loading$.subscribe((l) => {
        if (l) {
          this.gridApi.showLoadingOverlay();
        } else if (this._data.length <= 0) {
          this.gridApi.showNoRowsOverlay();
        } else {
          this.gridApi.hideOverlay();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // columns
  public loadingOverlayComponent = TableLoadingOverlayComponent;
  public noRowsOverlayComponent = TableNoDataOverlayComponent;
  public noRowsOverlayParams = { error: false };

  servicePointAttributeConfirmationColumnDefinition =
    new ServicePointAttributeConfirmationColumnDefinition();
  public columnDefs: ColDef[] =
    this.servicePointAttributeConfirmationColumnDefinition.getColDefs();
}
