import { ChangeDetectorRef, Component, inject } from "@angular/core";
import { ModalHeaderComponent } from "../modal-header/modal-header.component";
import { ModalFooterComponent } from "../modal-footer/modal-footer.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalBodyComponent } from "../modal-body/modal-body.component";
import { LabeledPropertyComponent } from "../../labeled-property/labeled-property.component";
import {
  ReplaySubject,
  Subscription,
  combineLatest,
  firstValueFrom,
  map,
  switchMap,
  tap,
} from "rxjs";
import { ServicePointIdReference } from "src/app/shared/models/referenceData";
import { IntervalUsageService } from "../../interval-usage/interval-usage.service";
import { ReferenceDataService } from "../../reference-data/reference-data.service";
import { IntervalUsage } from "src/app/shared/models/intervalUsage";
import * as moment from "moment-timezone";
import { ServicePointSelectorComponent } from "../../service-point-selector/service-point-selector.component";
import { DatePickerButtonComponent } from "../../date-picker-button/date-picker-button.component";
import { UpdateIntervalUsageTableComponent } from "../../table/update-interval-usage-table.component";
import { AsyncPipe } from "@angular/common";
import { TableEditableDataService } from "../../table/services/table-editable-data-service";
import { ToastService } from "../../toasts/toasts.service";

@Component({
  standalone: true,
  imports: [
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    LabeledPropertyComponent,
    ServicePointSelectorComponent,
    DatePickerButtonComponent,
    UpdateIntervalUsageTableComponent,
    UpdateIntervalUsageTableComponent,
    AsyncPipe
],
  selector: "app-update-interval-usage-modal",
  styles: ``,
  template: `
    <div app-modal-header>Update Usage</div>
    <div app-modal-body>
      <div app-labeled-property label="Service Point">
        <div
          app-service-point-selector
          [slim]="true"
          [value]="servicePointIdRef"
          (valueChange)="servicePointIdRef$.next($event)"
        ></div>
      </div>
      <div app-labeled-property label="Market Interval">
        <app-date-picker-button
          [slim]="true"
          [value]="marketInterval"
          (valueChange)="marketInterval$.next($event)"
        >
        </app-date-picker-button>
      </div>
      <app-update-interval-usage-table
        [data]="data$ | async"
        [loading]="loading"
      ></app-update-interval-usage-table>
    </div>
    <div app-modal-footer>
      <button class="action-btn" (click)="onCancel()">Cancel</button>
      <button class="action-btn primary" (click)="onUpdate()">
        Update
      </button>
    </div>
  `,
})
export class UpdateIntervalUsageModalComponent {
  activeModal = inject(NgbActiveModal);
  toastService = inject(ToastService);
  changeDetectorRef = inject(ChangeDetectorRef);
  referenceDataService = inject(ReferenceDataService);
  intervalUsageService = inject(IntervalUsageService);
  editableDataService = inject(TableEditableDataService);

  subscription = new Subscription();
  marketInterval$ = new ReplaySubject<Date>(1);
  marketInterval: Date;
  servicePointIdRef$ = new ReplaySubject<ServicePointIdReference>(1);
  servicePointIdRef: ServicePointIdReference;
  loading = false;
  data$ = combineLatest([this.servicePointIdRef$, this.marketInterval$]).pipe(
    tap(() => {
      this.loading = true;
    }),
    switchMap(([servicePointRef, marketInterval]) =>
      this.intervalUsageService.getIntervalUsage(
        {pageSize: 24, pageNumber: 0},
        [
          {name: 'service_point_uid', values: [{value: servicePointRef.service_point_uid}]},
          {name: 'market_interval', values: [{
            operation: 'between=',
            value: [
              moment(marketInterval).startOf('D').set('h',0).toISOString(true),
              moment(marketInterval).startOf('D').set('h',23).toISOString(true)
            ].join(',')
          }]},
        ],
        [{name: 'market_interval'}]
      )
    ),
    map(res => res.results.sort((a, b) => {
      const aMoment = moment(a.market_interval);
      const bMoment = moment(a.market_interval);
      return aMoment.diff(bMoment, 'h');
    })),
    tap(() => {
      this.loading = false;
    })
  );

  init(data?: IntervalUsage) {
    if (data) {
      this.marketInterval$.next(moment(data.market_interval).toDate());
      this.servicePointIdRef$.next(data);
    }
    this.subscription.add(
      this.marketInterval$.subscribe((marketInterval) => {
        this.marketInterval = marketInterval;
      })
    );
    this.subscription.add(
      this.servicePointIdRef$.subscribe((servicePointIdRef) => {
        this.servicePointIdRef = servicePointIdRef;
      })
    );
    this.changeDetectorRef.detectChanges();
  }

  onCancel() {
    this.subscription.unsubscribe();
    this.activeModal.dismiss();
  }

  async onUpdate() {
    const updates = Object.entries(this.editableDataService.getData()).map(([k,v]) => {
      const [service_point_uid, market_interval] = k.split('|')
      const measurement_value = v;
      return {
        service_point_uid,
        market_interval,
        measurement_value
      }
    })
    this.loading = true
    try {
      await firstValueFrom(this.intervalUsageService.createIntervalUsage(updates));
      this.activeModal.dismiss();
    } catch (e) {
      this.toastService.show({type: 'error', message: 'Something went wrong! Please review your data and try again'})
    }
    this.loading = false;
  }

  onClear() {
    console.log("Clear Triggered!");
  }
}
