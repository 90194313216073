import { ColDef } from "ag-grid-community";
import { DateCellComponent } from "../cells/date-cell.component";
import { RLSCertMetadata } from "src/app/shared/models/rls-cert-metadata";
import {
  ActionCellComponent,
  ActionCellProps,
} from "../cells/action-cell.component";
import { TableHeaderComponent } from "../headers/table-header.component";
import { DateFilterModalComponent } from "../../modals/filters/date-filter-modal.component";
import { StringFilterModalComponent } from "../../modals/filters/string-filter-modal.component";

export const filterGroup = "rls_certs";

export class RLSCertsColumnDefinition {
  constructor(
    private actions: ActionCellProps<RLSCertMetadata>["actions"]
  ) {}

  public sharedDef: ColDef = {
    filter: true,
    sortable: true,
    editable: false,
    minWidth: 100,
    width: 200,
  };

  getColDefs = (): ColDef[] => [
    {
      ...this.sharedDef,
      width: 165,
      headerName: "Market Participant",
      field: "participant",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: StringFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {},
      },
    },
    {
      ...this.sharedDef,
      flex: 1,
      headerName: "Name",
      field: "participant_name",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: StringFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {},
      },
    },
    {
      ...this.sharedDef,
      headerName: "Start Date",
      field: "start_date",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        format: "YYYY/MM/DD HH:mm:ss",
      },
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: DateFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {},
      },
    },
    {
      ...this.sharedDef,
      headerName: "Expiration Date",
      field: "expiration_date",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        format: "YYYY/MM/DD HH:mm:ss",
      },
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup,
        modal: DateFilterModalComponent,
        modalOptions: { centered: true },
        modalInit: {},
      },
    },
    {
      ...this.sharedDef,
      width: 75,
      pinned: "right",
      resizable: false,
      suppressMovable: true,
      filter: false,
      sortable: false,
      cellRenderer: ActionCellComponent,
      cellRendererParams: {
        actions: this.actions,
      },
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup
      }
    },
  ];
}
