import { Component, inject } from "@angular/core";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { IHeaderParams } from "ag-grid-community";
import { TableHeaderSortButtonComponent } from "./table-header-sort-button/table-header-sort-button.component";
import { TableHeaderFilterButtonComponent } from "./table-header-filter-button/table-header-filter-button.component";
import { Subscription } from "rxjs";
import { AbstractFilterModal } from "../../modals/filters/abstract-filter-modal";

@Component({
  standalone: true,
  imports: [TableHeaderSortButtonComponent, TableHeaderFilterButtonComponent],
  selector: "app-table-header",
  styles: `
    :host {
      position: relative;
      display: grid;
      grid-template-columns: 1fr minmax(0, 1.5rem);
      gap: 0.25rem;
      justify-content: space-between;
      align-items: center;
      font-size: 1rem;
      font-weight: 700;
      width: 100%;
    }`,
  template: `
    <button
      app-table-header-sort-button
      [label]="headerName"
      [field]="field"
      [sortable]="sortable"
    ></button>

    @if(filterable) {
    <button
      app-table-header-filter-button
      [filterGroup]="filterGroup"
      [field]="field"
      (click)="openFilter()"
    ></button>
    }
  `,
})
export class TableHeaderComponent<DATA = any, MODALPROPS = any> {
  modalService = inject(NgbModal);

  subscription = new Subscription();
  headerName: string;
  filterGroup: string;
  field: string;
  modal?: AbstractFilterModal<MODALPROPS>;
  modalOptions?: NgbModalOptions;
  modalInit?: MODALPROPS;
  filterable: boolean;
  sortable: boolean;

  agInit(params: IHeaderParams<DATA>): void {
    this.refresh(params);
  }

  refresh(params: IHeaderParams<DATA>): boolean {
    this.headerName = params.column.getColDef().headerName ?? "";
    this.filterGroup = params.column.getColDef().headerComponentParams.filterGroup ?? "";
    this.field = params.column.getColDef().field ?? "";
    this.modal = params.column.getColDef().headerComponentParams.modal;
    this.modalOptions =
      params.column.getColDef().headerComponentParams.modalOptions;
    this.modalInit =
      params.column.getColDef().headerComponentParams.modalInit;
    this.sortable = params.column.isSortable();
    this.filterable = params.column.isFilterAllowed() && !!this.modal;
    return true;
  }

  openFilter() {
    if (this.filterable) {
      const ref = this.modalService.open(this.modal, this.modalOptions);
      ref.componentInstance.init(this.modalInit, this.filterGroup, this.field, this.headerName);
      ref.result
        .then(() => console.debug(`[${this.field}] Modal close`))
        .catch(() => console.debug(`[${this.field}] Modal dismiss`));
    } else {
      console.debug(
        `[${this.field}] Filter could not be opened because the header is not filterable`
      );
    }
  }
}
