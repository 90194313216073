import {
  Component,
  OnDestroy,
} from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

export type ParamsType<
  DATA extends Record<string, unknown>,
  PROPS extends Record<string, unknown>,
  VALUE
> = ICellRendererParams<DATA & { edit_mode?: boolean }, VALUE> &
  PROPS;

@Component({
  standalone: true,
  template: ``,
  styles: ``,
})
export abstract class BaseCellComponent<
  DATA extends Record<string, unknown>,
  PROPS extends Record<string, unknown>,
  FIELD extends keyof DATA,
  VALUE extends DATA[FIELD]
> implements ICellRendererAngularComp, OnDestroy
{
  params: ParamsType<DATA, PROPS, VALUE>;
  field: FIELD | undefined;
  headerName: string;

  agInit(params: ParamsType<DATA, PROPS, VALUE>): void {
    this.refresh(params);
    this.init();
  }

  init() {}
  onOpen() {}
  onClose() {}

  refresh(params: ParamsType<DATA, PROPS, VALUE>) {
    this.destroy();
    this.params = params;
    this.field = this.params.colDef?.field as FIELD | undefined;
    this.headerName = this.params.colDef?.headerName ?? ''
    return true;
  }

  ngOnDestroy(): void {
    this.destroy();
    this.onClose();
  }

  destroy(): void {}
}
