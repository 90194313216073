import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MaskedDatePipe } from "src/app/shared/pipes/maskedDate.pipe";
import { DatePickerComponent } from "../date-picker/date-picker.component";
import { NamedDate } from "src/app/shared/models/namedDate";
import { NamedDatesComponent } from "../named-dates/named-dates.component";
import { Subscription } from "rxjs";
import { clamp } from "lodash";
import * as moment from "moment";

@Component({
  standalone: true,
  selector: "app-date-picker-button",
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatIconModule,
    MaskedDatePipe,
    DatePickerComponent,
    NamedDatesComponent,
  ],
  styles: `
    .wrapper {
      position: relative;
      width: 100%;
      padding: 0.5rem 0.75rem;
      border: 1px solid black;

      &.slim{
        padding: 0.5rem 0rem 0rem;
        border: none; 
      }
    }

    input {
      width: 100%;
      padding: 0.5rem 1.75rem 0.25rem 0.25rem;
      border: none;
      background: white;
      text-align: left;
      border-bottom: 1px solid black;
      font-size: 0.9rem;
      line-height: 1.25rem;

      &:focus {
        outline: none;
      }
    }

    .calendar-icon {
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;
    }
  
    .date-popover {
      display: none;
      position: absolute;
    }

    .date-popover.open {
      z-index: 3;
      display: flex;
      flex-direction: column;
      min-height: 7rem;
      width: 300px;
      right: 0;
      top: calc(100% + 0.5rem);
      border: 1px solid black;
      border-radius: 0;
      padding: 0.5rem 1rem;
      background: white;


      &::before {
        content: ' ';
        border: none;
        border-top: 1px solid black;
        border-right: 1px solid black;
        width: 0.5rem;
        height: 0.5rem;
        transform: rotate(-45deg);
        position: absolute;
        top: calc(-0.25rem - 1px);
        background: linear-gradient(
          45deg, 
          rgba(255,255,255,0) 0%, 
          rgba(255,255,255,0) calc(50% - 1px), 
          rgba(255,255,255,1) calc(50% - 1px), 
          rgba(255,255,255,1) 100%
          );
        right: 1rem;
      }
    }

    label {
      position: absolute;
      top: 1rem;
      left: 1rem;
      opacity: 0.75;
      width: 0;
      white-space: nowrap;
    }

    input::placeholder {
      opacity: 0;
    }

    input:not(:placeholder-shown) + label {
      transform: scale(0.75) translateY(-1.25rem);
    }

    .time-picker {
			display: grid;
			grid-template-columns: 3rem 3rem 2rem;
			align-items: baseline;
			margin-bottom: 1rem;
			font-weight: normal;
      justify-content: start;
      margin: 0 2rem 0.5rem 2rem;

      input {
        padding-right: 0
      }
		}
    `,
  template: `
    <div class="wrapper" [class.slim]="slim">
      <mat-icon class="calendar-icon" svgIcon="calendar_month"></mat-icon>
      <input
        type="button"
        [id]="id"
        [placeholder]="'none'"
        [value]="value | maskedDate : namedDates : format"
        (click)="open = !open"
      />
      <label [for]="id">{{ label }}</label>
      <div class="date-popover" [class.open]="open">
        <app-named-dates
          [selectedDate]="value"
          [namedDates]="namedDates"
          (select)="onValueChange($event)"
        ></app-named-dates>
        <app-date-picker
          [value]="value"
          (valueChange)="onValueChange($event)"
        ></app-date-picker>
        @if(showHour){
          <hr/>
          <span class="time-picker">
            <b>Hour:</b>
            <input
              type="number"
              min="0"
              max="23"
              [formControl]="hourControl"
            />:00
          </span>
        }
      </div>
    </div>
  `,
})
export class DatePickerButtonComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  elementRef = inject(ElementRef);
  changeDetectorRef = inject(ChangeDetectorRef);
  _defaultHour = 0;

  @Input() id: string;
  @Input() label: string;
  @Input() namedDates: NamedDate[] = [];
  @Input() value: Date | null;
  @Input() slim = false;
  @Input() showHour = false;
  @Input() 
  set defaultHour(v: number) {
    this._defaultHour = v;
    this.hourControl.setValue(v);
  };
  get defaultHour(): number {
    return this._defaultHour;
  }

  @Output() valueChange = new EventEmitter<Date>();

  subscription = new Subscription();
  hourControl = new FormControl(this.defaultHour);
  format: string;
  open = false;

  @HostListener("document:click", ["$event"])
  handleOutsideClick(event: Event) {
    if (
      this.open &&
      !this.elementRef.nativeElement.contains(event.target as Node)
    ) {
      this.open = false;
    }
  }

  ngOnInit(): void {
    this.subscription.add(
      this.hourControl.valueChanges.subscribe((newValue) => {
        if (newValue) {
          const cleanValue = clamp(newValue, 0, 23);
          if (newValue != cleanValue) {
            this.hourControl.setValue(cleanValue);
          } else {
            this.emit(this.value);
          }
        }
      })
    );
  }

  ngAfterViewInit(): void {
    if (this.showHour) {
      this.format = "yyyy-MM-DD HH:00";
    }
    if (this.value) {
      this.hourControl.setValue(this.value.getHours());
    }
    this.changeDetectorRef.detectChanges();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onValueChange(newDate: Date) {
    this.emit(newDate);
  }

  emit(value: Date | null) {
    if (value) {
      const newDate = moment(value)
        .startOf("D")
        .set("h", this.hourControl.value ?? this.defaultHour)
        .toDate();
      this.value = newDate;
      this.valueChange.emit(newDate);
    }
  }
}
