import { AfterViewInit, Component, ElementRef, ViewChild, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { TableEditableDataService } from "../services/table-editable-data-service";

function setInputFilter(textbox: HTMLInputElement, inputFilter: (v: string) => boolean) {
  ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function(event) {
    textbox.addEventListener(event, function(this: {
      value: string, 
      selectionStart: string, 
      selectionEnd: string,
      setSelectionRange: (a: string, b: string) => void,
      oldValue: string, 
      oldSelectionStart: string, 
      oldSelectionEnd: string
    }) {
      if (inputFilter(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      } else if (this.hasOwnProperty("oldValue")) {
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      } else {
        this.value = "";
      }
    });
  });
}

export type ParamsType<
  DATA extends Record<string, unknown>,
  PROPS extends Record<string, unknown>,
  VALUE
> = ICellRendererParams<DATA, VALUE> & PROPS;

export type PROPS = {
  inputFilter: (val: string) => boolean
}

@Component({
    standalone: true,
    selector: 'app-number-cell',
    imports: [FormsModule],
    styles: `
      :host {
          position: relative;
      }

      input {
        margin: 0 0 0.25rem 0;
        height: 2rem;
        width: 100%;
        border: none;
        border-bottom: 1px solid black;
      }
      `,
      template: `
    <input #inputEl type="text" (keyup)="handleChange($event)"/>
      `
})
export class AlwaysEditableTextCellComponent<
  DATA extends Record<string, unknown>,
  VALUE
> implements ICellRendererAngularComp, AfterViewInit {
  editableDataService = inject(TableEditableDataService);

  params: ParamsType<DATA, PROPS, VALUE>
  key: string;

  @ViewChild('inputEl')
  private inputEl: ElementRef

  agInit(params: ParamsType<DATA, PROPS, VALUE>): void {
    this.refresh(params);
  }
  
  ngAfterViewInit(): void {
    setInputFilter(this.inputEl.nativeElement, this.params.inputFilter);
  }

  refresh(params: ParamsType<DATA, PROPS, VALUE>) {
    this.params = params;
    this.key = `${(this.params.node.id ?? '')}|${this.params.colDef?.field ?? ''}`;
    return true;
  }

  handleChange(evt: Event) {
    this.editableDataService.update(this.key, (evt?.target as any)?.value)
  }
}