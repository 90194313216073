import { Component, Input, ViewChild, inject } from "@angular/core";
import { FileInputComponent } from "../file-input/file-input.component";
import { ToastService } from "../toasts/toasts.service";

@Component({
    standalone: true,
    selector: 'app-input-validation-file-uploader',
    imports: [FileInputComponent],
    styles: `
    .input-container {
        display: flex;
    }
    `,
    template: `
    <h4>{{name}}</h4>
    <div class="input-container">
        <div #fileInput 
            app-file-input 
            [accept]="accept" 
            [skipParse]="skipParse" 
            (changeFile)="onUpload($event)">
          Upload {{name}} File
        </div>
        <button class="action-btn primary" type="button" [disabled]="file === undefined" (click)="onSubmit()">Upload</button>
    </div>
    `
})
export class InputValidationFileUploader {
    toast = inject(ToastService);

    @Input({required: true}) name: string;
    @Input({required: false}) accept = 'text/csv';
    @Input({required: false}) skipParse = true;
    @Input({required: false}) validation: (file: File) => Promise<string | void>
    @Input({required: true}) submit: (file: File) => Promise<string | void>

    @ViewChild("fileInput")
    fileInputEl: FileInputComponent

    public file: File | undefined = undefined

    async onUpload(file: File | undefined) {
        if(!file) {
            this.file = undefined
            return;
        }
        const error = await (this.validation ?? (() => void 0))(file)
        if(!error) {
            this.file = file;
        } else {
            this.toast.show({type: 'error', message: error})
            this.fileInputEl.clear();
            this.file = undefined;
        }
    }

    async onSubmit() {
        if(this.file) {
            const error = await this.submit(this.file);
            if(error) {
                this.toast.show({type: 'error', message: error});
            } else {
                this.toast.show({type: 'success', message: 'File successfully uploaded!'});
                this.fileInputEl.clear();
                this.file = undefined;
            }
        }
    }
}