import { ChangeDetectorRef, Component, inject, OnDestroy } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalHeaderComponent } from "../modal-header/modal-header.component";
import { ModalBodyComponent } from "../modal-body/modal-body.component";
import { ModalFooterComponent } from "../modal-footer/modal-footer.component";
import { BifoldComponent } from "../../bifold/bifold.component";
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  firstValueFrom,
  map,
  Observable,
  of,
  shareReplay,
  Subscription,
  switchMap,
  tap,
  take,
  throwError,
} from "rxjs";
import { LabeledPropertyComponent } from "../../labeled-property/labeled-property.component";
import { SpaOverrideService } from "../../spah-overrides/spah-override.service";
import { EntitySelectorComponent } from "../../entity-selector/entity-selector.component";
import { DatePickerButtonComponent } from "../../date-picker-button/date-picker-button.component";
import * as moment from "moment";
import {
  EntityRef,
  ServicePointIdReference,
} from "src/app/shared/models/referenceData";
import { ReferenceDataService } from "../../reference-data/reference-data.service";
import { ServicePointSelectorComponent } from "../../service-point-selector/service-point-selector.component";
import { BoolPickerComponent } from "../../bool-picker/bool-picker.component";
import { ServicePointResponse } from "src/app/shared/models/servicePointResponse";
import { ServicePointAttributesConfirmationTableComponent } from "../../table/service-point-attributes-confirmation-table.component";
import { SpinnerComponent } from "../../spinner/spinner.component";

type ServicePointOverrideRequest = {
  service_point_id: string;
  effective_date: string;
  termination_date: string;
  ldc_code: string;
  eldc_code: string;
  profile_class_code: string;
  retailer_code: string;
  supply_class_code: string;
  meter_type_code: string;
  loss_class_code: string;
  location_code: string;
  cpnode_code: string;
  service_point_source_code: string;
  weather_sensitive_flag: boolean;
  bmg_flag: boolean;
  tie_flag: boolean;
  net_meter_rider_flag: boolean;
  usage_ingestion_block_flag: boolean;
};

@Component({
  standalone: true,
  imports: [
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    BifoldComponent,
    LabeledPropertyComponent,
    DatePickerButtonComponent,
    ServicePointSelectorComponent,
    EntitySelectorComponent,
    BoolPickerComponent,
    ServicePointAttributesConfirmationTableComponent,
    SpinnerComponent,
  ],
  selector: "app-service-point-override-create-modal",
  styles: `
  .spinner-anchor {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
  }
  `,
  template: `
    <div app-modal-header>
      {{
        mode === "create"
          ? action === "create"
            ? "Create Override"
            : "Update Override"
          : "Confirm Changes"
      }}
    </div>

    <div app-modal-body>
      @if(mode === 'create') { @if(loading) {
      <div class="spinner-anchor">
        <app-spinner></app-spinner>
      </div>
      }
      <div app-bifold>
        <div class="left-col">
          <div app-labeled-property label="Service Point">
            <div
              app-service-point-selector
              [slim]="true"
              [value]="servicePoint$.value"
              (valueChange)="servicePoint$.next($event)"
            ></div>
          </div>
          <div app-labeled-property label="Effective Date">
            <app-date-picker-button
              [slim]="true"
              [value]="effectiveDate$.value"
              (valueChange)="effectiveDate$.next($event)"
            >
            </app-date-picker-button>
          </div>
          <div app-labeled-property label="Termination Date">
            <app-date-picker-button
              [slim]="true"
              [value]="terminationDate$.value"
              (valueChange)="terminationDate$.next($event)"
            >
            </app-date-picker-button>
          </div>
          <div app-labeled-property label="Weather Sensitive">
            <div
              app-bool-picker
              [value]="weatherSensitive$.value"
              (valueChange)="weatherSensitive$.next($event)"
            ></div>
          </div>
          <div app-labeled-property label="BMG">
            <div
              app-bool-picker
              [value]="bmg$.value"
              (valueChange)="bmg$.next($event)"
            ></div>
          </div>
          <div app-labeled-property label="Tie">
            <div
              app-bool-picker
              [value]="tie$.value"
              (valueChange)="tie$.next($event)"
            ></div>
          </div>
          <div app-labeled-property label="Net Meter Rider">
            <div
              app-bool-picker
              [value]="netMeterRider$.value"
              (valueChange)="netMeterRider$.next($event)"
            ></div>
          </div>
          <div app-labeled-property label="Usage Ingestion Block">
            <div
              app-bool-picker
              [value]="usageIngestionBlock$.value"
              (valueChange)="usageIngestionBlock$.next($event)"
            ></div>
          </div>
        </div>
        <div class="right-col">
          <div app-labeled-property label="Service Point Source">
            <div
              app-entity-selector
              [slim]="true"
              [value]="servicePointSource$.value"
              (valueChange)="servicePointSource$.next($event)"
              label="Service Point Source"
              resource="servicePointSources"
            ></div>
          </div>

          <div app-labeled-property label="LDC">
            <div
              app-entity-selector
              [slim]="true"
              [value]="ldc$.value"
              (valueChange)="ldc$.next($event)"
              label="LDC"
              resource="lossDistributionCompanies"
            ></div>
          </div>

          <div app-labeled-property label="ELDC">
            <div
              app-entity-selector
              [slim]="true"
              [value]="eldc$.value"
              (valueChange)="eldc$.next($event)"
              label="ELDC"
              resource="lossDistributionCompanies"
            ></div>
          </div>

          <div app-labeled-property label="Profile Class">
            <div
              app-entity-selector
              [slim]="true"
              [value]="profileClass$.value"
              (valueChange)="profileClass$.next($event)"
              label="Profile Class"
              resource="profileClasses"
            ></div>
          </div>

          <div app-labeled-property label="Retailer">
            <div
              app-entity-selector
              [slim]="true"
              [value]="retailer$.value"
              (valueChange)="retailer$.next($event)"
              label="Retailer"
              resource="retailers"
            ></div>
          </div>

          <div app-labeled-property label="Supply Class">
            <div
              app-entity-selector
              [slim]="true"
              [value]="supplyClass$.value"
              (valueChange)="supplyClass$.next($event)"
              label="Supply Class"
              resource="supplyClasses"
            ></div>
          </div>

          <div app-labeled-property label="Meter Type">
            <div
              app-entity-selector
              [slim]="true"
              [value]="meterType$.value"
              (valueChange)="meterType$.next($event)"
              label="Meter Type"
              resource="meterTypes"
            ></div>
          </div>

          <div app-labeled-property label="Loss Class">
            <div
              app-entity-selector
              [slim]="true"
              [value]="lossClass$.value"
              (valueChange)="lossClass$.next($event)"
              label="Loss Class"
              resource="lossClasses"
            ></div>
          </div>

          <div app-labeled-property label="Location">
            <div
              app-entity-selector
              [slim]="true"
              [value]="location$.value"
              (valueChange)="location$.next($event)"
              label="Location"
              resource="locations"
            ></div>
          </div>
          <div app-labeled-property label="CP Node">
            <div
              app-entity-selector
              [slim]="true"
              [value]="cpNode$.value"
              (valueChange)="cpNode$.next($event)"
              label="CP Node"
              resource="cpNodes"
            ></div>
          </div>
        </div>
      </div>
      } @else {
      <app-service-point-attributes-confirmation-table
        [loading]="loading"
        [data]="confirmationData.results"
      >
      </app-service-point-attributes-confirmation-table>
      }
    </div>

    <div app-modal-footer>
      @if(mode === 'create') {
      <button
        class="action-btn primary"
        [disabled]="!canSubmitCreation()"
        (click)="submitCreation()"
      >
        Submit
      </button>
      } @else {
      <button
        class="action-btn primary"
        [disabled]="!canConfirmCreation()"
        (click)="confirmCreation()"
      >
        Confirm
      </button>
      }
      <button class="action-btn" (click)="onCancel()">Cancel</button>
    </div>
  `,
})
export class ServicePointOverrideCreateModal implements OnDestroy {
  activeModal = inject(NgbActiveModal);
  servicePointOverrideService = inject(SpaOverrideService);
  referenceDataService = inject(ReferenceDataService);
  changeDetectorRef = inject(ChangeDetectorRef);

  subscription = new Subscription();
  mode: "create" | "confirm" = "create";
  action: "create" | "update" = "create";
  loading: boolean = false;
  confirmationData: ServicePointResponse;
  req: Partial<ServicePointOverrideRequest> = {};
  servicePoint$ = new BehaviorSubject<ServicePointIdReference | null>(null);
  effectiveDate$ = new BehaviorSubject<Date | null>(null);
  terminationDate$ = new BehaviorSubject<Date | null>(null);
  servicePointSource$ = new BehaviorSubject<EntityRef | null>(null);
  ldc$ = new BehaviorSubject<EntityRef | null>(null);
  eldc$ = new BehaviorSubject<EntityRef | null>(null);
  profileClass$ = new BehaviorSubject<EntityRef | null>(null);
  retailer$ = new BehaviorSubject<EntityRef | null>(null);
  supplyClass$ = new BehaviorSubject<EntityRef | null>(null);
  meterType$ = new BehaviorSubject<EntityRef | null>(null);
  lossClass$ = new BehaviorSubject<EntityRef | null>(null);
  location$ = new BehaviorSubject<EntityRef | null>(null);
  cpNode$ = new BehaviorSubject<EntityRef | null>(null);
  weatherSensitive$ = new BehaviorSubject<boolean>(false);
  bmg$ = new BehaviorSubject<boolean>(false);
  tie$ = new BehaviorSubject<boolean>(false);
  netMeterRider$ = new BehaviorSubject<boolean>(false);
  usageIngestionBlock$ = new BehaviorSubject<boolean>(false);
  req$ = combineLatest([
    this.servicePoint$,
    this.effectiveDate$,
    this.terminationDate$,
    this.servicePointSource$,
    this.ldc$,
    this.eldc$,
    this.profileClass$,
    this.retailer$,
    this.supplyClass$,
    this.meterType$,
    this.lossClass$,
    this.location$,
    this.cpNode$,
    this.weatherSensitive$,
    this.bmg$,
    this.tie$,
    this.netMeterRider$,
    this.usageIngestionBlock$,
  ]).pipe(
    map(
      ([
        servicePoint,
        effectiveDate,
        terminationDate,
        servicePointSource,
        ldc,
        eldc,
        profileClass,
        retailer,
        supplyClass,
        meterType,
        lossClass,
        location,
        cpNode,
        weatherSensitive,
        bmg,
        tie,
        netMeterRider,
        usageIngestionBlock,
      ]): Partial<ServicePointOverrideRequest> => ({
        service_point_id: servicePoint
          ? servicePoint.service_point_id
          : undefined,
        effective_date: effectiveDate
          ? moment(effectiveDate).toISOString().split(".")[0]
          : undefined,
        termination_date: terminationDate
          ? moment(terminationDate).toISOString().split(".")[0]
          : undefined,
        service_point_source_code: servicePointSource
          ? servicePointSource.entity_code
          : undefined,
        ldc_code: ldc ? ldc.entity_code : undefined,
        eldc_code: eldc ? eldc.entity_code : undefined,
        profile_class_code: profileClass ? profileClass.entity_code : undefined,
        retailer_code: retailer ? retailer.entity_code : undefined,
        supply_class_code: supplyClass ? supplyClass.entity_code : undefined,
        meter_type_code: meterType ? meterType.entity_code : undefined,
        loss_class_code: lossClass ? lossClass.entity_code : undefined,
        location_code: location ? location.entity_code : undefined,
        cpnode_code: cpNode ? cpNode.entity_code : undefined,
        weather_sensitive_flag: weatherSensitive,
        bmg_flag: bmg,
        tie_flag: tie,
        net_meter_rider_flag: netMeterRider,
        usage_ingestion_block_flag: usageIngestionBlock,
      })
    ),
    shareReplay(1)
  );

  findEntity(
    entities$: Observable<EntityRef[]>,
    code: string | undefined
  ): Observable<null | EntityRef> {
    return entities$.pipe(
      map((entities) => {
        return entities.find((e) => e.entity_code === code) ?? null;
      })
    );
  }

  canConfirmCreation(): boolean {
    return this.mode === "confirm" && !this.loading;
  }

  canSubmitCreation(
    r: Partial<ServicePointOverrideRequest> = this.req
  ): r is ServicePointOverrideRequest {
    return [
      "service_point_id",
      "effective_date",
      "termination_date",
      "ldc_code",
      "eldc_code",
      "profile_class_code",
      "retailer_code",
      "supply_class_code",
      "meter_type_code",
      "loss_class_code",
      "location_code",
      "cpnode_code",
      "service_point_source_code",
      "weather_sensitive_flag",
      "bmg_flag",
      "tie_flag",
      "net_meter_rider_flag",
      "usage_ingestion_block_flag",
    ].every((key) => (r as any)[key] !== undefined);
  }

  close(changed = false) {
    if (changed) {
      this.activeModal.close();
    } else {
      this.activeModal.dismiss();
    }
  }

  async confirmCreation(): Promise<void> {
    if (this.canSubmitCreation(this.req) && this.canConfirmCreation()) {
      const workflow$ = of(this.req).pipe(
        tap(() => {
          this.loading = true;
        }),
        switchMap((req) =>
          this.servicePointOverrideService[
            this.action === "create" ? "createOverride" : "updateOverride"
          ](req, false)
        ),
        tap(() => {
          this.loading = false;
          this.close(true);
        }),
        catchError((e) => {
          this.loading = false;
          return throwError(() => e);
        })
      );
      await firstValueFrom(workflow$);
    }
  }

  async init(
    action: typeof this.action,
    req: Partial<ServicePointOverrideRequest>,
    id: ServicePointIdReference | null
  ) {
    this.subscription.add(
      this.req$.subscribe((r) => {
        this.req = r;
        this.changeDetectorRef.detectChanges();
      })
    );
    this.subscription.add(
      combineLatest([
        of(id),
        of(req.effective_date ? moment(req.effective_date).toDate() : null),
        of(req.termination_date ? moment(req.termination_date).toDate() : null),
        this.findEntity(
          this.referenceDataService.servicePointSources(),
          req.service_point_source_code
        ),
        this.findEntity(
          this.referenceDataService.lossDistributionCompanies(),
          req.ldc_code
        ),
        this.findEntity(
          this.referenceDataService.lossDistributionCompanies(),
          req.eldc_code
        ),
        this.findEntity(
          this.referenceDataService.profileClasses(),
          req.profile_class_code
        ),
        this.findEntity(
          this.referenceDataService.retailers(),
          req.retailer_code
        ),
        this.findEntity(
          this.referenceDataService.supplyClasses(),
          req.supply_class_code
        ),
        this.findEntity(
          this.referenceDataService.meterTypes(),
          req.meter_type_code
        ),
        this.findEntity(
          this.referenceDataService.lossClasses(),
          req.loss_class_code
        ),
        this.findEntity(
          this.referenceDataService.locations(),
          req.location_code
        ),
        this.findEntity(this.referenceDataService.cpNodes(), req.cpnode_code),
        of(req.weather_sensitive_flag ?? false),
        of(req.bmg_flag ?? false),
        of(req.tie_flag ?? false),
        of(req.net_meter_rider_flag ?? false),
        of(req.net_meter_rider_flag ?? false),
      ])
        .pipe(take(1))
        .subscribe(
          ([
            servicePoint,
            effectiveDate,
            terminationDate,
            servicePointSource,
            ldc,
            eldc,
            profileClass,
            retailer,
            supplyClass,
            meterType,
            lossClass,
            location,
            cpNode,
            weatherSensitive,
            bmg,
            tie,
            netMeterRider,
            usageIngestionBlock,
          ]) => {
            this.servicePoint$.next(servicePoint);
            this.effectiveDate$.next(effectiveDate);
            this.terminationDate$.next(terminationDate);
            this.servicePointSource$.next(servicePointSource);
            this.ldc$.next(ldc);
            this.eldc$.next(eldc);
            this.profileClass$.next(profileClass);
            this.retailer$.next(retailer);
            this.supplyClass$.next(supplyClass);
            this.meterType$.next(meterType);
            this.lossClass$.next(lossClass);
            this.location$.next(location);
            this.cpNode$.next(cpNode);
            this.weatherSensitive$.next(weatherSensitive);
            this.bmg$.next(bmg);
            this.tie$.next(tie);
            this.netMeterRider$.next(netMeterRider);
            this.usageIngestionBlock$.next(usageIngestionBlock);
            this.changeDetectorRef.detectChanges();
          }
        )
    );
    this.action = action;
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onCancel() {
    this.close();
  }

  async submitCreation(): Promise<void> {
    if (this.canSubmitCreation(this.req)) {
      const workflow$ = of(this.req).pipe(
        tap(() => {
          this.loading = true;
        }),
        switchMap((req) =>
          this.servicePointOverrideService[
            this.action === "create" ? "createOverride" : "updateOverride"
          ](req, true)
        ),
        tap(() => {
          this.mode = "confirm";
          this.loading = false;
        }),
        catchError((e) => {
          this.loading = false;
          return throwError(() => e);
        })
      );
      this.confirmationData = await firstValueFrom(workflow$);
    }
  }
}
