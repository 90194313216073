import { HttpClient } from "@angular/common/http";
import { APIEnvelope } from "../models/api";
import { firstValueFrom } from "rxjs";
import { httpParams, withHttpParams, pagination } from "./http-params";

export const fetchUntilExhausted = async <T>(
  http: HttpClient,
  url: string,
  pageSize: number,
  pageNumber: number,
  lastRes?: APIEnvelope<T>
): Promise<APIEnvelope<T>> => {
  const res = await firstValueFrom(
    http.get<APIEnvelope<T>>(url, {
      headers: { NOLOADING: "true" },
      params: httpParams(withHttpParams(pagination, { pageNumber, pageSize })),
    })
  );
  const acc = {
    results: [...(lastRes?.results ?? []), ...res.results],
    total: res.total,
  };
  return acc.results.length !== acc.total &&
    res.results.length === res.total && // primary key issue
    res.results.length === pageSize // primary key issue too
    ? fetchUntilExhausted(http, url, pageSize, pageNumber + 1, acc)
    : acc;
};
