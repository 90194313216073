import { Component } from "@angular/core";
import { BaseCellComponent } from "./base-cell.component";
import { BoolDisplayPipe } from "src/app/shared/pipes/boolDisplay.pipe";

type FlagCellComponentProps = {};

@Component({
  standalone: true,
  imports: [BoolDisplayPipe],
  styles: `
        :host {
            position: relative;
        }
    `,
  template: ` {{ params.value | boolDisplay }} `,
})
export class FlagCellComponent<
  DATA extends Record<string, unknown>
> extends BaseCellComponent<
  DATA,
  FlagCellComponentProps,
  keyof DATA,
  DATA[keyof DATA] & boolean
> {}
