import { ColDef } from "ag-grid-community";
import { DateCellComponent } from "../cells/date-cell.component";
import { TableHeaderComponent } from "../headers/table-header.component";
import { FlagCellComponent } from "../cells/flag-cell.component";

const filterGroup = "service_point_attributes_confirmation";

export class ServicePointAttributeConfirmationColumnDefinition {
  constructor() {}

  sharedDef: ColDef = {
    filter: false,
    sortable: false,
    editable: false,
    minWidth: 100,
    width: 150,
  };

  getColDefs = (): ColDef[] => [
    {
      ...this.sharedDef,
      width: 165,
      headerName: "Service Point",
      field: "service_point_id",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup
      }
    },
    {
      ...this.sharedDef,
      headerName: "Effective Date",
      field: "effective_date",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        format: "YYYY/MM/DD",
      },
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup
      }
     },
    {
      ...this.sharedDef,
      headerName: "Termination Date",
      field: "termination_date",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        format: "YYYY/MM/DD",
      },
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup
      }
     },
    {
      ...this.sharedDef,
      headerName: "LDC",
      field: "ldc_code",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup
      }
     },
    {
      ...this.sharedDef,
      headerName: "ELDC",
      field: "eldc_code",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup
      }
     },
    {
      ...this.sharedDef,
      headerName: "Profile Class",
      field: "profile_class_code",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup
      }
    },
    {
      ...this.sharedDef,
      headerName: "Retailer",
      field: "retailer_code",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup
      }
    },
    {
      ...this.sharedDef,
      headerName: "Supply Class",
      field: "supply_class_code",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup
      }
    },
    {
      ...this.sharedDef,
      headerName: "Meter Type",
      field: "meter_type_code",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup
      }
    },
    {
      ...this.sharedDef,
      headerName: "Loss Class",
      field: "loss_class_code",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup
      }
    },
    {
      ...this.sharedDef,
      headerName: "Location",
      field: "location_code",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup
      }
    },
    {
      ...this.sharedDef,
      headerName: "CP Node",
      field: "cpnode_code",
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup
      }
    },
    {
      ...this.sharedDef,
      headerName: "Weather Sensitive",
      field: "weather_sensitive_flag",
      cellRenderer: FlagCellComponent,
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup
      }
    },
    {
      ...this.sharedDef,
      headerName: "BMG",
      field: "bmg_flag",
      cellRenderer: FlagCellComponent,
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup
      }
    },
    {
      ...this.sharedDef,
      headerName: "Tie",
      field: "tie_flag",
      cellRenderer: FlagCellComponent,
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup
      }
    },
    {
      ...this.sharedDef,
      headerName: "Net Meter Rider",
      field: "net_meter_rider_flag",
      cellRenderer: FlagCellComponent,
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup
      }
    },
    {
      ...this.sharedDef,
      headerName: "Usage Ingestion Block",
      field: "usage_ingestion_block_flag",
      cellRenderer: FlagCellComponent,
      headerComponent: TableHeaderComponent,
      headerComponentParams: {
        filterGroup
      }
    },
  ];
}
