import { HttpClient } from "@angular/common/http";
import { DestroyRef, inject, Injectable } from "@angular/core";
import { orderBy } from "lodash";
import * as moment from "moment-timezone";
import { map, Observable, shareReplay, Subject, switchMap, tap, catchError } from "rxjs";
import {
  RLSCertMetadata,
  RLSCertMetadataResponse,
} from "src/app/shared/models/rls-cert-metadata";
import { RLSCertUploadRequest } from "src/app/shared/models/rls-cert-upload-request";
import { FilterParams, SortParams } from "src/app/shared/utilities/http-params";
import { ToastService } from "../toasts/toasts.service";

const isDateField = (fName: string) => fName.includes('date');

const compareDates = (str1: string, str2: string) => (fn: (d1: moment.Moment, d2: moment.Moment) => boolean) => {
  const val = fn(moment(str1).startOf('D'), moment(str2).startOf('D'));
  console.log(JSON.stringify({str1, str2, val}));
  return val;
}

const filterLocallyByParams =
  (filters: FilterParams[]) =>
  <T extends Record<string, any>>(arr: T[]): T[] => {
    return filters.reduce(
      (acc, filter) =>
        acc.filter((item) =>
          filter.values.some((fv) => {
            if (fv.operation === "!=") {
              return isDateField(filter.name)
                ? compareDates(item[filter.name], fv.value)((d1, d2) => !d1.isSame(d2))
                : item[filter.name] > fv.value;
            }
            if (fv.operation === "<") {
              return isDateField(filter.name)
                ? compareDates(item[filter.name], fv.value)((d1, d2) => d1.isBefore(d2))
                : item[filter.name] < fv.value;
            }
            if (fv.operation === ">") {
              return isDateField(filter.name)
                ? compareDates(item[filter.name], fv.value)((d1, d2) => d1.isAfter(d2))
                : item[filter.name] > fv.value;
            }
            if (fv.operation === "<=") {
              return isDateField(filter.name)
                ? compareDates(item[filter.name], fv.value)((d1, d2) => d1.isSameOrBefore(d2))
                : item[filter.name] <= fv.value;
            }
            if (fv.operation === ">=") {
              return isDateField(filter.name)
                ? compareDates(item[filter.name], fv.value)((d1, d2) => d1.isSameOrAfter(d2))
                : item[filter.name] >= fv.value;
            }
            if (fv.operation === "==") {
              return isDateField(filter.name)
                ? compareDates(item[filter.name], fv.value)((d1, d2) => d1.isSame(d2))
                : item[filter.name] === fv.value;
            }
            return `${item[filter.name]}`
              .toLowerCase()
              .startsWith(fv.value.toLowerCase());
          })
        ),
      arr
    );
  };

const sortLocallyByOrder =
  (order: SortParams[]) =>
  <T extends Record<string, any>>(arr: T[]): T[] =>
    orderBy<T>(
      arr,
      order.map((o) => o.name),
      order.map((o) => o.direction ?? "asc")
    );

@Injectable({
  providedIn: "root",
})
export class RLSCertificatesService {
  private _http = inject(HttpClient);
  private _toast = inject(ToastService);
  private _destroyRef = inject(DestroyRef);

  private _rlsCertMetadataRefetchTrigger = new Subject<void>();
  private _rlsCertMetadataFetch = this._rlsCertMetadataRefetchTrigger.pipe(
    switchMap(() =>
      this._http.get<RLSCertMetadataResponse>("rls_certs/metadata")
    ),
    map((res) => res.results),
    shareReplay(1)
  );
  private _subscription = this._rlsCertMetadataFetch.subscribe();

  constructor() {
    this._destroyRef.onDestroy(() => {
      this._subscription.unsubscribe();
    });
  }

  refresh() {
    this._rlsCertMetadataRefetchTrigger.next(void 0);
  }

  getCertMetadata(
    filters: FilterParams[] = [],
    order: SortParams[] = []
  ): Observable<RLSCertMetadata[]> {
    this._rlsCertMetadataRefetchTrigger.next(void 0);
    return this._rlsCertMetadataFetch.pipe(
      map(filterLocallyByParams(filters)),
      map(sortLocallyByOrder(order))
    );
  }

  uploadCert(request: RLSCertUploadRequest) {
    const formData = new FormData();
    Object.entries(request).forEach(([k, v]) => formData.append(k, v));
    return this._http.post<string>("rls_certs", formData, {
      responseType: 'json',
      reportProgress: true,
      headers: {
      }
    }).pipe(
      tap(() => {
        this._rlsCertMetadataRefetchTrigger.next(void 0);
      }),
      catchError((err) => {
        this._toast.show({
          type: 'error',
          message: 'Something went wrong! Please review the provided information and try again.'
        })
        throw err;
      })
    );
  }

  downloadCert(participant: string) {
    return this._http.get<string>(`rls_certs/participant/${participant}/download`).pipe(
      tap((url) => {
        var link = document.createElement("a");
        link.href = url;
        link.click();
      }),
      catchError((err) => {
        this._toast.show({
          type: 'error',
          message: 'Something went wrong! Please try again.'
        })
        throw err;
      })
    )
  }

  deleteCert(participant: string) {
    return this._http.delete<void>(`rls_certs/participant/${participant}`).pipe(
      tap(() => {
        this._rlsCertMetadataRefetchTrigger.next(void 0);
      }),
      catchError((err) => {
        this._toast.show({
          type: 'error',
          message: 'Something went wrong! Please try again.'
        })
        throw err;
      })
    )
  }
}
