import { Component, inject, OnDestroy } from "@angular/core";
import { AbstractFilterModal } from "./abstract-filter-modal";
import { ModalHeaderComponent } from "../modal-header/modal-header.component";
import { ModalBodyComponent } from "../modal-body/modal-body.component";
import { ModalFooterComponent } from "../modal-footer/modal-footer.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import {
  DateRangeFilter,
  FilterService,
} from "src/app/shared/services/filter.service";
import { ActiveFilterListComponent } from "../../table/filters/active-filter-list/active-filter-list.component";
import { NamedDate } from "src/app/shared/models/namedDate";
import { LabeledPropertyComponent } from "../../labeled-property/labeled-property.component";
import { DatePickerButtonComponent } from "../../date-picker-button/date-picker-button.component";
import * as moment from "moment";

type DateRangeFilterModalInit = {
  namedDates?: NamedDate[];
  enableHours?: boolean;
};

@Component({
  standalone: true,
  imports: [
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    ActiveFilterListComponent,
    LabeledPropertyComponent,
    DatePickerButtonComponent,
  ],
  selector: "app-date-range-filter-modal",
  styles: `
  `,
  template: `
    <div app-modal-header>{{ fieldNameDisplay }}</div>
    <div app-modal-body>
      <p>Select a date range for the column {{ fieldNameDisplay }} below</p>
      <div app-labeled-property label="Start Date">
        <app-date-picker-button
          [value]="selectedStartDate"
          (valueChange)="selectStartDate($event)"
          [namedDates]="modalInit.namedDates ?? []"
          [slim]="true"
          [showHour]="modalInit.enableHours ?? false"
        />
      </div>
      <div app-labeled-property label="End Date">
        <app-date-picker-button
          [value]="selectedEndDate"
          (valueChange)="selectEndDate($event)"
          [namedDates]="modalInit.namedDates ?? []"
          [slim]="true"
          [showHour]="modalInit.enableHours ?? false"
          [defaultHour]="23"
        />
      </div>
      <app-active-filter-list
        [filterGroup]="filterGroup"
        [filterName]="field"
      ></app-active-filter-list>
    </div>
    <div app-modal-footer>
      <button class="action-btn" (click)="onClose()">Close</button>
    </div>
  `,
})
export class DateRangeFilterModalComponent
  extends AbstractFilterModal<DateRangeFilterModalInit>
  implements OnDestroy
{
  private modal = inject(NgbActiveModal);
  private filterService = inject(FilterService);

  private subscription = new Subscription();
  public filterGroup: string;
  public field: string;
  public modalInit: DateRangeFilterModalInit;
  public fieldNameDisplay: string = "";
  public selectedStartDate: Date | null;
  public selectedEndDate: Date | null;

  public override init(
    modalInit: DateRangeFilterModalInit,
    filterGroup: string,
    field: string,
    fieldNameDisplay: string
  ): void {
    this.modalInit = modalInit;
    this.filterGroup = filterGroup;
    this.field = field;
    this.fieldNameDisplay = fieldNameDisplay;

    this.subscription.add(
      this.filterService.state(this.filterGroup).subscribe((fp) => {
        const filter = fp[this.field] as DateRangeFilter;
        if (filter) {
          this.selectedStartDate = filter.value[0];
          this.selectedEndDate = filter.value[1];
        } else {
          this.selectedStartDate = null;
          this.selectedEndDate = null;
        }
      })
    );
  }

  selectStartDate(date: Date) {
    this.selectedStartDate = date;
    this.select();
  }

  selectEndDate(date: Date) {
    this.selectedEndDate = date;
    this.select();
  }

  select() {
    if (!(this.selectedStartDate && this.selectedEndDate)) {
      return;
    }
    if(this.selectedEndDate < this.selectedStartDate) {
      const tmpDate = moment(this.selectedEndDate).toDate();
      this.selectedEndDate = this.selectedStartDate;
      this.selectedStartDate = tmpDate; 
    }
    this.filterService.dispatch({
      type: "SET_DATE_RANGE_FILTER",
      value: [this.selectedStartDate, this.selectedEndDate],
      display: this.fieldNameDisplay,
      filterGroup: this.filterGroup,
      filterName: this.field,
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onClose() {
    this.modal.close();
  }
}
