import { Component, inject, TemplateRef, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EntityRef } from "src/app/shared/models/referenceData";
import { ReferenceDataService } from "../reference-data/reference-data.service";
import * as moment from "moment";
import { SettlementsService } from "../settlements/service/settlements.service";
import { firstValueFrom } from "rxjs";
import { ToastService } from "../toasts/toasts.service";
import { MDMAJobStatus } from "../settlements/service/settlements.types";

@Component({
  selector: "app-mdma-aggregations",
  styles: `
    .modal-body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 1rem;
    }

    .modal-close-icon {
      margin-top: -4px;
      margin-left: -5px;
    }
  `,
  template: `
    <app-page-header title="MDMA Settlement Runs">
      <button class="action-btn" (click)="open(modal)">
        Run <mat-icon svgIcon="add_circle" />
      </button>
    </app-page-header>
    <app-page-content>
      <app-mdma-table-aggregations-table (create)="openWithData($event)" />
    </app-page-content>

    <ng-template #modal let-modal>
      <div class="modal-header">
        <h4 class="modal-title">Create run</h4>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="modal.dismiss('dismiss')"
        >
          <mat-icon class="modal-close-icon" svgIcon="close"></mat-icon>
        </button>
      </div>
      <div class="modal-body">
        <app-date-picker-button
          [label]="'Operating Date'"
          [id]="'operating-date-input'"
          [slim]="true"
          [(value)]="operatingDate"
        ></app-date-picker-button>
        <div app-entity-selector
          label="Settlement Type"
          resource="settlementType"
          [slim]="true"
          [(value)]="settlementType"
        ></div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="action-btn primary"
          [disabled]="!operatingDate || !settlementType"
          (click)="startRun(operatingDate, settlementType.entity_code)"
        >
          Run
        </button>
      </div>
    </ng-template>
  `,
})
export class MDMAAggregationsComonent {
  private modalService = inject(NgbModal);
  private referenceDataService = inject(ReferenceDataService);
  private settlementsService = inject(SettlementsService);
  private toastsService = inject(ToastService);

  @ViewChild("modal")
  private modalRef: TemplateRef<any>;

  // for form
  public operatingDate: Date = moment().startOf("D").utc().toDate();
  public settlementType: EntityRef;
  displayGetter = (d: EntityRef) => d?.entity_name;
  valueGetter = (d: EntityRef) => d?.entity_code;
  hasMoreOptions = (d: EntityRef[], pageSize: number) => d.length === pageSize;
  fetchOptions = (search: string) =>
    this.referenceDataService.settlementTypes([
      {
        name: "entity_name",
        values: [{ value: search }],
      },
    ]);
  saving = false;

  open(content: TemplateRef<any>) {
    this.modalService
      .open(content, { ariaLabelledBy: "create-run", centered: true })
      .result.then(this.handleClose.bind(this), this.handleClose.bind(this));
  }

  openWithData(data: MDMAJobStatus) {
    this.operatingDate = moment(data.operating_day).startOf("D").utc().toDate();
    this.settlementType = {
      entity_code: data.settlement_type,
      entity_name: data.settlement_type,
    };
    this.open(this.modalRef);
  }

  handleClose() {}

  async startRun(operatingDate: Date, settlementType: string) {
    this.saving = true;
    try {
      await firstValueFrom(
        this.settlementsService.run(operatingDate, settlementType)
      );
      this.modalService.dismissAll();
      this.toastsService.show({
        message: "Run successfully queued",
        type: "success",
      });
    } catch (err) {
      this.toastsService.show({
        message: "An error occured while starting your run. Please try again.",
        type: "error",
      });
    } finally {
      this.saving = false;
    }
  }
}
