import { Component, inject, OnDestroy } from "@angular/core";
import { AbstractFilterModal } from "./abstract-filter-modal";
import { ModalHeaderComponent } from "../modal-header/modal-header.component";
import { ModalBodyComponent } from "../modal-body/modal-body.component";
import { ModalFooterComponent } from "../modal-footer/modal-footer.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import {
  DateFilter,
  FilterService,
} from "src/app/shared/services/filter.service";
import { ActiveFilterListComponent } from "../../table/filters/active-filter-list/active-filter-list.component";
import { NamedDate } from "src/app/shared/models/namedDate";
import { DatePickerButtonComponent } from "../../date-picker-button/date-picker-button.component";
import { LabeledPropertyComponent } from "../../labeled-property/labeled-property.component";
import { SelectorComponent } from "../../selector/selector.component";

type DateFilterModalInit = {
  namedDates?: NamedDate[];
  showHours?: boolean;
};

@Component({
  standalone: true,
  imports: [
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    LabeledPropertyComponent,
    SelectorComponent,
    DatePickerButtonComponent,
    ActiveFilterListComponent,
  ],
  selector: "app-date-filter-modal",
  styles: `
  `,
  template: `
    <div app-modal-header>{{ fieldNameDisplay }}</div>
    <div app-modal-body>
      <p>Select a date for the column {{ fieldNameDisplay }} below</p>
      <div app-labeled-property label="Operation">
        <div
          app-selector
          [selected]="selectedOperationOption"
          [options]="operations"
          [slim]="true"
          (selectedChange)="selectOperation($event)"
        ></div>
      </div>
      <div app-labeled-property label="Date">
        <app-date-picker-button
          [value]="selectedDate"
          (valueChange)="selectDate($event)"
          [namedDates]="modalInit.namedDates ?? []"
          [slim]="true"
          [showHour]="modalInit.showHours ?? false"
        />
      </div>
      <app-active-filter-list
        [filterGroup]="filterGroup"
        [filterName]="field"
      ></app-active-filter-list>
    </div>
    <div app-modal-footer>
      <button class="action-btn" (click)="onClose()">Close</button>
    </div>
  `,
})
export class DateFilterModalComponent
  extends AbstractFilterModal<DateFilterModalInit>
  implements OnDestroy
{
  private modal = inject(NgbActiveModal);
  private filterService = inject(FilterService);

  private subscription = new Subscription();
  public filterGroup: string;
  public field: string;
  public modalInit: DateFilterModalInit;
  public fieldNameDisplay: string = "";
  public operations: {value: DateFilter['operation'], display: string}[] = [
    { value: "<", display: "Before" },
    { value: "<=", display: "Before or Equals" },
    { value: "==", display: "Equals" },
    { value: ">=", display: "After or Equals" },
    { value: ">", display: "After" }
  ];
  public selectedDate: Date | null;
  public selectedOperationOption: { value: DateFilter["operation"]; display: string }

  public override init(
    modalInit: DateFilterModalInit,
    filterGroup: string,
    field: string,
    fieldNameDisplay: string
  ): void {
    this.modalInit = modalInit;
    this.filterGroup = filterGroup;
    this.field = field;
    this.fieldNameDisplay = fieldNameDisplay;
    this.selectedOperationOption = this.operations[2];

    this.subscription.add(
      this.filterService.state(this.filterGroup).subscribe((fp) => {
        const filter = fp[this.field];
        if (filter) {
          this.selectedOperationOption = this.operations.find(
            (o) => o.value === (filter as DateFilter).operation
          )!;
          this.selectedDate = (filter as DateFilter).value;
        } else {
          this.selectedDate = null;
        }
      })
    );
  }

  selectOperation(operation: {
    value: DateFilter["operation"];
    display: string;
  }) {
    this.selectedOperationOption = operation;
    this.select();
  }

  selectDate(date: Date) {
    this.selectedDate = date;
    this.select();
  }

  select() {
    if (this.selectedDate && this.selectedOperationOption) {
      this.filterService.dispatch({
        type: "SET_DATE_FILTER",
        value: this.selectedDate,
        operation: this.selectedOperationOption.value,
        display: this.fieldNameDisplay,
        filterGroup: this.filterGroup,
        filterName: this.field,
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onClose() {
    this.modal.close();
  }
}
