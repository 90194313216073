import { DestroyRef, inject, Injectable } from "@angular/core";
import { EntityRef, ServicePointIdReference } from "../models/referenceData";
import { ServicePointGroup } from "../models/servicePointGroup";
import {
  map,
  Observable,
  scan,
  shareReplay,
  startWith,
  Subject,
  Subscription,
} from "rxjs";
import { FilterParams } from "../utilities/http-params";
import * as moment from "moment";
import { BoolDisplayPipe } from "../pipes/boolDisplay.pipe";

export type DateFilter = {
  type: "DATE";
  value: Date;
  display: string;
  operation: "<=" | "<" | "==" | ">" | ">=";
};

export type DateRangeFilter = {
  type: "DATE_RANGE";
  value: [Date, Date];
  display: string;
};

export type EntityFilter = {
  type: "ENTITY";

  value: EntityRef[];
  display: string;
};

export type FlagFilter = {
  type: "FLAG";
  value: boolean;
  display: string;
};

export type ServicePointFilter = {
  type: "SERVICE_POINT";
  value: (ServicePointIdReference | ServicePointGroup | string)[];
  useUids: boolean;
  display: string;
};

export type StringFilter = {
  type: "STRING";
  value: string[];
  display: string;
};

export type Filter =
  | DateFilter
  | DateRangeFilter
  | EntityFilter
  | FlagFilter
  | ServicePointFilter
  | StringFilter;

type ActiveFilterDisplay = {
  headerName: string;
  operation: string;
  value: string;
  remove: () => void;
};

// Actions
export type SetDateFilterAction = {
  type: "SET_DATE_FILTER";
  value: DateFilter["value"] | undefined;
  display: DateFilter["display"];
  operation: DateFilter["operation"];
  filterGroup: string;
  filterName: string;
};

export type SetDateRangeFilterAction = {
  type: "SET_DATE_RANGE_FILTER";
  value: DateRangeFilter["value"] | undefined;
  display: DateRangeFilter["display"];
  filterGroup: string;
  filterName: string;
};

export type AddEntityFilterAction = {
  type: "ADD_ENTITY_FILTER";
  value: EntityFilter["value"];
  display: EntityFilter["display"];
  filterGroup: string;
  filterName: string;
};

export type RemoveEntityFilterAction = {
  type: "REMOVE_ENTITY_FILTER";
  code: string;
  filterGroup: string;
  filterName: string;
};

export type SetFlagFilterAction = {
  type: "SET_FLAG_FILTER";
  value: FlagFilter["value"] | undefined;
  display: FlagFilter["display"];
  filterGroup: string;
  filterName: string;
};

export type AddServicePointFilterAction = {
  type: "ADD_SERVICE_POINT_FILTER";
  value: ServicePointFilter["value"];
  useUids: boolean;
  display: ServicePointFilter["display"];
  filterGroup: string;
  filterName: string;
};

export type RemoveServicePointFilterAction = {
  type: "REMOVE_SERVICE_POINT_FILTER";
  id: string;
  filterGroup: string;
  filterName: string;
};

export type AddStringFilterAction = {
  type: "ADD_STRING_FILTER";
  value: StringFilter["value"] | undefined;
  display: StringFilter["display"];
  filterGroup: string;
  filterName: string;
};

export type RemoveStringFilterAction = {
  type: "REMOVE_STRING_FILTER";
  term: string;
  filterGroup: string;
  filterName: string;
};

export type FilterAction =
  | SetDateFilterAction
  | SetDateRangeFilterAction
  | AddEntityFilterAction
  | RemoveEntityFilterAction
  | SetFlagFilterAction
  | AddServicePointFilterAction
  | RemoveServicePointFilterAction
  | AddStringFilterAction
  | RemoveStringFilterAction;

export type FilterState = {
  [filterGroup: string]: {
    [filterName: string]: Filter | undefined;
  };
};

export const pickFilterSlice = <F extends Filter, A extends FilterAction>(
  state: FilterState,
  action: FilterAction,
  act: (filter: F | undefined, action: A) => F | undefined
): FilterState => {
  const filter: F | undefined =
    (state[action.filterGroup]?.[action.filterName] as F) ?? undefined;
  return {
    ...state,
    [action.filterGroup]: {
      ...(state[action.filterGroup] ?? {}),
      [action.filterName]: act(filter, action as A),
    },
  };
};

export const filterReducer = (
  state: FilterState,
  action: FilterAction | "INIT"
): FilterState => {
  if (action === "INIT") {
    return state;
  }
  switch (action.type) {
    case "SET_DATE_FILTER":
      return pickFilterSlice(
        state,
        action,
        (_: DateFilter | undefined, action: SetDateFilterAction) => {
          return action.value
            ? {
                type: "DATE",
                operation: action.operation,
                value: action.value,
                display: action.display,
              }
            : undefined;
        }
      );

    case "SET_DATE_RANGE_FILTER":
      return pickFilterSlice(
        state,
        action,
        (_: DateRangeFilter | undefined, action: SetDateRangeFilterAction) => {
          return action.value
            ? {
                type: "DATE_RANGE",
                value: action.value,
                display: action.display,
              }
            : undefined;
        }
      );

    case "ADD_ENTITY_FILTER":
      return pickFilterSlice(
        state,
        action,
        (filter: EntityFilter | undefined, action: AddEntityFilterAction) => {
          return action.value
            ? {
                type: "ENTITY",
                value: [filter?.value ?? [], action.value].flat(),
                display: action.display,
              }
            : undefined;
        }
      );

    case "REMOVE_ENTITY_FILTER":
      return pickFilterSlice(
        state,
        action,
        (
          filter: EntityFilter | undefined,
          action: RemoveEntityFilterAction
        ) => {
          return filter
            ? {
                ...filter,
                value: filter.value.filter(
                  (e) => e.entity_code !== action.code
                ),
              }
            : undefined;
        }
      );

    case "SET_FLAG_FILTER":
      return pickFilterSlice(
        state,
        action,
        (_: FlagFilter | undefined, action: SetFlagFilterAction) => {
          return action.value
            ? {
                type: "FLAG",
                value: action.value,
                display: action.display,
              }
            : undefined;
        }
      );

    case "ADD_SERVICE_POINT_FILTER":
      return pickFilterSlice(
        state,
        action,
        (
          filter: ServicePointFilter | undefined,
          action: AddServicePointFilterAction
        ) => {
          return action.value
            ? {
                type: "SERVICE_POINT",
                value: [filter?.value ?? [], action.value].flat(),
                useUids: action.useUids,
                display: action.display,
              }
            : undefined;
        }
      );

    case "REMOVE_SERVICE_POINT_FILTER":
      return pickFilterSlice(
        state,
        action,
        (
          filter: ServicePointFilter | undefined,
          action: RemoveServicePointFilterAction
        ) => {
          return filter
            ? {
                ...filter,
                value: filter.value.filter((s) => {
                  if (typeof s === "string") {
                    return s !== action.id;
                  }
                  if (isServicePointGroup(s)) {
                    return !(
                      s.service_point_group_uid === action.id ||
                      s.service_points.some(
                        // group contains id to remove
                        (i) => i.service_point_uid === action.id
                      )
                    );
                  }
                  if (isServicePointIdRef(s)) {
                    return s.service_point_uid !== action.id;
                  }
                  return false;
                }),
              }
            : undefined;
        }
      );

    case "ADD_STRING_FILTER":
      return pickFilterSlice(
        state,
        action,
        (filter: StringFilter | undefined, action: AddStringFilterAction) => {
          return action.value
            ? {
                type: "STRING",
                value: [filter?.value ?? [], action.value].flat(),
                display: action.display,
              }
            : undefined;
        }
      );

    case "REMOVE_STRING_FILTER":
      return pickFilterSlice(
        state,
        action,
        (
          filter: StringFilter | undefined,
          action: RemoveStringFilterAction
        ) => {
          return filter
            ? {
                ...filter,
                value: filter.value.filter((s) => s !== action.term),
              }
            : undefined;
        }
      );

    default:
      return state;
  }
};

@Injectable({ providedIn: "root" })
export class FilterService {
  private destroyRef = inject(DestroyRef);

  private subscription = new Subscription();
  private actions$ = new Subject<FilterAction>();
  private state$ = this.actions$
    .asObservable()
    .pipe(startWith("INIT" as const), scan(filterReducer, {}), shareReplay(1));

  constructor() {
    this.subscription.add(this.state$.subscribe());
    this.destroyRef.onDestroy(() => {
      this.subscription.unsubscribe();
    });
  }

  dispatch(action: FilterAction) {
    this.actions$.next(action);
  }

  state(filterGroup: string) {
    return this.state$.pipe(map((s) => s[filterGroup] ?? {}));
  }

  asParams(filterGroup: string): Observable<FilterParams[]> {
    return this.state$.pipe(
      map((state) => {
        return Object.entries(state[filterGroup] ?? {})
          .map(([name, filter]): FilterParams[] => {
            if (!filter) {
              return [];
            }
            switch (filter.type) {
              case "DATE": {
                return this.dateAsParams(name, filter);
              }
              case "DATE_RANGE": {
                return this.dateRangeAsParams(name, filter);
              }
              case "ENTITY": {
                return this.entityAsParams(name, filter);
              }
              case "FLAG": {
                return this.flagAsParams(name, filter);
              }
              case "SERVICE_POINT": {
                return this.servicePointAsParams(name, filter);
              }
              case "STRING": {
                return this.stringAsParams(name, filter);
              }
              default:
                return [];
            }
          })
          .flat();
      })
    );
  }

  asActiveDisplay(
    filterGroup: string,
    filterName?: string
  ): Observable<ActiveFilterDisplay[]> {
    return this.state(filterGroup).pipe(
      map((group) => {
        return Object.entries(group ?? {})
          .filter(([name]) =>
            filterName === undefined || name === filterName ? true : false
          )
          .map(([name, filter]): ActiveFilterDisplay[] => {
            if (!filter) {
              return [];
            }
            switch (filter.type) {
              case "DATE": {
                return this.dateAsDisplay(filterGroup, name, filter);
              }
              case "DATE_RANGE": {
                return this.dateRangeAsDisplay(filterGroup, name, filter);
              }
              case "ENTITY": {
                return this.entityAsDisplay(filterGroup, name, filter);
              }
              case "FLAG": {
                return this.flagAsDisplay(filterGroup, name, filter);
              }
              case "SERVICE_POINT": {
                return this.servicePointAsDisplay(filterGroup, name, filter);
              }
              case "STRING": {
                return this.stringAsDisplay(filterGroup, name, filter);
              }
              default:
                return [];
            }
          })
          .flat();
      })
    );
  }

  asCount(filterGroup: string, filterName: string): Observable<number> {
    return this.state(filterGroup).pipe(
      map((group) => group[filterName]),
      map((filter) => {
        if (!filter) {
          return 0;
        }
        switch (filter.type) {
          case "DATE": {
            return this.dateAsCount(filter);
          }
          case "DATE_RANGE": {
            return this.dateRangeAsCount(filter);
          }
          case "ENTITY": {
            return this.entityAsCount(filter);
          }
          case "FLAG": {
            return this.flagAsCount(filter);
          }
          case "SERVICE_POINT": {
            return this.servicePointAsCount(filter);
          }
          case "STRING": {
            return this.stringAsCount(filter);
          }
          default:
            return 0;
        }
      })
    );
  }

  dateAsCount(filter: DateFilter): any {
    return filter.value ? 1 : 0;
  }

  dateRangeAsCount(filter: DateRangeFilter): any {
    return filter.value ? 1 : 0;
  }

  entityAsCount(filter: EntityFilter): any {
    return (filter.value ?? []).length;
  }

  flagAsCount(filter: FlagFilter): any {
    return filter.value === undefined ? 0 : 1;
  }

  servicePointAsCount(filter: ServicePointFilter): any {
    return new Set(
      (filter.value ?? [])
        .map((s) => {
          if (typeof s === "string") {
            return s;
          }
          if (isServicePointGroup(s)) {
            return s.service_points.map((p) => p.service_point_id);
          }
          if (isServicePointIdRef(s)) {
            return s.service_point_id;
          }
        })
        .filter(Boolean)
        .flat() as string[]
    ).size;
  }

  stringAsCount(filter: StringFilter): any {
    return filter.value ? filter.value.length : 0;
  }

  private dateAsParams(name: string, filter: DateFilter): FilterParams[] {
    return [
      {
        name,
        values: [
          {
            value: moment(filter.value).utc(true).toISOString(true),
            operation: filter.operation,
          },
        ],
      },
    ];
  }
  private dateAsDisplay(
    filterGroup: string,
    filterName: string,
    filter: DateFilter
  ): ActiveFilterDisplay[] {
    return [
      {
        headerName: filter.display,
        operation: filter.operation,
        value: moment(filter.value).utc(true).format("YYYY/MM/DD HH:mm Z"),
        remove: (() =>
          this.actions$.next({
            type: "SET_DATE_FILTER",
            value: undefined,
            display: filter.display,
            operation: filter.operation,
            filterGroup,
            filterName,
          })).bind(this),
      },
    ];
  }

  private dateRangeAsParams(
    name: string,
    filter: DateRangeFilter
  ): FilterParams[] {
    return [
      {
        name,
        values: [
          {
            value: `${moment(filter.value[0])
              .utc(true)
              .toISOString(true)},${moment(filter.value[1])
              .utc(true)
              .toISOString(true)}`,
            operation: "between=",
          },
        ],
      },
    ];
  }
  private dateRangeAsDisplay(
    filterGroup: string,
    filterName: string,
    filter: DateRangeFilter
  ): ActiveFilterDisplay[] {
    return [
      {
        headerName: filter.display,
        operation: "between",
        value: `${moment(filter.value[0])
          .utc(true)
          .format("YYYY/MM/DD HH:mm z")} & ${moment(filter.value[1])
          .utc(true)
          .format("YYYY/MM/DD HH:mm z")}`,
        remove: (() =>
          this.actions$.next({
            type: "SET_DATE_RANGE_FILTER",
            value: undefined,
            display: filter.display,
            filterGroup,
            filterName,
          })).bind(this),
      },
    ];
  }

  private flagAsParams(name: string, filter: FlagFilter): FilterParams[] {
    return [
      {
        name,
        values: [{ value: `${filter.value}` }],
      },
    ];
  }
  private flagAsDisplay(
    filterGroup: string,
    filterName: string,
    filter: FlagFilter
  ): ActiveFilterDisplay[] {
    return [
      {
        headerName: filter.display,
        operation: "=",
        value: new BoolDisplayPipe().transform(filter.value),
        remove: (() =>
          this.actions$.next({
            type: "SET_FLAG_FILTER",
            value: undefined,
            display: filter.display,
            filterGroup,
            filterName,
          })).bind(this),
      },
    ];
  }

  private entityAsParams(name: string, filter: EntityFilter): FilterParams[] {
    return [
      {
        name,
        values: filter.value.map((v) => ({ value: v.entity_code })),
      },
    ];
  }
  private entityAsDisplay(
    filterGroup: string,
    filterName: string,
    filter: EntityFilter
  ): ActiveFilterDisplay[] {
    return filter.value.map((f) => ({
      headerName: filter.display,
      operation: "=",
      value: f.entity_name,
      remove: (() =>
        this.actions$.next({
          type: "REMOVE_ENTITY_FILTER",
          code: f.entity_code,
          filterGroup,
          filterName,
        })).bind(this),
    }));
  }

  private servicePointAsParams(
    name: string,
    filter: ServicePointFilter
  ): FilterParams[] {
    return [
      {
        name,
        values: [
          {
            operation: "in=",
            value: (filter.value ?? [])
              .map((v) =>
                filter.useUids
                  ? (v as ServicePointIdReference).service_point_uid ??
                    (v as ServicePointGroup).service_points.map(
                      (s) => s.service_point_uid
                    ) ??
                    v
                  : (v as ServicePointIdReference).service_point_id ??
                    (v as ServicePointGroup).service_points.map(
                      (s) => s.service_point_id
                    ) ??
                    v
              )
              .flat()
              .join(","),
          },
        ],
      },
    ];
  }
  private servicePointAsDisplay(
    filterGroup: string,
    filterName: string,
    filter: ServicePointFilter
  ): ActiveFilterDisplay[] {
    return filter.value.map((f) => ({
      headerName: filter.display,
      operation: "=",
      value:
        typeof f === "string"
          ? f
          : (f as ServicePointIdReference).service_point_id ??
            `${(f as ServicePointGroup).service_point_group_name} (${
              (f as ServicePointGroup).service_points.length
            })`,
      remove: (() =>
        this.actions$.next({
          type: "REMOVE_SERVICE_POINT_FILTER",
          id:
            (f as ServicePointIdReference).service_point_uid ??
            (f as ServicePointGroup).service_point_group_uid ??
            f,
          filterGroup,
          filterName,
        })).bind(this),
    }));
  }

  private stringAsParams(name: string, filter: StringFilter): FilterParams[] {
    return [
      {
        name,
        values: [
          {
            operation: "in=",
            value: filter.value.join(","),
          },
        ],
      },
    ];
  }
  private stringAsDisplay(
    filterGroup: string,
    filterName: string,
    filter: StringFilter
  ): ActiveFilterDisplay[] {
    return filter.value.map((f) => ({
      headerName: filter.display,
      operation: "=",
      value: f,
      remove: (() =>
        this.actions$.next({
          type: "REMOVE_STRING_FILTER",
          term: f,
          filterGroup,
          filterName,
        })).bind(this),
    }));
  }
}

function isServicePointGroup(
  s: ServicePointIdReference | ServicePointGroup
): s is ServicePointGroup {
  return !!(s as ServicePointGroup).service_point_group_uid;
}

function isServicePointIdRef(
  s: ServicePointIdReference | ServicePointGroup
): s is ServicePointIdReference {
  return !!(s as ServicePointIdReference).service_point_uid;
}
