import { Component, inject, OnDestroy } from "@angular/core";
import { AbstractFilterModal } from "./abstract-filter-modal";
import { ModalHeaderComponent } from "../modal-header/modal-header.component";
import { ModalBodyComponent } from "../modal-body/modal-body.component";
import { ModalFooterComponent } from "../modal-footer/modal-footer.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import {
  FilterService,
  ServicePointFilter,
} from "src/app/shared/services/filter.service";
import { ActiveFilterListComponent } from "../../table/filters/active-filter-list/active-filter-list.component";
import { ServicePointIdReference } from "src/app/shared/models/referenceData";
import { ServicePointGroup } from "src/app/shared/models/servicePointGroup";
import { ServicePointSelectorComponent } from "../../service-point-selector/service-point-selector.component";
import { LabeledPropertyComponent } from "../../labeled-property/labeled-property.component";
import { ServicePointGroupSelectorComponent } from "../../service-point-group-selector/service-point-group-selector.component";

type ServicePointFilterModalInit = {
  allowPartial?: boolean;
  useUids?: boolean;
  filterField?: string;
};

@Component({
  standalone: true,
  imports: [
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    LabeledPropertyComponent,
    ServicePointSelectorComponent,
    ServicePointGroupSelectorComponent,
    ActiveFilterListComponent,
  ],
  selector: "app-service-point-filter-modal",
  styles: `
  `,
  template: `
    <div app-modal-header>{{ fieldNameDisplay }}</div>
    <div app-modal-body>
      <p>Select a date for the column {{ fieldNameDisplay }} below</p>
      <div app-labeled-property label="Service Points">
        <div
          app-service-point-selector
          (valueChange)="select($event)"
          (partialValueChange)="select($event)"
          [selectedIds]="selected"
          [slim]="true"
          [allowPartial]="modalInit.allowPartial ?? false"
        ></div>
      </div>
      <div app-labeled-property label="Service Point Groups">
        <div
          app-service-point-group-selector
          (valueChange)="select($event)"
          [selectedGroups]="selected"
          [slim]="true"
        ></div>
      </div>
      <app-active-filter-list
        [filterGroup]="filterGroup"
        [filterName]="field"
      ></app-active-filter-list>
    </div>
    <div app-modal-footer>
      <button class="action-btn" (click)="onClose()">Close</button>
    </div>
  `,
})
export class ServicePointFilterModalComponent
  extends AbstractFilterModal<ServicePointFilterModalInit>
  implements OnDestroy
{
  private modal = inject(NgbActiveModal);
  private filterService = inject(FilterService);

  private subscription = new Subscription();
  public filterGroup: string;
  public field: string;
  public fieldNameDisplay: string = "";
  public modalInit: ServicePointFilterModalInit;
  public selected: Set<string> = new Set();

  public override init(
    modalInit: ServicePointFilterModalInit,
    filterGroup: string,
    field: string,
    fieldNameDisplay: string
  ): void {
    this.modalInit = modalInit;
    this.filterGroup = filterGroup;
    this.field = this.modalInit.filterField ?? field;
    this.fieldNameDisplay = fieldNameDisplay;

    this.subscription.add(
      this.filterService.state(this.filterGroup).subscribe((fp) => {
        this.selected = new Set(
          ((fp[this.field] as ServicePointFilter)?.value ?? []).map((sp) =>
            this.getId(sp)
          )
        );
      })
    );
  }

  getId(sp: string | ServicePointIdReference | ServicePointGroup) {
    return (
      (sp as ServicePointIdReference).service_point_uid ??
      (sp as ServicePointGroup).service_point_group_uid ??
      sp
    );
  }

  select(sp: ServicePointIdReference | ServicePointGroup | string) {
    if (this.selected.has(this.getId(sp))) {
      this.filterService.dispatch({
        type: "REMOVE_SERVICE_POINT_FILTER",
        id: this.getId(sp),
        filterGroup: this.filterGroup,
        filterName: this.field,
      });
    } else {
      this.filterService.dispatch({
        type: "ADD_SERVICE_POINT_FILTER",
        value: [sp],
        useUids: this.modalInit.useUids ?? false,
        display: this.fieldNameDisplay,
        filterGroup: this.filterGroup,
        filterName: this.field,
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onClose() {
    this.modal.close();
  }
}
