import {
  Component,
  EventEmitter,
  inject,
  OnDestroy,
  Output,
} from "@angular/core";
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  map,
  NEVER,
  Observable,
  shareReplay,
  Subscription,
  switchMap,
  tap,
} from "rxjs";
import {
  ColDef,
  GetRowIdFunc,
  GridApi,
  GridReadyEvent,
  IRowNode,
} from "ag-grid-community";
import { APIEnvelope } from "src/app/shared/models/api";
import { ReferenceDataService } from "../reference-data/reference-data.service";
import { AgGridAngular } from "ag-grid-angular";
import { TablePaginationComponent } from "./table-pagination/table-pagination.component";
import { TableLoadingOverlayComponent } from "./overlays/table-loading-overlay.component";
import { TableNoDataOverlayComponent } from "./overlays/table-no-data-overlay.component";
import { SpaOverrideService } from "../spah-overrides/spah-override.service";
import { SpaOverride } from "src/app/shared/models/spaOverride";
import {
  filterGroup,
  SpahOverrideColumnDefinition,
} from "./definitions/spah-overrides-column-definition";
import { TableSortService } from "./services/table-sort.service";
import { ActiveFilterListComponent } from "./filters/active-filter-list/active-filter-list.component";
import { TableConfirmActionComponent } from "./table-confirm-action/table-confirm-action.component";
import { ServicePointOverrideCreateModal } from "../modals/service point-override-create-modal/service-point-override-create-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { pick } from "lodash";
import { ServicePointOverrideDeleteModal } from "../modals/service-point-override-delete-modal/service-point-override-delete-modal.component";
import { FilterService } from "src/app/shared/services/filter.service";

@Component({
  standalone: true,
  selector: "app-spah-overrides-table",
  imports: [
    AgGridAngular,
    TablePaginationComponent,
    ActiveFilterListComponent,
    TableConfirmActionComponent,
  ],
  providers: [TableSortService],
  styles: `
    :host {
        display: flex;
        flex-direction: column;
        justify-content: start;
    }
    app-table-pagination {
        margin-top: 1rem;
    }
  `,
  template: `
    <app-table-confirm-action></app-table-confirm-action>
    <app-active-filter-list
      [filterGroup]="filterGroup"
    ></app-active-filter-list>
    <ag-grid-angular
      class="ag-theme-quartz app-table"
      style="height: calc(100vh - 250px)"
      [getRowId]="getRowId"
      [columnDefs]="columnDefs"
      [rowData]="[]"
      [suppressPaginationPanel]="true"
      [suppressDragLeaveHidesColumns]="true"
      [loadingOverlayComponent]="loadingOverlayComponent"
      [noRowsOverlayComponent]="noRowsOverlayComponent"
      [noRowsOverlayComponentParams]="noRowsOverlayParams"
      [pagination]="false"
      [enableCellTextSelection]="true"
      (gridReady)="onGridReady($event)"
    />
    <app-table-pagination
      [pageNumber]="pageNumber"
      [pageSize]="pageSize"
      [totalResults]="totalResults"
      (pageNumberChange)="pageNumber$.next($event)"
      (pageSizeChange)="pageSize$.next($event)"
    />
  `,
})
export class SpahOverridesTableComponent implements OnDestroy {
  modalService = inject(NgbModal);
  spaOverrideService = inject(SpaOverrideService);
  referenceDataService = inject(ReferenceDataService);
  sortService = inject(TableSortService);
  filterService = inject(FilterService);

  @Output() public loading = new EventEmitter<boolean>();

  private subscription = new Subscription();
  public refreshTrigger$ = new BehaviorSubject<void>(void 0);
  public gridApi: GridApi<Partial<SpaOverride>>;

  // PAGINATION
  public totalResults: number = 0;
  public pageNumber: number = 0;
  public pageSize: number = 1000;
  public pageSize$ = new BehaviorSubject(25);
  public pageNumber$ = new BehaviorSubject(0);
  public paginationParams$ = combineLatest([
    this.pageNumber$,
    this.pageSize$,
  ]).pipe(
    map(([pageNumber, pageSize]) => ({ pageNumber, pageSize })),
    shareReplay(1)
  );

  // DATA
  public data$: Observable<APIEnvelope<SpaOverride>> = combineLatest([
    this.paginationParams$,
    this.filterService.asParams(filterGroup).pipe(
      tap(() => {
        if (this.pageNumber !== 0) {
          this.pageNumber$.next(0);
        }
      })
    ),
    this.sortService.getOrder(),
    this.refreshTrigger$,
  ]).pipe(
    tap(() => {
      this.loading.emit(true);
      this.gridApi.showLoadingOverlay();
    }),
    switchMap(([page, filters, sortOrder]) => {
      return this.spaOverrideService.getOverrides(page, filters, sortOrder);
    }),
    catchError(() => {
      this.noRowsOverlayParams = { error: true };
      const nodeData: IRowNode<Partial<SpaOverride>>[] = [];
      this.gridApi?.forEachNode((node) => node.data && nodeData.push(node));
      this.gridApi?.applyTransaction({
        remove: nodeData.map((n) => n.data!),
      });
      this.loading.emit(false);
      this.gridApi.showNoRowsOverlay();
      return NEVER;
    }),
    shareReplay(1)
  );

  getRowId: GetRowIdFunc<Partial<SpaOverride>> = (data) =>
    this.getDataId(data.data);

  getDataId = (data: Partial<SpaOverride> | undefined) =>
    data?.service_point_uid ?? "" + data?.effective_date ?? "";

  init() {
    this.subscription.add(
      this.data$.subscribe((res) => {
        this.totalResults = res.total;
        const nodeData: IRowNode<Partial<SpaOverride>>[] = [];
        this.gridApi?.forEachNode((node) => nodeData.push(node));
        this.gridApi?.applyTransaction({
          remove: nodeData.map((n) => n.data!),
          add: res.results,
        });
        this.gridApi.hideOverlay();
        if (!res.results.length) {
          this.gridApi?.forEachNode((node) => nodeData.push(node));
          this.gridApi?.applyTransaction({
            remove: nodeData.map((n) => n.data!),
          });
          this.noRowsOverlayParams = { error: false };
          this.gridApi.showNoRowsOverlay();
        }
        this.loading.emit(false);
      })
    );
  }

  destroy() {
    if (!this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.init();
  }

  ngOnDestroy() {
    this.destroy();
  }

  clone(item: SpaOverride) {
    const ref = this.modalService.open(ServicePointOverrideCreateModal, {
      windowClass: "confirmation-modal",
      centered: true,
    });
    ref.componentInstance.init(
      "create",
      pick(item, [
        "ldc_code",
        "eldc_code",
        "profile_class_code",
        "retailer_code",
        "supply_class_code",
        "meter_type_code",
        "loss_class_code",
        "location_code",
        "cpnode_code",
        "service_point_source_code",
        "weather_sensitive_flag",
        "bmg_flag",
        "tie_flag",
        "net_meter_rider_flag",
        "usage_ingestion_block_flag",
      ])
    );

    ref.result
      .then(() => {
        console.debug("Modal closed");
        this.refreshTrigger$.next(void 0);
      })
      .catch(() => {
        console.debug("Modal dismissed");
      });
  }

  update(item: SpaOverride) {
    const ref = this.modalService.open(ServicePointOverrideCreateModal, {
      windowClass: "confirmation-modal",
      centered: true,
    });
    ref.componentInstance.init(
      "update",
      pick(item, [
        "effective_date",
        "termination_date",
        "ldc_code",
        "eldc_code",
        "profile_class_code",
        "retailer_code",
        "supply_class_code",
        "meter_type_code",
        "loss_class_code",
        "location_code",
        "cpnode_code",
        "service_point_source_code",
        "weather_sensitive_flag",
        "bmg_flag",
        "tie_flag",
        "net_meter_rider_flag",
        "usage_ingestion_block_flag",
      ]),
      pick(item, ["service_point_id", "service_point_uid"])
    );

    ref.result
      .then(() => {
        console.debug("Modal closed");
        this.refreshTrigger$.next(void 0);
      })
      .catch(() => {
        console.debug("Modal dismissed");
      });
  }

  delete(item: SpaOverride) {
    const ref = this.modalService.open(ServicePointOverrideDeleteModal, {
      windowClass: "confirmation-modal",
      centered: true,
    });
    ref.componentInstance.init(item);

    ref.result
      .then(() => {
        console.debug("Modal closed");
        this.refreshTrigger$.next(void 0);
      })
      .catch(() => {
        console.debug("Modal dismissed");
      });
  }

  // columns
  public loadingOverlayComponent = TableLoadingOverlayComponent;
  public noRowsOverlayComponent = TableNoDataOverlayComponent;
  public noRowsOverlayParams = { error: false };
  public spahOverrideColumnDefinition = new SpahOverrideColumnDefinition(
    this.referenceDataService,
    [
      {
        name: "Clone",
        onAction: ((d: SpaOverride) => this.clone(d)).bind(this),
      },
      {
        name: "Edit",
        onAction: ((d: SpaOverride) => this.update(d)).bind(this),
      },
      {
        name: "Delete",
        onAction: ((d: SpaOverride) => this.delete(d)).bind(this),
      },
    ]
  );
  public columnDefs: ColDef[] = this.spahOverrideColumnDefinition.getColDefs();
  public filterGroup = filterGroup;
}
