import { Component, Input, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subscription, combineLatest } from "rxjs";
import {
  ColDef,
  GetRowIdFunc,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { AgGridAngular } from "ag-grid-angular";
import { TableLoadingOverlayComponent } from "./overlays/table-loading-overlay.component";
import { TableNoDataOverlayComponent } from "./overlays/table-no-data-overlay.component";
import { TableSortService } from "./services/table-sort.service";
import { IntervalUsage } from "src/app/shared/models/intervalUsage";
import { ImportIntervalUsageColumnDefinition } from "./definitions/import-interval-usage-column-definition";

@Component({
  standalone: true,
  selector: "app-import-interval-usage-table",
  imports: [AgGridAngular],
  providers: [TableSortService],
  styles: `
      :host {
          display: flex;
          flex-direction: column;
          justify-content: start;
      }
    `,
  template: `
    <ag-grid-angular
      class="ag-theme-quartz app-table"
      style="height: 40vh"
      [getRowId]="getRowId"
      [columnDefs]="columnDefs"
      [rowData]="[]"
      [suppressPaginationPanel]="true"
      [suppressDragLeaveHidesColumns]="true"
      [loadingOverlayComponent]="loadingOverlayComponent"
      [noRowsOverlayComponent]="noRowsOverlayComponent"
      [noRowsOverlayComponentParams]="noRowsOverlayParams"
      [pagination]="false"
      [enableCellTextSelection]="true"
      (gridReady)="onGridReady($event)"
    />
  `,
})
export class ImportIntervalUsageTableComponent
  implements OnDestroy
{
  private subscription = new Subscription();
  private loading$ = new BehaviorSubject(false);
  private error$ = new BehaviorSubject('');
  private _data: Partial<IntervalUsage>[] = [];
  private data$ = new BehaviorSubject<Partial<IntervalUsage>[]>([]);

  @Input() set loading(val: boolean) {
    this.loading$.next(val);
  }

  @Input() set error(val: string) {
    this.error$.next(val);
  }

  @Input() set data(val: Partial<IntervalUsage>[] | null) {
    if(val) {
        this.data$.next(val);
    }
  }

  public gridApi: GridApi;

  getRowId: GetRowIdFunc<Partial<IntervalUsage>> = (data) =>
    (data.data.service_point_uid ?? 'NA') + (data.data.market_interval ?? 'NA');

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.init();
  }

  private init() {
    this.subscription.add(
      this.data$.subscribe((data) => {
        const nodeData: unknown[] = [];
        this._data = data;
        this.gridApi?.forEachNode((node) => nodeData.push(node.data));
        this.gridApi?.applyTransaction({
          remove: nodeData,
          add: data,
        });
        this.gridApi.hideOverlay();
        if (!data.length) {
          this.gridApi?.forEachNode((node) => nodeData.push(node.data));
          this.gridApi?.applyTransaction({
            remove: nodeData,
          });
          this.noRowsOverlayParams = { error: false, message: 'No data to display! Please upload a CSV file to continue'  };
          this.gridApi.showNoRowsOverlay();
        }
      })
    );
    this.subscription.add(
      combineLatest([this.loading$, this.error$]).subscribe(([l,e]) => {
        if (l) {
          this.gridApi.showLoadingOverlay();
        } else if (e) {
          this.noRowsOverlayParams = { error: true, message: e };
          this.gridApi.showNoRowsOverlay();
        }else if (this._data.length <= 0) {
          this.noRowsOverlayParams = { error: false, message: 'No data to display!, Please upload a CSV file to continue' };
          this.gridApi.showNoRowsOverlay();
        } else {
          this.gridApi.hideOverlay();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // columns
  public loadingOverlayComponent = TableLoadingOverlayComponent;
  public noRowsOverlayComponent = TableNoDataOverlayComponent;
  public noRowsOverlayParams = { error: false, message: 'No data to display!, Please upload a CSV file to continue' };

  columnDefinition =
    new ImportIntervalUsageColumnDefinition();
  public columnDefs: ColDef[] =
    this.columnDefinition.getColDefs();
}
