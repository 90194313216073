import { ChangeDetectorRef, Component, inject } from "@angular/core";
import { ModalHeaderComponent } from "../modal-header/modal-header.component";
import { ModalFooterComponent } from "../modal-footer/modal-footer.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalBodyComponent } from "../modal-body/modal-body.component";
import { LabeledPropertyComponent } from "../../labeled-property/labeled-property.component";
import { ReferenceDataService } from "../../reference-data/reference-data.service";
import { IntervalUsage } from "src/app/shared/models/intervalUsage";
import { DatePickerButtonComponent } from "../../date-picker-button/date-picker-button.component";
import {
  ReplaySubject,
  Subscription,
  combineLatest,
  switchMap,
  tap,
} from "rxjs";
import * as moment from "moment";
import { ServicePointSelectorComponent } from "../../service-point-selector/service-point-selector.component";
import { ServicePointIdReference } from "src/app/shared/models/referenceData";
import { IntervalUsageService } from "../../interval-usage/interval-usage.service";
import { AsyncPipe } from "@angular/common";
import { IntervalUsageHistoryTableComponent } from "../../table/interval-usage-history-table.component";

@Component({
  standalone: true,
  imports: [
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    LabeledPropertyComponent,
    ServicePointSelectorComponent,
    DatePickerButtonComponent,
    IntervalUsageHistoryTableComponent,
    AsyncPipe,
  ],
  selector: "app-interval-usage-history-modal",
  styles: ``,
  template: `
    <div app-modal-header>Usage History</div>
    <div app-modal-body>
      <div app-labeled-property label="Service Point">
        @if(servicePointIdRef) {
        <div
          app-service-point-selector
          [slim]="true"
          [value]="servicePointIdRef"
          (valueChange)="servicePointIdRef$.next($event)"
        ></div>
        }
      </div>
      <div app-labeled-property label="Market Interval">
        @if(marketInterval) {
        <app-date-picker-button
          [slim]="true"
          [showHour]="true"
          [value]="marketInterval"
          (valueChange)="marketInterval$.next($event)"
        >
        </app-date-picker-button>
        }
      </div>
      <app-interval-usage-history-table
        [data]="data$ | async"
        [loading]="loading"
      ></app-interval-usage-history-table>
    </div>
    <div app-modal-footer>
      <button class="action-btn" (click)="onClose()">Close</button>
    </div>
  `,
})
export class IntervalUsageHistoryModalComponent {
  activeModal = inject(NgbActiveModal);
  changeDetectorRef = inject(ChangeDetectorRef);
  referenceDataService = inject(ReferenceDataService);
  intervalUsageService = inject(IntervalUsageService);

  subscription = new Subscription();
  marketInterval$ = new ReplaySubject<Date>(1);
  marketInterval: Date;
  servicePointIdRef$ = new ReplaySubject<ServicePointIdReference>(1);
  servicePointIdRef: ServicePointIdReference;
  loading = false;
  data$ = combineLatest([this.servicePointIdRef$, this.marketInterval$]).pipe(
    tap(() => {
      this.loading = true;
    }),
    switchMap(([servicePointRef, marketInterval]) =>
      this.intervalUsageService.getIntervalUsageHistory(
        servicePointRef,
        marketInterval
      )
    ),
    tap(() => {
      this.loading = false;
    })
  );

  init(data: IntervalUsage) {
    this.marketInterval$.next(moment(data.market_interval).toDate());
    this.servicePointIdRef$.next(data);
    this.subscription.add(
      this.marketInterval$.subscribe((marketInterval) => {
        this.marketInterval = marketInterval;
      })
    );
    this.subscription.add(
      this.servicePointIdRef$.subscribe((servicePointIdRef) => {
        this.servicePointIdRef = servicePointIdRef;
      })
    );
    this.changeDetectorRef.detectChanges();
  }

  onClose() {
    this.subscription.unsubscribe();
    this.activeModal.dismiss();
  }
}
