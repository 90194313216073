import { ColDef, IRowNode } from "ag-grid-community";
import { DateCellComponent } from "../cells/date-cell.component";
import { IntervalUsageHistory } from "src/app/shared/models/intervalUsageHistory";
import { TableHeaderComponent } from "../headers/table-header.component";

export const filterGroup = "interval_usage_history"

export class IntervalUsageHistoryColumnDefinition {
  constructor(
  ) {}

  sharedDef: ColDef = {
    filter: false,
    sortable: false,
    editable: false,
    minWidth: 100,
    width: 150,
  };

  getColDefs = (): ColDef[] => [
    {
        ...this.sharedDef,
        headerName: "Usage (kW h)",
        field: "usage",
        cellRenderer: (d: IRowNode<IntervalUsageHistory>) => d.data?.measurement_value,
        headerComponent: TableHeaderComponent,
        headerComponentParams: {
          filterGroup
        }
      },
      {
        ...this.sharedDef,
        width: 175,
        headerName: "Last Updated At",
        field: "last_update_time",
        cellRenderer: DateCellComponent,
        cellRendererParams: {
          format: "YYYY/MM/DD HH:mm:ss",
        },
        headerComponent: TableHeaderComponent,
        headerComponentParams: {
          filterGroup
        }
      },
      {
        ...this.sharedDef,
        width: 175,
        flex: 1,
        headerName: "Last Updated By",
        field: "last_updated_by",
        headerComponent: TableHeaderComponent,
        headerComponentParams: {
          filterGroup
        }
      },
  ];
}
