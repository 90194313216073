import { Component, inject } from "@angular/core";
import { ModalHeaderComponent } from "../modal-header/modal-header.component";
import { ModalFooterComponent } from "../modal-footer/modal-footer.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalBodyComponent } from "../modal-body/modal-body.component";
import { LabeledPropertyComponent } from "../../labeled-property/labeled-property.component";
import { RLSCertificatesService } from "../../rls-certificates/rls-certificates.service";
import { scan, shareReplay, Subject, Subscription } from "rxjs";
import { TextInputComponent } from "../../text-input/text-input.component";
import { FileInputComponent } from "../../file-input/file-input.component";
import {
  isCompleteRLSCertUploadRequest,
  RLSCertUploadRequest,
} from "src/app/shared/models/rls-cert-upload-request";

@Component({
  standalone: true,
  imports: [
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    LabeledPropertyComponent,
    TextInputComponent,
    FileInputComponent,
  ],
  selector: "app-rls-cert-upload-modal",
  styles: ``,
  template: `
    <div app-modal-header>Upload Digital Certificate</div>
    <div app-modal-body>
      <div app-labeled-property label="Participant">
        <div
          app-text-input
          placeholder="AMIL"
          (changeValue)="handleParticipantChange($event)"
        ></div>
      </div>
      <div app-labeled-property label="Pass-Phrase">
        <div
          app-text-input
          placeholder="certificate pass-phrase"
          (changeValue)="handlePassPhraseChange($event)"
        ></div>
      </div>
      <div app-labeled-property label="Certificate File">
        <div
          app-file-input
          accept="*.pfx"
          (changeFile)="handleFileUpload($event)"
        ></div>
      </div>
    </div>
    <div app-modal-footer>
      <button class="action-btn" (click)="onCancel()">Cancel</button>
      <button
        class="action-btn primary"
        [disabled]="uploadDisabled"
        (click)="onUpload()"
      >
        Upload
      </button>
    </div>
  `,
})
export class RLSCertUploadModal {
  activeModal = inject(NgbActiveModal);
  rlsCertService = inject(RLSCertificatesService);

  subscription = new Subscription();
  uploadDisabled = true;
  data: RLSCertUploadRequest | undefined
  formEvent$ = new Subject<Partial<RLSCertUploadRequest>>();
  form$ = this.formEvent$.pipe(
    scan(
      (acc, evt) => ({
        ...acc,
        ...evt,
      }),
      {} as Partial<RLSCertUploadRequest>
    ),
    shareReplay(1)
  );

  ngOnInit() {
    this.subscription.add(this.form$.subscribe(formData => {
        if(isCompleteRLSCertUploadRequest(formData)) {
            this.uploadDisabled = false;
            this.data = formData;
        }
    }))
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onCancel() {
    this.activeModal.dismiss();
  }

  handleParticipantChange(participant: string) {
    this.formEvent$.next({ participant });
  }

  handlePassPhraseChange(pass_phrase: string) {
    this.formEvent$.next({ pass_phrase });
  }

  handleFileUpload(file: File | undefined) {
    this.formEvent$.next({ file });
  }

  onUpload() {
    if (this.data) {
      this.subscription.add(
        this.rlsCertService.uploadCert(this.data).subscribe(() => {
            this.activeModal.dismiss();
        })
      );
    }
  }
}
