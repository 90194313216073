import { Component, inject } from "@angular/core";
import { ModalHeaderComponent } from "../modal-header/modal-header.component";
import { ModalFooterComponent } from "../modal-footer/modal-footer.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalBodyComponent } from "../modal-body/modal-body.component";
import { LabeledPropertyComponent } from "../../labeled-property/labeled-property.component";
import { RLSCertificatesService } from "../../rls-certificates/rls-certificates.service";
import { RLSCertMetadata } from "src/app/shared/models/rls-cert-metadata";
import { DatePipe } from "@angular/common";
import { take } from "rxjs";

@Component({
  standalone: true,
  imports: [
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    LabeledPropertyComponent,
    DatePipe
  ],
  selector: "app-rls-cert-confirm-delete-modal",
  styles: ``,
  template: `
    <div app-modal-header>Confirm Deletion</div>
    <div app-modal-body>
      @if(metadata) {
        <div app-labeled-property label="Participant">{{metadata.participant}}</div>
        <div app-labeled-property label="Participant Name">{{metadata.participant_name}}</div>
        <div app-labeled-property label="Start Date">{{metadata.start_date | date:'yyyy/MM/dd hh:ss a'}}</div>
        <div app-labeled-property label="End Date">{{metadata.expiration_date | date:'yyyy/MM/dd hh:ss a'}}</div>
      }
    </div>
    <div app-modal-footer>
      <button class="action-btn" (click)="onCancel()">Cancel</button>
      <button class="action-btn primary" (click)="onDelete()" [disabled]="!metadata">Delete</button>
    </div>
  `,
})
export class RLSCertConfirmDeleteModal {
  activeModal = inject(NgbActiveModal);
  rlsCertService = inject(RLSCertificatesService);
  
  metadata?: RLSCertMetadata;

  init(metadata: RLSCertMetadata) {
    this.metadata = metadata;
  }

  onCancel() {
    this.activeModal.dismiss();
  }

  onDelete() {
    if(this.metadata) {
      this.rlsCertService.deleteCert(this.metadata.participant).pipe(
        take(1)
      ).subscribe(() => {
        this.rlsCertService.refresh();
        this.activeModal.dismiss();
      });
    }
  }
}
