import { HttpClient } from "@angular/common/http";
import {
  merge,
  Observable,
  ReplaySubject,
  shareReplay,
  Subscription,
  switchMap,
} from "rxjs";
import { fetchUntilExhausted } from "./fetch-until-exhausted";

export class CachedResponse<T> {
  private subscription = new Subscription();
  private data$: Observable<T[]>;
  private refresh$ = new ReplaySubject<true>(1);

  constructor(
    url: string,
    storage: Storage,
    http: HttpClient,
    pageSize = 1000
  ) {
    const fromStorage$ = new Observable<T[]>((subscriber) => {
      const storageValue = storage.getItem(url);
      if (storageValue) {
        subscriber.next(<T[]>JSON.parse(storageValue));
      }
    });
    const fromServer$ = this.refresh$.pipe(
      switchMap(
        () =>
          new Observable<T[]>((subscriber) => {
            fetchUntilExhausted<T>(http, url, pageSize, 0).then((res) => {
              storage.setItem(url, JSON.stringify(res.results));
              subscriber.next(res.results);
            });
          })
      )
    );
    this.data$ = merge(fromStorage$, fromServer$).pipe(shareReplay(1));
    this.subscription.add(this.data$.subscribe());
    this.refresh();
  }

  get() {
    return this.data$;
  }

  refresh() {
    this.refresh$.next(true);
  }

  destroy() {
    this.subscription.unsubscribe();
  }
}
